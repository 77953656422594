import {
  always,
  ascend,
  chain,
  compose,
  equals,
  filter,
  find,
  forEach,
  head,
  indexBy,
  indexOf,
  join,
  last,
  length,
  lensPath,
  lensProp,
  map,
  omit,
  pick,
  prop,
  propOr,
  set,
  sortBy,
  sortWith,
  split,
  unless,
  when,
  without
} from 'ramda';
import {pathOr, strPathOr} from '@rescapes/ramda';
import {compact, extremes, findOrThrow, idsEqual, toArrayIfNot} from 'utils/functional/functionalUtils.ts';

import {
  convertDistanceRangeFromTrainRouteGroupToTrainRoute
} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainRouteUtils.ts';
import {CrudList} from 'types/crud/crudList';
import {TrainRouteOrGroupDerived} from 'types/trainRouteGroups/trainRouteOrGroup';
import {SensorDataPoint} from 'types/sensors/sensorDataPoint';
import {ChartPayloadItem, ChartPayloadItemDerived} from 'types/dataVisualizations/chartPayloadItem';
import {
  areSensorDataDateIntervalsAreUpToDate,
  trainGroupHasSensorData
} from 'appUtils/trainAppUtils/trainGroupSensorDataUtils/trainGroupSensorDataUtils.ts';
import {mergeTrainGroup} from 'appUtils/trainAppUtils/trainAppTypeMerging/trainGroupMerging.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {DistanceRange} from 'types/distances/distanceRange';
import {DataFeature} from 'types/dataVisualizations/nonSpatialFeatureSet.ts';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps.d.ts';
import {
  userTrainRunDistanceRangeAndIntervalDifference
} from 'appUtils/trainAppUtils/trainGroupUtils/trainGroupRouteBasedUtils.ts';
import {equalsCemitType, implementsCemitTypeViaClass} from 'classes/cemitAppCemitedClasses/cemitClassResolvers.ts';
import {OrganizationProps} from 'types/propTypes/organizationPropTypes/organizationProps';
import {doesOrganizationHaveServiceLines} from 'utils/organization/organizationUtils.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';

import {SensorDataTrainGroup} from 'types/trainGroups/sensorDataTrainGroup';
import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';

import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import {TrainGroupSensorDataGeojson} from 'types/trainGroups/trainGroupSensorDataGeojson';
import {
  workerizedTrainRunFeatureCollectionSensorPoints
} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainDataUtilsWorkerized.ts';
import {omitClassOrType, setClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {dumpDateIntervals} from 'utils/datetime/dateUtils.ts';
import {TrainRun} from 'types/trainRuns/trainRun';
import {resolveActiveTrainGroupCrudList} from 'appUtils/trainAppUtils/scope/trainGroupCrudScope.ts';
import {TrainGroupClassification} from 'types/trainGroups/trainGroupClassification.ts';
import {useMemo} from 'react';

/**
 * Merges each loadedSensorDataTrainGroups into the corresponding crudTrainGroups
 * TrainGroups
 * @param crudTrainGroups
 * @param loadedSensorDataTrainGroups Either SensorDataTrainGroup that have
 * loaded SensorDataPoints to merge in, or TrainGroup that have geojson to merge in
 */
export const updateSensorDataOfCrudTrainGroupsPreMerge = (
  crudTrainGroups: CrudList<TrainGroup>,
  loadedSensorDataTrainGroups: SensorDataTrainGroup[]
): TrainGroup[] => {
  return map((loadedSensorTrainGroup: SensorDataTrainGroup) => {
    const _crudTrainGroup: Perhaps<TrainGroup> = find((crudTrainGroup) => {
      return idsEqual(loadedSensorTrainGroup.trainGroup, crudTrainGroup);
    }, crudTrainGroups.list);

    // Omit sensor based properties that are unrelated to sensor data that might have been updated in the same run loop
    // so that we don't overwrite them with old values when we merge
    const crudTrainGroup = omitSensorBasedDataExceptProps(
      ['sensorDataPoints', 'sensorDataDateIntervals', 'sensorDataGeojson'],
      _crudTrainGroup
    );

    if (!crudTrainGroup) {
      throw new Error(
        `No corresponding TrainGroup in crudTrainGroups for id ${loadedSensorTrainGroup.trainGroup.id}`
      );
    }
    // If the geojson is already synced with loadedSensorTrainGroup.sensorDataDateIntervals, do nothing
    if (areSensorDataDateIntervalsAreUpToDate(loadedSensorTrainGroup, crudTrainGroup)) {
      return crudTrainGroup;
    } else {
      const existingSensorDataIntervals = crudTrainGroup?.sensorDataGeojson
        ?.sensorDataDateIntervals
        ? dumpDateIntervals(crudTrainGroup?.sensorDataGeojson?.sensorDataDateIntervals)
        : 'undefined';
      const incomingSensorDataIntervald = dumpDateIntervals(
        loadedSensorTrainGroup.sensorDataDateIntervals
      );
      console.debug(
        `TrainGroup ${crudTrainGroup.localizedName()} sensorDataGeojson needs to be synced to new sensor data.\nExisting sensorDataDateIntervals: ${existingSensorDataIntervals}\nIncoming sensorDataDateIntervals ${incomingSensorDataIntervald}`
      );
    }

    const trainGroupGeojsonFeatureCollection =
      workerizedTrainRunFeatureCollectionSensorPoints({
        trainRouteOrGroup: crudTrainGroup.trainRouteOrGroup,
        sensorDataTrainGroup: loadedSensorTrainGroup
      });
    const sensorDataGeojson = clsOrType<TrainGroupSensorDataGeojson>(
      CemitTypename.trainGroupGeojson,
      {
        localUpdateDate: new Date(),
        localUpdateVersion: 1,
        // Mark what activeDateInterval the sensorDataFeatureCollections represents so we
        // can detect stale data
        activeDateInterval: crudTrainGroup.activeDateInterval,
        // Mark what sensorDataDateIntervals the sensorDataGeojson represents so we
        // can detect stale data
        sensorDataDateIntervals: loadedSensorTrainGroup.sensorDataDateIntervals,
        featureCollections: [trainGroupGeojsonFeatureCollection]
      }
    );
    const updatedCrudTrainGroup = mergeTrainGroup(
      // Completely overwrite sensorDataPoints sensorDataDateIntervals in case cache clearing happened
      omit(['sensorDataPoints', 'sensorDataDateIntervals'], crudTrainGroup),
      // Merge the properties of loadedSensorTrainGroup into its TrainGroup
      // This gives us the updated loadingStatus, sensorDataDateIntervals, and sensorDataPoints,
      // which we can merge into the crudTrainGroup
      pick(
        ['loadingStatus', 'sensorDataDateIntervals', 'sensorDataPoints'],
        loadedSensorTrainGroup
      ) as Partial<TrainGroup>
    );
    // Set the computed geojson
    const updated = setClassOrType(
      lensProp('sensorDataGeojson'),
      sensorDataGeojson,
      updatedCrudTrainGroup
    );
    return updated;
  }, loadedSensorDataTrainGroups);
};

export const updateTrainGroupGeojsonDataOfCrudTrainGroupsPreMerge = (
  crudTrainGroups: CrudList<TrainGroup>,
  updatedTrainGroups: TrainGroup[]
): TrainGroup[] => {
  return compact(
    map((trainGroup: TrainGroup) => {
      const crudTrainGroup: Perhaps<TrainGroup> = find((crudTrainGroup) => {
        return idsEqual(trainGroup, crudTrainGroup);
      }, crudTrainGroups.list);
      // TODO this should never happen, perhaps it was happening when we changed the
      // crudTrainGroups.list items in scope during a load, such as changing the TrainRoute
      if (!crudTrainGroup) {
        throw new Error(
          `No corresponding TrainGroup in crudTrainGroups for id ${trainGroup.id}`
        );
      }
      return mergeTrainGroup(crudTrainGroup, pick(['sensorDataGeojson'], trainGroup));
    }, updatedTrainGroups)
  );
};

/**
 * Checks that the SensorDataPoint data for the trainGroup's current distanceRange is loaded for the
 * distanceResolution that the distanceRange resolves to
 * @param trainRouteOrGroup The TrainRouteOrGroup
 * @param minimumTrainRunsWithSensorDataPoints
 * @param trainGroup
 * @returns {*}
 */
export const outstandingTrainGroupSensorDataForDistanceIntervalAndRange = (
  trainRouteOrGroup: TrainRouteOrGroupDerived,
  minimumTrainRunsWithSensorDataPoints: SensorDataTrainGroup[],
  trainGroup: TrainGroup
): DistanceRange[] => {
  const trainIdToMinimumTrainRunsWithSensorDataPoints = indexBy(
    prop('id'),
    minimumTrainRunsWithSensorDataPoints
  );
  const trainGroupId = trainGroup.singleTrainRun!.id;
  const distanceResolutionsAndRanges = pathOr(
    [],
    [trainGroupId, 'distanceResolutionsAndRanges'],
    trainIdToMinimumTrainRunsWithSensorDataPoints
  );
  if (!length(distanceResolutionsAndRanges)) {
    // If we haven't loaded anything for the current TrainGroup, return empty
    return [];
  }
  const convertedDistanceRange = convertDistanceRangeFromTrainRouteGroupToTrainRoute(
    trainRouteOrGroup,
    // This has to be the non-grouped TrainRoute
    trainGroup.singleTrainRunTrainRoute,
    trainRouteOrGroup.trainDistanceInterval.distanceRange
  );
  const trainGroupWithMappedDistanceRange = set(
    lensPath(['singleTrainRun', 'trainRoute', 'distanceRange']),
    convertedDistanceRange,
    trainGroup
  );
  // If distanceRanges is empty, it means we have already downloaded the range of trainGroup.trainRouteOrGroup.distanceRange
  const {distanceRanges} = userTrainRunDistanceRangeAndIntervalDifference(
    distanceResolutionsAndRanges,
    trainGroupWithMappedDistanceRange
  );
  return distanceRanges;
};

export const logSensorDataPointsOfTrainGroups = (
  trainGroups: TrainGroup[],
  label: string = ''
) => {
  forEach((trainGroup: TrainGroup) => {
    const sensorDataPoints = chain(
      strPathOr([], 'collectionDevice.sensorDataPoints'),
      trainGroup.trainGroupCollectionDevices
    );
    console.debug(
      join(
        '\n',
        compact([
          label,
          trainGroup.id,
          `has: ${trainGroupHasSensorData(trainGroup)}`,
          `extremes: ${map(
            prop('time'),
            extremes(
              sortBy(
                (sensorDataPoint: SensorDataPoint) => sensorDataPoint.time,
                sensorDataPoints
              )
            )
          )}`
        ])
      )
    );
  }, trainGroups);
};

/**
 * Correct problems with the way Recharts picks the payloadItems, and/or find the closest points of
 * the TrainGroups that were not hovered over
 * @param appProps
 * @param trainGroups The active TrainGroups, which correspond to the
 * payloadItems
 * @param payloadItems 0 to length(trainGroups) payloadItems from recharts or generated
 * manually from a Mapbox hover. Each has a payload, the feature of the TrainGroup.geojson.featureCollectionPoints
 * and attributes such as the TrainGroup label
 * @returns {[Object]} The corrected payloadItems
 */
export const correctPayload = (
  appProps: TrainAppProps,
  trainGroups: TrainGroup[],
  payloadItems: Perhaps<(ChartPayloadItem | ChartPayloadItemDerived)[]>
): ChartPayloadItem[] => {
  const compactedPayloadItems = compact(
    map((payloadItem: ChartPayloadItemDerived) => {
      // Ignore payloads from empty dataFeatureCollection.features. This is
      // just garbage that recharts generates
      if (!length(payloadItem.dataFeatureCollection.features)) {
        return undefined;
      }

      // TODO we should be able to pass trainGroup with the payloadItems
      const trainGroup: TrainGroup | undefined = find((trainGroup) => {
        return equals(
          // TODO  payloadItem.payload.source type should be forced to implement TrainGroup
          payloadItem.payload?.source?.trainGroup?.id ||
          // TODO hack for when source is TrainGroup
          // Instead Make the dataFeatureCollection supply a payload comparison function
          payloadItem.dataFeatureCollection?.source?.id,
          trainGroup.id
        );
      }, trainGroups);
      // If we don't have featureCollectionPoints, it means the user-selected distance range
      // is outside this TrainRun's TrainRoute, or we didn't get sensor data where expected.
      // If trainGroup.activity.isVisible is true, don't show this payloadItem.
      if (!trainGroup) {
        console.warn('Payload item lacks a trainGroup. This should never happen');
        // Return undefined to discard this payloadItem
        return undefined;
      }
      const xValueOfHoveredItem = payloadItem.xValueOfHoveredItem;
      // Get the attribute portion of properties.attribute
      const xAxisProperty = last(
        split(
          '.',
          payloadItem.dataFeatureCollection.chartDataConfigFinalized?.chartDataConfig
            .xAxisDataPath
        )
      );
      // Is this payloadItem the one of the line that the user hover over, as opposed to a calculation of
      // the nearest point on a line that wasn't hover over.
      // payloadItem.isActivePayloadItem is set true ahead of time for the Mapbox hover case
      const isActivePayloadItem =
        payloadItem.isActivePayloadItem ||
        Boolean(
          find(({properties}) => {
            return equals(xValueOfHoveredItem, properties[xAxisProperty]);
          }, payloadItem.dataFeatureCollection.features)
        );
      return {...payloadItem, trainGroup, isActivePayloadItem};
    }, payloadItems || [])
  );
  const sortedPayloadItems: ChartPayloadItem[] = sortWith([
    // Sort by the index of trainGroups so it matches the active TrainGroups order
    ascend(
      compose(
        (trainGroup: TrainGroup) => indexOf(trainGroup, trainGroups),
        (payloadItem: ChartPayloadItemDerived) => payloadItem.trainGroup
      )
    ),

    // Secondarily sort by Wheel.side so left wheels show first if this is a WheelScan application
    // TODO hack. Move this the the WheelGroup dataFeatureCollection
    // ascend(
    //   compose(
    //     (wheel: Perhaps<Wheel>) => {
    //       return wheel?.side || 'N/A';
    //     },
    //     (payloadItem: ChartPayloadItemDerived): Perhaps<Wheel> => {
    //       const source = payloadItem.dataFeatureCollection.source;
    //       return wheelOfWheelGroup(source.wheelGroup);
    //     },
    //   ),
    // ),
    // Thirdly by dataFeatureCollection.sourceTypename, which can in the future become
    // dataFeatureCollection.sortOrder or similar
    ascend((payloadItem: ChartPayloadItemDerived) => {
      return payloadItem.dataFeatureCollection.sourceTypename;
    })
  ])(compactedPayloadItems);

  // payloadItem.trainGroup can be undefined when we remove UserTrainRuns
  const validPayloadItems = filter<ChartPayloadItem, ChartPayloadItem[]>(
    (payloadItem: ChartPayloadItem): boolean => {
      return Boolean(payloadItem.trainGroup);
    },
    sortedPayloadItems
  );
  // When there is no corresponding dataIndex for the non active TrainGroup chart lines,
  // Recharts incorrectly assigns data from the active TrainGroup. Se we need to detect this
  // and replace the data with the closest point, if it's within 500 meters

  const getXAxisValue = (payloadItem: ChartPayloadItem) => {
    const payloadDataFeature = payloadItem.payload;
    // TODO hack pass chartDataConfig
    const xAxisProperty = payloadItem.trainGroup?.sensorDataGeojson ? 'meters' : 'date';
    return payloadDataFeature.properties?.[xAxisProperty];
  };

  const updatedPayloadItems: ChartPayloadItem[] =
    validPayloadItems &&
    compact(
      map((payloadItem: ChartPayloadItemDerived) => {
        const compareWithX = payloadItem.xValueOfHoveredItem;
        // If have no xValueOfHoveredItem (Mapbox hover case) then do nothing.
        // Otherwise find the closest feature point to use as the payloadItems, since Recharts doesn't find the correct points
        // We do this on the active payloadItem as well because Recharts seems to somehow select the wrong
        // item sometimes.
        const maybedUpdatedPayloadItem = !payloadItem.xValueOfHoveredItem
          ? payloadItem
          : (compose(
            // If this payloadItems item isn't within 500 units of headPayloadItem, don't show it
            unless((payloadItem: ChartPayloadItem) => {
              return (
                payloadItem.payload &&
                Math.abs(getXAxisValue(payloadItem) - compareWithX) <= 500
              );
            }, always(undefined)),
            // Update the value to this feature if dataKey is defined (defined in dataVisualizations only, not maps)
            (payloadItem: ChartPayloadItem) => {
              return when(
                (payloadItem) => propOr(false, 'dataKey', payloadItem),
                (payloadItem: ChartPayloadItem) => {
                  return set<CharacterData, string | number | undefined>(
                    lensProp('value'),
                    strPathOr(
                      undefined,
                      payloadItem.dataKey || 'noDataKey',
                      payloadItem.payload || {}
                    ),
                    payloadItem
                  );
                }
              )(payloadItem);
            },
            // For non-active payloadItems, the closest payloadItems data is always wrongly based on the feature index
            // of the active item or incorrectly copied from the
            // active item by recharts when the index doesn't exist for the non-active payloadItem's features.
            // So we need to find the closest feature for this TrainGroup
            // to payloadItem.xValueOfHoveredItem, which is the meters values of the mouse hover on the active line
            (payloadItem: ChartPayloadItemDerived) => {
              // Find the closest feature in xAxisProperty property
              // If there are no features, set the payload feature to undefined so it doesn't show anything
              const features = payloadItem.dataFeatureCollection.features;
              // TODO hack
              const xAxisProperty = payloadItem.trainGroup?.sensorDataGeojson
                ? 'meters'
                : 'date';
              const replacementFeature = length(features)
                ? sortBy((dataFeature: DataFeature) => {
                  return Math.abs(
                    dataFeature.properties![xAxisProperty] -
                    payloadItem.xValueOfHoveredItem
                  );
                }, features)[0]
                : undefined;
              return set(lensProp('payload'), replacementFeature, payloadItem);
            }
          )(payloadItem) as ChartPayloadItemDerived);
        return maybedUpdatedPayloadItem;
      }, validPayloadItems)
    );

  return updatedPayloadItems;
};

/**
 * Chains all TrainRuns of the TrainGroup(s)
 * @param trainGroups
 */
export const trainRuns = (
  trainGroups: Perhaps<TrainGroup | TrainGroup[]>
): TrainRun[] => {
  return chain<TrainGroup, TrainRun>(
    (trainGroup: TrainGroup) => {
      return trainGroup.trainRuns || ([] as TrainRun[]);
    },
    toArrayIfNot(trainGroups || []) as TrainGroup[]
  );
};

/**
 * Returns the 0 or more TrainRouteOrGroup instances of the TrainGroup
 * @param trainGroup
 */
export const trainRouteOrGroupsOfTrainGroup = (trainGroup: TrainGroup) => {
  return trainGroup.trainRouteOrGroup
    ? [trainGroup.trainRouteOrGroup]
    : map(
      (trainRun: TrainRun) => trainRun.journeyPattern.trainRoute,
      equalsCemitType(CemitTypename.trainGroupOnlyTrainFormation, trainGroup)
        ? []
        : trainGroup.trainRuns!
    );
};

/**
 * Returns true if the given activeTrainGroups are not TrainGroupOnlyTrainFormation
 * Checks only the first, assuming all are the same type
 * @param activeTrainGroups
 */
export const doActiveTrainGroupsHaveTrainRuns = (
  activeTrainGroups: TrainGroup | TrainGroup[]
): Perhaps<boolean> => {
  const cemitType = typeOfActiveTrainGroups(activeTrainGroups);
  return CemitTypename.trainGroupSingleTrainRun == cemitType;
};

/**
 * Returns CemitTypename.trainGroupSingleTrainRun or CemitTypename.trainGroupOnlyTrainFormation
 * @param activeTrainGroups
 */
export const typeOfActiveTrainGroups = (
  activeTrainGroups: TrainGroup | TrainGroup[]
): Perhaps<CemitTypename> => {
  const check = head(toArrayIfNot(activeTrainGroups));
  if (!check) {
    return undefined;
  }
  return check.__typename;
};

/**
 * If the active scope is TrainGroupSingleTrainRuns, use trainProps.trainGroupSingleTrainRunProps.crudTrainGroups
 * Else use crudTrainGroupOnlyTrainFormations
 * @param trainProps
 */
export const crudTrainGroupsOfActiveScope = (
  trainProps: TrainProps
): CrudList<TrainGroup> | CrudList<TrainGroupOnlyTrainFormation> => {
  return doActiveTrainGroupsHaveTrainRuns(
    trainProps.trainGroupActivityProps.activeTrainGroups
  )
    ? trainProps.trainGroupSingleTrainRunProps.crudTrainGroups
    : trainProps.trainGroupOnlyTrainFormationProps.crudTrainGroupOnlyTrainFormations!;
};

/**
 * Return the givenTrainGroups that match the type of the activeTrainGroups.
 * @param activeTrainGroups
 * @param sensorDataTrainGroups
 */
export const filterSensorTrainGroupsToActiveScope = (
  activeTrainGroups: TrainGroup | TrainGroup[],
  sensorDataTrainGroups: SensorDataTrainGroup[]
) => {
  const type = typeOfActiveTrainGroups(activeTrainGroups);
  if (!type) {
    return [];
  }
  return filter((sensorDataTrainGroups: SensorDataTrainGroup) => {
    return implementsCemitTypeViaClass(type, sensorDataTrainGroups.trainGroup);
  }, sensorDataTrainGroups);
};

/**
 * The DateInterval in scope for the trainGroups.
 * TODO this is always trainProps.trainFormationDateProps.dateInterval now
 *
 * @param trainProps
 * @param trainGroups
 */
export const dateIntervalInScopeForTrainGroups = (
  trainProps: TrainProps,
  trainGroups: TrainGroup | TrainGroup[]
): DateInterval => {
  return trainProps.trainFormationDateProps.dateInterval;
};

/**
 * Returns true if the Organization has ServiceLines and the
 * trainGroups have TrainRoutes, meaning that they
 * are no TrainGroupOnlyTrainFormations
 * @param organizationProps
 * @param trainGroups
 */
export const trainGroupsHaveServiceLinesAndTrainRouteGroups = (
  organizationProps: OrganizationProps,
  trainGroups: TrainGroup[]
): Perhaps<boolean> => {
  return (
    doesOrganizationHaveServiceLines(organizationProps) &&
    doActiveTrainGroupsHaveTrainRuns(trainGroups)
  );
};

/**
 * TrainGroup's whose TrainFormation doesn't have disableSensorData set to True
 * @param trainGroups
 */
export const activeSensorDataEligibleTrainGroups = (trainGroups: TrainGroup[]) => {
  return filter((trainGroup: TrainGroup) => {
    return !Boolean(trainGroup.trainFormation.disableSensorData);
  }, trainGroups);
};

// Props that we don't want to overwrite if we are loading sensor data from one source and there
// is a race condition where the object we are writing has older data values than what was previously saved
const excludedSensorPropsWhenCruding: (keyof TrainGroup)[] = [
  // Sensor data props from train-api's EnergyXYZ table or similar
  'sensorDataPoints',
  'sensorDataDateIntervals',
  'sensorDataGeojson',
  // Alert data props
  'alertScopeProps',
  // Summary Alert data props
  'alertScopeSummaryProps',
  'alertGeojson',
  // RealtimeTrain sensor data props
  'realtimeTrainScopeProps',
  'realtimeTrainGeojson'
];
/**
 * Omit props from TrainGroup related to sensor data before saving. This keeps one sensor
 * data source from interfering with another during a race condition
 * @param exceptProps
 * @param trainGroup
 */
export const omitSensorBasedDataExceptProps = (
  exceptProps: (keyof TrainGroup)[],
  trainGroup: TrainGroup
): TrainGroup => {
  return omitClassOrType<TrainGroup>(
    without(exceptProps, excludedSensorPropsWhenCruding),
    trainGroup
  );
};

/**
 * Returns true if there are any activeTrainGroupSingleTrainRuns and they are loading or
 * else the trainGroupOnlyTrainFormations are loading
 * @param trainProps
 */
export const areActiveTrainGroupsLoading = (trainProps: TrainProps): boolean => {
  return length(
    trainProps.trainGroupSingleTrainRunProps.activeTrainGroupSingleTrainRuns || []
  )
    ? trainProps.trainGroupSingleTrainRunProps.loading
    : trainProps.trainGroupOnlyTrainFormationProps.loading
}
