/**
 * Used for showing trend values
 */
export enum TrendQuaternary {
  unknown='unknown',
  down='down',
  flat='flat',
  up='up',
}

/**
 * Combines the trend type with the diff between the end minus the start value,
 * which can be NaN if either value was unvailable
 */
export interface TrendQuaternaryAndDiff {
  trendQuaternary: TrendQuaternary,
  diff: number
}
