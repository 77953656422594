import React, {ComponentType, useCallback, useContext, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useCustomLocalStorageDefaulted} from 'utils/hooks/useCustomLocalStorage.ts';
import {useWindowDimensions} from 'utils/hooks/domHooks.ts';
import {accelerationConfig} from 'config/appConfigs/trainConfigs/dataConfig.tsx';
import type {Location} from '@remix-run/router';
import {useTranslation} from 'react-i18next';
import {TFunction} from 'i18next';
import OrganizationDependency from 'async/trainAppAsync/OrganizationDependency.tsx';
import {CemitTypename} from 'types/cemitTypename.ts';
import {resolveTabFromLocation} from 'appUtils/locationUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {AccelerationConfig, AccelerationOptionsKeys} from 'types/dataVisualizations/accelerationConfig';
import {ViewSlider} from 'types/propTypes/viewSlider.ts';
import {FrontendViewGroup} from 'types/frontendViews/frontendViewGroup';
import {
  activeFrontendViewsOfGroup,
  setActiveViewsOfFrontendViewGroup
} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/activityUtils.ts';
import {FrontendViewGroupSelection} from 'types/frontendViews/frontendViewGroupSelection';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitFilter} from 'types/cemitFilters/cemitFilter';
import {UserContext, UserContextType} from 'pages/auth/UserContext.ts';
import {AccessStatus} from 'utils/userTypes.ts';
import {
  useWhatChangedLoadingExplanation,
  useWhatIsLoading
} from 'async/trainAppAsync/trainAppHooks/loadingExplanationHooks.ts';
import {isLoadingStringOfDependencyUnit} from 'async/trainAppAsync/trainAppDependencies/trainDependencyUnitConfig.ts';
import {useStateWhatDependenciesAreLoading} from 'async/whatDependenciesAreLoading/whatDependenciesAreLoadingHooks.ts';
import {getLocalTimeZoneStr} from 'utils/datetime/timeUtils.ts';
import {AppSettings} from 'config/appConfigs/appSettings.ts';
import {FrontendView} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {SortConfig} from 'types/sorting/sortConfig.ts';
import {SortDirection, SortDirectionEnum} from 'types/sorting/sortDirection.ts';
import {TrainAppProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppProps';
import {
  TrainGroupOverviewColumnAttributes
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/trainGroupOverviewEnums.ts';
import {AlertOverviewByTimePeriod} from 'types/alerts/alertOverviewByTimePeriod.ts';
import {TrainGroupClassification, TrainGroupClassificationEnum} from 'types/trainGroups/trainGroupClassification.ts';
import {StateSetter} from 'types/hookHelpers/stateSetter';

interface TrainAppDependencyProps<T extends ComponentType> {
  organizationDependencyChildren: T;
}

/**
 * Top-level app configuration.
 * This could load top-level non-organization specific information about the server environment of global
 * data such as all the railroads that CemitType knows about.
 * The TrainAppDependency creates and OrganizationDependency that has organizationDependencyChildren of type T extends
 * React.ReactNode. This allows the each application to configure the Dependency tree that it needs
 * TODO this currently ignores the values of CemitAppPropsDependency in favor of its own AppProps. It should
 * instead merge the AppProps of CemitAppPropsDependency
 * @param organizationChildren child trafficSimComponents to create for the child OrganizationDependency
 * @return {*}
 */
const TrainAppDependency = <T extends ComponentType>(
  {
    organizationDependencyChildren
  }: TrainAppDependencyProps<T>): JSX.Element => {
  const {
    t
  }: {
    t: TFunction;
  } = useTranslation();
  const location: Location = useLocation();

  const [currentTimezone, setCurrentTimezone] = useState<string>(getLocalTimeZoneStr());

  const [whatDependenciesAreLoading, setWhatDependenciesAreLoading] =
    useStateWhatDependenciesAreLoading();

  // The active FrontendViews. These are initialized based on the organization configuration and changed by
  // user interaction
  const [activeViews, setActiveViews] = useState<FrontendView[]>(undefined);
  const windowSize = useWindowDimensions();
  const displayProps = useMemo(() => {
    return {
      windowSize
    };
  }, [windowSize]);

  const {userState} = useContext(UserContext) as UserContextType;

  // The current page of the app
  const currentAppPage = useMemo(() => {
    return resolveTabFromLocation(location);
  }, [location]);

  // The template CemitFilter, extended by other Dependencies
  const cemitFilterApp: CemitFilter = useMemoClsOrType<CemitFilter>(
    CemitTypename.cemitFilter,
    {
      // Top-level, no parent
      parent: undefined,
      // These are used for embedded CemitFilters that are for a particular model
      modelTypename: undefined,
      allPass: []
    },
    []
  );

  // Collapse the Train panel, hiding it and showing other panels that are visible
  const [viewSlider, setViewSlider] = useState<ViewSlider>(ViewSlider.normal);

  // CemitFilterDrawer. Not currently in use. Shows filter attributes in a left-side draw instead of in the Train panel
  const [trainFilterOpen, setTrainFilterOpen] = useState(false);

  // Shows running characteristics charts
  const [runningCharacteristicsOpen, setRunningCharacteristicsOpen] = useState(false);

  // Running Characteristics Configuration drawer. Not currently in use
  const [
    runningCharacteristicsConfigurationOpen,
    setRunningCharacteristicsConfigurationOpen
  ] = useState(false);

  // The acceleration type toggle on Running Characteristics
  const [chosenAccelerationKey, setChosenAcceleration] =
    useCustomLocalStorageDefaulted<AccelerationOptionsKeys>(
      {},
      AppSettings.localStorage.accelerationType,
      'xyz'
    );

  // Not currently in use. This was for the wheelscan application
  const [isLifetimeSelected, setIsLifetimeSelected] =
    useCustomLocalStorageDefaulted<boolean>({}, 'isLifetimeSelected', false);
  // True if the x-axis units are time instead of distance
  const [isTimeSelected, setIsTimeSelected] = useCustomLocalStorageDefaulted<boolean>(
    {},
    AppSettings.localStorage.isLifetimeSelected,
    false
  );

  // Paging for the TrainGroup and TrainRunList
  const [trainGroupTablePage, setTrainGroupTablePage] = React.useState<number>(0);
  const [trainGroupTableRowsPerPage, setTrainGroupTableRowsPerPage] =
    // TODO set to Infinity for now. Individual containers can limit the count
    React.useState<number>(Infinity);

  const [realtimeIsActive, setRealtimeIsActive] = useCustomLocalStorageDefaulted<boolean>(
    {},
    AppSettings.localStorage.realtimeIsActive,
    false
  );

  // The chosen AccelerationConfig, currently y or xyz. TODO move this a a child interface of chart configurations
  const chosenAcceleration: Perhaps<AccelerationConfig> =
    chosenAccelerationKey && accelerationConfig[chosenAccelerationKey];

  const childPropsLoading = userState.status === AccessStatus.Initial || !activeViews;

  // Util functions to updated activeViews of FrontendViewGroups correctly
  const appPropsScopedFunctions = {
    setActiveViewsOfFrontendViewGroups: useCallback(
      (frontendGroupSelections: FrontendViewGroupSelection[]) => {
        return setActiveViewsOfFrontendViewGroup(setActiveViews, frontendGroupSelections);
      },
      []
    ),
    activeFrontendViewsOfGroup: useCallback(
      (frontendGroup: FrontendViewGroup) => {
        return activeFrontendViewsOfGroup(frontendGroup, activeViews);
      },
      [activeViews]
    )
  };

  // The chosen TrainGroup SortConfig and  sort direction
  // Initialize the TrainGroup sortBy from the cache or with a default
  const [trainGroupSortConfigName, setTrainGroupSortConfigName] = useCustomLocalStorageDefaulted<SortConfig<TrainGroupOverviewColumnAttributes>['name']>(
    {},
    AppSettings.localStorage.trainGroupSortTypeName,
    'trainAndClasses'
  );
  const [trainGroupSortConfigNameDirection, setTrainGroupSortConfigNameDirection] = useCustomLocalStorageDefaulted<SortDirectionEnum>(
    {},
    AppSettings.localStorage.trainGroupSortDirection,
    SortDirectionEnum.ascending
  );

  const [areAllTrainGroupsExpanded, setAreAllTrainGroupsExpanded] = useCustomLocalStorageDefaulted<boolean>(
    {},
    AppSettings.localStorage.trainGroupAllExpanded,
    false
  );

  // The sort direction for the TrainGroup overview table
  const [trainGroupOverviewSortDirection, setTrainGroupOverviewSortDirection] = useCustomLocalStorageDefaulted<SortDirection>(
    {},
    AppSettings.localStorage.trainGroupOverviewSortDirection,
    'asc'
  );

  // Order by for the TrainGroup overview table
  const [trainGroupOverviewOrderBy, setTrainGroupOverviewOrderBy] = useCustomLocalStorageDefaulted<TrainGroupOverviewColumnAttributes>(
    {},
    AppSettings.localStorage.trainGroupOverviewOrderBy,
    'trainAndClasses'
  );

  // Stores the selected AlertOverviewByTimePeriod for the TrainGroup overview table. This determines what time
  // interval is shown in the table
  const [alertOverviewByTimePeriod, setAlertOverviewByTimePeriod] = useCustomLocalStorageDefaulted<AlertOverviewByTimePeriod>(
    {},
    AppSettings.localStorage.trainGroupOverviewTimePeriod,
    AlertOverviewByTimePeriod.Days1
  );

  const [trainGroupClassificationSourceKey, setTrainGroupClassificationSourceKey] =  useCustomLocalStorageDefaulted<string>(
    {},
    AppSettings.localStorage.trainGroupClassification,
    TrainGroupClassificationEnum.allTrains
  );

  const appProps: TrainAppProps = useMemo(() => {
    return clsOrType<TrainAppProps>(CemitTypename.trainAppProps, {
      loading: childPropsLoading,
      distanceUnit: 'km',
      t,
      activeViews,
      setActiveViews,
      displayProps,
      ...appPropsScopedFunctions,
      currentAppPage,
      viewSlider,
      setViewSlider,
      chosenAcceleration,
      chosenAccelerationKey,
      setChosenAcceleration,
      isLifetimeSelected,
      setIsLifetimeSelected,
      isTimeSelected,
      setIsTimeSelected,
      trainFilterOpen,
      setTrainFilterOpen,
      runningCharacteristicsOpen,
      setRunningCharacteristicsOpen,
      runningCharacteristicsConfigurationOpen,
      setRunningCharacteristicsConfigurationOpen,
      cemitFilterApp,
      trainGroupTablePage,
      setTrainGroupTablePage,
      trainGroupTableRowsPerPage,
      setTrainGroupTableRowsPerPage,
      setWhatDependenciesAreLoading,
      currentTimezone,
      setCurrentTimezone,
      realtimeIsActive,
      setRealtimeIsActive,
      trainGroupSortConfigName,
      setTrainGroupSortConfigName,
      trainGroupSortConfigNameDirection,
      setTrainGroupSortConfigNameDirection,
      areAllTrainGroupsExpanded,
      setAreAllTrainGroupsExpanded,
      trainGroupOverviewSortDirection,
      setTrainGroupOverviewSortDirection,
      trainGroupOverviewOrderBy,
      setTrainGroupOverviewOrderBy,
      alertOverviewByTimePeriod,
      setAlertOverviewByTimePeriod,
      trainGroupClassificationSourceKey,
      setTrainGroupClassificationSourceKey
    });
  }, [
    childPropsLoading,
    t,
    displayProps,
    activeViews,
    currentAppPage,
    viewSlider,
    chosenAcceleration,
    chosenAccelerationKey,
    isLifetimeSelected,
    isTimeSelected,
    trainFilterOpen,
    runningCharacteristicsOpen,
    runningCharacteristicsConfigurationOpen,
    cemitFilterApp,
    trainGroupTablePage,
    trainGroupTableRowsPerPage,
    currentTimezone,
    whatDependenciesAreLoading,
    realtimeIsActive,
    trainGroupSortConfigName,
    trainGroupSortConfigNameDirection,
    areAllTrainGroupsExpanded,
    trainGroupOverviewSortDirection,
    trainGroupOverviewOrderBy,
    alertOverviewByTimePeriod,
    trainGroupClassificationSourceKey
  ]);
  const whatChangedObject = appProps;
  const whatIsLoading = useWhatIsLoading(
    false,
    isLoadingStringOfDependencyUnit(TrainAppDependency.name),
    TrainAppDependency.name,
    {
      'userState.status': userState.status !== AccessStatus.Initial,
      activeViews
    },
    [userState?.status, activeViews],
    appProps.setWhatDependenciesAreLoading
  );
  useWhatChangedLoadingExplanation(
    whatIsLoading,
    whatChangedObject,
    'TrainAppDependency'
  );

  return (
    <OrganizationDependency
      {...{appProps, renderChildren: organizationDependencyChildren}}
    />
  );
};
export default TrainAppDependency;
