import React from 'react';
import TrainMapContainer from '../mapbox/TrainMapContainer.tsx';

import {ViewSlider} from '../../../types/propTypes/viewSlider.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {TrainAppMapComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapComponentDependencyProps.ts';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';

export interface TrainPageMapProps extends CemitComponentProps {
  hideSearch: boolean;
}
const TrainPageMap = ({
  appProps,
  trainProps,
  mapProps,
  componentProps,
}: TrainAppMapComponentDependencyProps<TrainPageMapProps>) => {
  return (
    <TrainMapContainer
      {...{
        appProps,
        trainProps,
        mapProps,
        componentProps: {
          sx: {
            ...(appProps.viewSlider == ViewSlider.expanded
              ? {width: 0}
              : {width: '100%'}),
            height: appProps.displayProps.windowSize.height,
          },
          ...componentProps,
        },
      }}
    />
  );
};

export default TrainPageMap;
