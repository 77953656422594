import * as React from 'react';
import {IconButton, Stack} from '@mui/material';
import {Edit} from '@mui/icons-material';
import {useTheme} from '@mui/styles';
import CustomTooltip from 'pages/trafficsim/trafficSimComponents/themedComponents/CustomTooltip.tsx';

export default function EditButton({onEdit}) {
  const theme = useTheme();
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <CustomTooltip title="Redigera Begränsningar">
        {/*{' '}*/}
        <IconButton
          onClick={onEdit}
          aria-label="delete"
          size="small"
          sx={{
            backgroundColor: theme.palette.brand.main,
            '&:hover': {
              backgroundColor: theme.palette.brand.hover,
            },
            color: theme.palette.brand.contrastText,
            textTransform: 'none',
            width: theme.spacing(3),
            height: theme.spacing(3),
            borderRadius: '3px',
          }}
        >
          <Edit fontSize="inherit" />
        </IconButton>
      </CustomTooltip>
    </Stack>
  );
}
