import mapboxgl, {AnyLayer, AnySourceData, LngLatLike, Map, MapLayerMouseEvent, Popup, SymbolLayer} from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {useNotLoadingEffect, useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {compact} from '@rescapes/ramda';
import {filter, find, forEach, last, lensPath, map, set, split, startsWith} from 'ramda';
import {ChartPayloadItem, ChartPayloadItemMinimized} from 'types/dataVisualizations/chartPayloadItem';
import {MapboxLayer, MapSourceVisualForTrainGroup} from 'types/mapbox/mapSourceVisual';
import {BBox, Feature} from 'geojson';
import {MapboxOptions} from 'types/mapbox/mapboxPosistion';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {TRAIN_MAP_PITCH} from 'config/appConfigs/cemitAppConfigs/cemitMapConfig.ts';
import {AppSettings} from 'config/appConfigs/appSettings.ts';
import {changeStatusesMatchNamePrefixes} from 'appUtils/cemitAppUtils/mapboxSourceChangeStatusUtils.ts';
import {MapSourceChangeStatus} from 'types/mapbox/mapSourceChangeStatus';
import {combineFeatureCollections} from 'utils/geojson/geojsonUtils.ts';
import bbox from '@turf/bbox';
import {OrganizationProps} from 'types/propTypes/organizationPropTypes/organizationProps';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {findOrThrow, lengthAsBoolean, throwUnlessDefined} from 'utils/functional/functionalUtils.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {ts} from 'appUtils/typeUtils/clsOrType.ts';
import {MapboxSearchBox} from '@mapbox/search-js-web';
import {mapboxStyleUrlOfTheme} from 'utils/map/mapUtils.ts';
import {MapboxImage} from 'types/mapbox/MapboxImage.ts';
import {useContext, useEffect, useState} from 'react';
import CemitThemeContext from 'theme/CemitThemeContext.ts';
import {
  SCHEDULED_STOP_POINT_LAYER_PREFIX,
  SCHEDULED_STOP_POINT_SOURCE_PREFIX
} from 'config/appConfigs/cemitAppConfigs/railwayLineConfig.ts';

const OLD_MAP_MAPBOX_API_TOKEN = throwUnlessDefined(process.env.REACT_MAPBOX_TOKEN);
const DARK_LIGHT_MAP_MAPBOX_API_TOKEN = throwUnlessDefined(process.env.REACT_MAPBOX_TOKEN_FOR_DARK_LIGHT);

/**
 * Mounts a map. TODO move style config to a configuration
 * @Param styleUrl The Mapbox style url
 * @param isDarkLightEnabled
 * @param bounds
 * @param center Optional center lon, lat array to pan to
 * @param zoom Optional zoom level
 * @returns {Object} The Mabox map instance
 */
const mountMap = (
  styleUrl: string,
  isDarkLightEnabled: boolean,
  bounds?: Perhaps<BBox>,
  center?: Perhaps<LngLatLike>,
  zoom?: number
): Map => {

  // We have an old and new Mapbox token that refers to an old and new account with different styles that we need
  mapboxgl.accessToken = isDarkLightEnabled ? DARK_LIGHT_MAP_MAPBOX_API_TOKEN : OLD_MAP_MAPBOX_API_TOKEN;

  const position = compact({
    center,
    zoom,
    bounds
  }) as MapboxOptions;

  const map = new mapboxgl.Map({
    container: AppSettings.mapboxDivId,
    style: styleUrl,
    antialias: true,
    pitch: TRAIN_MAP_PITCH,
    ...position
  });
  // instantiate a search box instance
  const searchBox = new MapboxSearchBox();

  // set the mapbox access token, search box API options
  searchBox.accessToken = OLD_MAP_MAPBOX_API_TOKEN;
  searchBox.options = {};

  // set the mapboxgl library to use for markers and enable the marker functionality
  searchBox.mapboxgl = mapboxgl;
  searchBox.marker = true;

  // bind the search box instance to the map instance
  searchBox.bindMap(map);

  // add the search box instance to the DOM
  document.getElementById('search-box-container').appendChild(searchBox);
  return map;
};

/**
 * Mounts the mapbox map
 * @param loading
 * @param organizationProps
 * @param isTrainMapMounted
 * @param trainMap
 * @param setTrainMap Setter to set the Mapbox map
 * @param trainMapLoading
 * @param mapboxImages
 * @param setTrainMapLoading Setter to indicate loading
 * @param setIsTrainMapMounted Setter to indicate the map is mounted
 */
export const useNotLoadingEffectTrainMap = (
  loading: boolean,
  organizationProps: OrganizationProps,
  isTrainMapMounted: boolean,
  trainMap: Perhaps<Map>,
  setTrainMap: StateSetter<Map | undefined>,
  trainMapLoading: boolean,
  mapboxImages: MapboxImage[],
  setTrainMapLoading: StateSetter<boolean>,
  setIsTrainMapMounted: StateSetter<boolean>
): void => {
  const [styleUrl, setStyleUrl] = useState<string>();
  const {theme} = useContext(CemitThemeContext);
  const themeMode: string = theme.palette.mode;

  useNotLoadingEffect(loading, (isTrainMounted, organization, themeMode) => {
    // The mapbox style is based on theme.palette.mode if the organization enables dark/light mode switching
    const styleUrl: string = mapboxStyleUrlOfTheme(
      organization!.isDarkLightEnabled,
      themeMode
    );
    setStyleUrl(styleUrl);

    if (!isTrainMapMounted) {
      // Once our async data is loaded we set up the map
      const bounds = lengthAsBoolean(
        organizationProps.organization.stopFeatureCollections
      )
        ? bbox(
          combineFeatureCollections(
            organizationProps.organization.stopFeatureCollections
          )
        )
        : undefined;

      const trainMap = mountMap(styleUrl, organization.isDarkLightEnabled, bounds);
      setTrainMap(trainMap);

      trainMap.on('style.load', () => {
        // This has to be done because Mapbox doesn't correctly report
        // the loading of styles
        const waiting = () => {
          if (!trainMap.isStyleLoaded()) {
            setTimeout(waiting, 200);
          } else {
            setTrainMapLoading(false);
            trainMap.resize();
          }
        };
        waiting();
      });
      setIsTrainMapMounted(true);
    }
  }, [isTrainMapMounted, organizationProps?.organization, themeMode] as const);

  useNotLoadingEffect(loading || !organizationProps?.organization, (trainMapLoading, isDarkLightEnabled, themeMode) => {
    if (!trainMapLoading) {
      // The mapbox style is based on theme.palette.mode if the organization enables dark/light mode switching
      const newStyleUrl: string = mapboxStyleUrlOfTheme(
        isDarkLightEnabled,
        themeMode
      );
      if (styleUrl !== newStyleUrl) {
        setStyleUrl(newStyleUrl);
        switchBaseMap(trainMap!, newStyleUrl, mapboxImages, themeMode);
      }
    }
  }, [trainMapLoading, organizationProps?.organization?.isDarkLightEnabled, themeMode, styleUrl] as const);
};

export interface MapboxHoverEventProps {
  trainGroup: TrainGroup;
  hoveredStateId: string;
  e: MapLayerMouseEvent;
  popup: Popup;
}

export interface CreateOnHoverProps extends TrainAppMapDependencyProps {
  mostRecentTooltipPayload: ChartPayloadItem[];
  eventProps: MapboxHoverEventProps;
}

export interface MapboxHoverProps extends TrainAppMapDependencyProps {
  dataProps: {
    mapSourceVisuals: MapSourceVisualForTrainGroup[];
    createOnHover: (
      props: CreateOnHoverProps,
      payload: ChartPayloadItemMinimized[]
    ) => void;
    onClickOnly: boolean;
    mostRecentTooltipPayload: ChartPayloadItem[];
  };
}

/**
 *  When the user moves their mouse over the given layers,
 *  calls onHover on the first feature
 * @param appProps
 * @param organizationProps
 * @param trainMap The TrainMap
 * @param mapSourceVisuals Sets of {source, layers, trainGroup} where
 * @param createOnHover Currently only used to show a hover for the train switches on the map. Could be used
 * for other map based hover
 * @param t The translation property
 * @param onClickOnly Default false. If true, require a click to activate the onHover
 * trainGroup is only set if the layer is specific to a TrainRun
 */
export const setMapboxOnHover = (
  {
    appProps,
    organizationProps,
    trainProps,
    mapProps,
    dataProps: {
      mapSourceVisuals,
      createOnHover,
      onClickOnly = false,
      mostRecentTooltipPayload
    }
  }: MapboxHoverProps) => {
  const {trainMap} = mapProps;
  forEach(({layers, trainGroup}) => {
    let hoveredStateId: Perhaps<string>;
    forEach((layer: AnyLayer) => {
      const popup = new mapboxgl.Popup({
        // The closeButton doesn't work correctly, so exclude it here
        /*closeButton: true,*/
        closeOnClick: true
      });
      const mapboxEvent = onClickOnly ? 'click' : 'mouseenter';
      if (onClickOnly) {
        trainMap.on('mouseenter', layer.id, () => {
          // Change the cursor style as a UI indicator.
          trainMap.getCanvas().style.cursor = 'pointer';
        });
      }
      // On mouseenter or onclick
      trainMap.on(mapboxEvent, layer.id, (e: MapLayerMouseEvent) => {
        if (!onClickOnly) {
          // Change the cursor style as a UI indicator.
          trainMap.getCanvas().style.cursor = 'pointer';
        }
        // Imitate recharts payload format
        const chartStylePayloadFromFeatures = (features: Feature[]) => {
          return map((feature: Feature): ChartPayloadItemMinimized => {
            return {payload: feature};
          }, features);
        };

        const payload: ChartPayloadItemMinimized[] = chartStylePayloadFromFeatures(
          e.features as Feature[]
        );
        const createOnHoverProps: CreateOnHoverProps = ts<CreateOnHoverProps>({
          appProps,
          organizationProps,
          trainProps,
          mapProps,
          mostRecentTooltipPayload,
          eventProps: {
            e,
            popup,
            trainGroup,
            hoveredStateId: hoveredStateId as string
          }
        });
        if (createOnHover) {
          createOnHover(createOnHoverProps, payload);
        }
      });

      trainMap.on('mouseleave', layer.id, () => {
        trainMap.getCanvas().style.cursor = '';
      });
    }, layers);
  }, mapSourceVisuals);
};

/**
 * Returns true if all sourcePrefixes are found in at least one changeStatus instances. It's possible
 * to have more than one changeStatuses whose name matches the sourcePrefix.
 * @param loading
 * @param changeStatuses
 * @param sourcePrefixes
 */
export const useNotLoadingMemoAreMapSourcesLoaded = (
  loading: boolean,
  changeStatuses: MapSourceChangeStatus[],
  sourcePrefixes: string[]
) => {
  return useNotLoadingMemo(loading, () => {
    return changeStatusesMatchNamePrefixes(changeStatuses, sourcePrefixes);
  }, [changeStatuses, sourcePrefixes]);
};

const layersToIgnore = [];

/**
 * Based on https://github.com/mapbox/mapbox-gl-js/issues/4006
 * Switches the map url, preserving sources and layers and reloading the map mages that
 * have been stored in mapboxImages whenever we added an image to Mapbox
 * @param map
 * @param styleUri
 * @param mapboxImages
 */
async function switchBaseMap(
  map: Map,
  styleUri: string,
  mapboxImages: MapboxImage[],
  themeMode: string
) {
  const currentStyle = map.getStyle();
  const allCurrentSources = Object.keys(currentStyle.sources).reduce(
    (acc: {id: string; source: AnySourceData}[], el: string) => {
      if (el !== 'composite') {
        acc.push({
          id: el,
          source: {...currentStyle.sources[el]}
        });
      }
      return acc;
    },
    []
  );

  const allCurrentLayers = currentStyle.layers
    .filter(
      layer =>
        !layersToIgnore.includes(layer.id) &&
        ((layer as SymbolLayer).source &&
          (layer as SymbolLayer).source != 'mapbox://mapbox.satellite' &&
          (layer as SymbolLayer).source != 'mapbox' &&
          (layer as SymbolLayer).source != 'composite')
    );

  map.setStyle(styleUri);
  map.once('styledata', async () => {
    await restoreImagesAsync(map, mapboxImages);

    allCurrentSources.forEach(source => {
      map.addSource(source.id, source.source);
    });

    allCurrentLayers.forEach((layer: MapboxLayer) => {

      // Enabled the correct scheduled stop layer for current theme
      // This code is here because of major Mapbox bugs that causes addSource to
      // throw a duplicate error if we try to do a map.setLayout operation in railwayLineMapHooks
      if (startsWith(SCHEDULED_STOP_POINT_LAYER_PREFIX, layer.id)) {
        const layerThemeMode = last(split('-', layer.id));
        const layerUpdated: MapboxLayer = set(
          lensPath(['layout', 'visibility']),
          themeMode === layerThemeMode ? 'visible' : 'none', layer
        )
        map.addLayer(layerUpdated);
      }
      else {
        map.addLayer(layer);
      }
    });
  });
}

/**
 * Restore the images we have loaded if needed. It seems this isn't ever needed as Mapbox
 * doesn't lose them when changing the style
 * @param mapboxMap
 * @param mapboxImages
 */
const restoreImagesAsync = async (mapboxMap: Map, mapboxImages: MapboxImage[]) => {
  forEach((mapboxImage: MapboxImage) => {
    if (!mapboxMap.hasImage(mapboxImage.name)) {
      const image: HTMLImageElement = new Image(
        mapboxImage.width,
        mapboxImage.height
      );
      image.onload = () => mapboxMap.addImage(mapboxImage.name, image);
      image.src = mapboxImage.svg;
    }
  }, mapboxImages);
};
