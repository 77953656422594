import {
  TrainAppTrainComponentDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainComponentDependencyProps.ts';
import {join, map} from 'ramda';
import TableCell from '@mui/material/TableCell';
import {Box, Stack, TableSortLabel, Tooltip} from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {visuallyHidden} from '@mui/utils';
import {SortDirection} from 'types/sorting/sortDirection';
import {TableGroupOverviewTableColumn} from 'types/summaryPresentations/tableGroupOverviewTableColumn.ts';
import {
  TrainGroupOverviewColumnAttributes
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/trainGroupOverviewEnums.ts';
import {AlertOverviewByTimePeriod} from 'types/alerts/alertOverviewByTimePeriod.ts';
import {compact, findOrThrow} from 'utils/functional/functionalUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {
  sortActiveAndDirection
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/trainGroupOverviewUtils.ts';
import {
  TrainAppTrainDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps.ts';
import {TrainGroupClassification} from 'types/trainGroups/trainGroupClassification.ts';
import {ReactElement} from 'react';
import TableGroupOverviewCellSpan
  from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/TableGroupOverviewCellSpan.tsx';
import {
  useNotLoadingMemoResolveCurrentTrainGroupClassification
} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainGroupHooks.ts';
import {toggleButtonGroupClasses} from '@mui/material/ToggleButtonGroup';
import TooltipBox from 'components/utilComponents/TooltipBox.tsx';

export interface TableGroupTableHeadProps extends CemitComponentProps {
  columns: TableGroupOverviewTableColumn[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: TrainGroupOverviewColumnAttributes) => void;
  sortDirection: SortDirection;
  orderBy: string;
  // The currently selected AlertOverviewByTimePeriod. THis impacts what is displayed in the columns
  // and the sort calculations
  alertOverviewByTimePeriod: AlertOverviewByTimePeriod;
}

/**
 * The Head cells for the TrainGroupOverviewTable, including sort function
 * @param appProps
 * @param componentProps
 * @constructor
 */
const TrainGroupOverviewTableHead = (
  {
    appProps,
    organizationProps,
    trainProps,
    componentProps
  }: TrainAppTrainComponentDependencyProps<TableGroupTableHeadProps>) => {
  const {orderBy, sortDirection} = componentProps;

  const createSortHandler =
    (property: TrainGroupOverviewColumnAttributes) => (event: React.MouseEvent<unknown>) => {
      componentProps.onRequestSort(event, property);
    };

  // The Head table cells, which include a name and optional sort and sortDirection control
  const tableHeadCells: JSX.Element[] = map((column: TableGroupOverviewTableColumn) => {
      const {label, unit} = column;
      const labelFinal: string = label ? appProps.t(label) : '';
      const unitFinal: Perhaps<string> = unit && `(${appProps.t(unit)})`;
      const labelAndMaybeUnit = <Stack>{map(
        (str: string) => {
          return <TableGroupOverviewCellSpan key={str}>{str}</TableGroupOverviewCellSpan>;
        },
        compact([labelFinal, unitFinal])
      )}</Stack>;
      const isActiveSort = orderBy === column.id;
      return (
        <TableCell
          key={column.id}
          {...{
            align: column.align || 'right',
            sx: {
              ...(column.minWidth ? {minWidth: column.minWidth} : {}),
              ...(column.maxWidth ? {maxWidth: column.maxWidth} : {}),
              paddingY: '5px',
              paddingX: '10px'
            }
          }}
        >
          <TooltipBox {...{
            arrow: true,
            title: join(
              ' ',
              compact([
                appProps.t(column.columnAndSortDescription),
                sortActiveAndDirection(appProps.t, column.disableSort, isActiveSort, sortDirection)
              ]))
          }} >
          {
            // Create a TableSortLabel unless the column is configured with disableSort true
            column.disableSort ?
              <TableGroupOverviewCellSpan>{labelAndMaybeUnit}</TableGroupOverviewCellSpan> :
              <TableSortLabel
                active={isActiveSort}
                direction={isActiveSort ? sortDirection : 'asc'}
                onClick={createSortHandler(column.id)}
              >
                {labelAndMaybeUnit}
                {isActiveSort ? (
                  <Box component="span" sx={visuallyHidden}>
                    {appProps.t(sortDirection === 'desc' ? 'sortedDescending' : 'sortedAscending')}
                  </Box>
                ) : undefined}
              </TableSortLabel>
          }
          </TooltipBox>
        </TableCell>
      );
    }, componentProps.columns
  );
  // Get the current TrainGroupClassification
  const trainGroupClassification: TrainGroupClassification = useNotLoadingMemoResolveCurrentTrainGroupClassification(
    false,
    trainProps.trainGroupOnlyTrainFormationProps.trainGroupClassifications,
    appProps.trainGroupClassificationSourceKey
  );

  const trainGroupClassificationCells: JSX.Element[] = map((column: TableGroupOverviewTableColumn) => {
      const trainGroupClassificationValueElement: ReactElement = column.resolveValueForTrainGroupClassification({
        appProps,
        organizationProps,
        trainProps
      }, trainGroupClassification);

      return <TableCell
        key={column.id}
        {...{
          align: column.align || 'right',
          sx: {
            minWidth: column.minWidth,
            paddingY: '5px',
            paddingX: '10px',
            fontWeight: 'bolder',
            fontSize: '14px'
          }
        }}>
        {trainGroupClassificationValueElement}
      </TableCell>;
    },
    componentProps.columns
  );

  return <TableHead>
    <TableRow key="tableHeadCells">
      {tableHeadCells}
    </TableRow>
    <TableRow key="trainGroupClassificationCells" {...{

    }}>
      {trainGroupClassificationCells}
    </TableRow>
  </TableHead>;
};
export default TrainGroupOverviewTableHead;
