import {
  TrainAppTrainComponentDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps';
import React, {createElement, ReactNode, SyntheticEvent, useMemo} from 'react';
import {includes, indexOf, join, lensPath, map, set} from 'ramda';
import {ToggleButton, ToggleButtonGroup, Tooltip} from '@mui/material';
import {
  AlertOptionsMenuProps
} from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/AlertOptionsMenu.tsx';
import {
  AlertTypeConfigVisibleAttributeAlertLevelEnum,
  AttributeAlertLevel
} from 'types/alerts/attributeAlertLevelEnums';
import {
  useMemoCurrentAlertTypeConfigVisibleAttributeAlertLevelEnum
} from 'async/trainAppAsync/trainAppHooks/alertConfigHooks/alertTypeConfigVisibleAttributeAlertLevelEnumHooks.ts';
import {AttributeAlertLevelConfig} from 'types/alerts/attributeAlertLevelConfig';
import {findOrThrow} from 'utils/functional/functionalUtils.ts';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig.ts';

/**
 * A menu for selected Alert filter options
 * @param appProps
 * @param trainProps
 * @param componentProps
 * @constructor
 */
export const AlertTypeToggleButtonGroup = (
  {
    appProps,
    trainProps,
    componentProps
  }: TrainAppTrainComponentDependencyProps<AlertOptionsMenuProps>) => {
  const menuItemIconSize = 20;
  const alertTypeConfig: AlertTypeConfig = trainProps.alertConfigProps!.alertTypeConfig!;

  // The AlertTypeConfigVisibleAttributeAlertLevelEnum corresponding to trainProps.alertConfigProps.alertTypeConfig
  // We need to update its visibleAttributeAlertLevelEnums when the user selects items from the menu
  const alertTypeConfigVisibleAttributeAlertLevelEnum: AlertTypeConfigVisibleAttributeAlertLevelEnum =
    useMemoCurrentAlertTypeConfigVisibleAttributeAlertLevelEnum(trainProps);
  const visibleAttributeAlertLevelEnums = alertTypeConfigVisibleAttributeAlertLevelEnum.visibleAttributeAlertLevelEnums;

  const mapEligibleAlertLevels = [...alertTypeConfig.criticalLevels, ...alertTypeConfig.warningLevels];

  const indexOfAlertType = indexOf(
    alertTypeConfigVisibleAttributeAlertLevelEnum,
    trainProps.alertConfigProps.alertTypeConfigVisibleAttributeAlertLevelEnums
  );

  /**
   * When a toggle button is clicked, all active visibleAttributeAlertLevelEnums are sent to this handler
   * @param _e
   * @param visibleAttributeAlertLevelEnums
   */
  const handleClick = (
    _e: SyntheticEvent,
    visibleAttributeAlertLevelEnums: AttributeAlertLevel[]
  ) => {

    trainProps.alertConfigProps.setAlertTypeConfigVisibleAttributeAlertLevelEnums(
      (alertTypeConfigVisibleAttributeAlertLevelEnums: AlertTypeConfigVisibleAttributeAlertLevelEnum[]) => {
        return set(
          lensPath([indexOfAlertType, 'visibleAttributeAlertLevelEnums']),
          visibleAttributeAlertLevelEnums,
          alertTypeConfigVisibleAttributeAlertLevelEnums
        );
      }
    );
  };

  // Create toggleButtons for each trainProps.alertConfigProps.alertTypeConfig.defaultVisibleAttributeAlertLevels
  // For each that is currently in alertTypeConfigVisibleAttributeAlertLevelEnum.visibleAttributeAlertLevelEnums,
  // selecting it will remove it from  alertTypeConfigVisibleAttributeAlertLevelEnum.visibleAttributeAlertLevelEnums.
  // If it is currently no in that collection, selected it will add it
  const toggleButtons: ReactNode = useMemo((): Element[] => {
    return map((attributeAlertLevel: AttributeAlertLevel): Element => {
      const attributeAlertLevelConfig: AttributeAlertLevelConfig = findOrThrow(
        (attributeAlertLevelConfig: AttributeAlertLevelConfig): boolean => {
          return (
            attributeAlertLevelConfig.attributeAlertLevel ===
            attributeAlertLevel
          );
        },
        alertTypeConfig.attributeAlertLevelConfigs
      );
      const isEnabled = includes(attributeAlertLevel, visibleAttributeAlertLevelEnums);
      return (
        <Tooltip
          key={attributeAlertLevel}
          {...{
            arrow: true,
            // Tells the user they can click to enable or disable the alert
            title: join(' ',
              [
                appProps.t('clickTo'),
                appProps.t(isEnabled ? 'disable' : 'enable'),
                attributeAlertLevelConfig.label,
                appProps.t('alerts'),
                appProps.t('for'),
                alertTypeConfig.label
              ]
            )
          }}
        >
          <ToggleButton
            key={attributeAlertLevel}
            {...{value: attributeAlertLevel}}
          >
            {/*{*/}
            {/*  <Stack {...{spacing: 1, direction: 'row', sx: {alignItems: 'center'}}}>*/}
            {/*    <Box*/}
            {/*      key="box"*/}
            {/*      {...{*/}
            {/*        sx: {*/}
            {/*          alignSelf: 'center',*/}
            {/*          width: menuItemIconSize,*/}
            {/*          height: menuItemIconSize*/}
            {/*        }*/}
            {/*      }}*/}
            {/*    >*/}
            {createElement(attributeAlertLevelConfig.imageSvgComponent, {
              width: menuItemIconSize,
              height: menuItemIconSize,
              fill: attributeAlertLevelConfig.color
            })}
            {/*  </Box>*/}
            {/*  <Typography>*/}
            {/*    {`${*/}
            {/*      includes(*/}
            {/*        defaultVisibleAttributeAlertLevel,*/}
            {/*        alertTypeConfigVisibleAttributeAlertLevelEnum.visibleAttributeAlertLevelEnums*/}
            {/*      )*/}
            {/*        ? appProps.t('hide')*/}
            {/*        : appProps.t('show')*/}
            {/*    } ${appProps.t(defaultVisibleAttributeAlertLevel)}`}*/}
            {/*  </Typography>*/}
            {/*</Stack>*/}
            {/*}*/}
          </ToggleButton>
        </Tooltip>
      );
    }, mapEligibleAlertLevels);
  }, [
    trainProps.alertConfigProps.alertTypeConfig,
    alertTypeConfigVisibleAttributeAlertLevelEnum
  ]);

  return <ToggleButtonGroup
    size="small"
    orientation="vertical"
    value={visibleAttributeAlertLevelEnums}
    onChange={handleClick}
  >
    {toggleButtons}
  </ToggleButtonGroup>;
};
