import * as React from 'react';
import {IconButton, Stack} from '@mui/material';
import {MyLocation} from '@mui/icons-material';
import CustomTooltip from 'pages/trafficsim/trafficSimComponents/themedComponents/CustomTooltip.tsx';

export default function ZoomLocationButton({onClick = () => {}}) {
  return (
    <Stack direction="row" spacing={1}>
      <CustomTooltip title="Zooma till plats">
        <IconButton onClick={onClick} color="primary" aria-label="zoom to location">
          <MyLocation />
        </IconButton>
      </CustomTooltip>
    </Stack>
  );
}
