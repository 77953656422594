import {TrainGroup} from 'types/trainGroups/trainGroup.ts';
import {includes, map} from 'ramda';
import {TableGroupOverviewTableColumn} from 'types/summaryPresentations/tableGroupOverviewTableColumn.ts';
import {
  resolveTrainGroupOverviewTableColumnValue
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/trainGroupOverviewUtils.ts';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {
  TrainAppTrainComponentDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainComponentDependencyProps.ts';

export interface TrainOverviewTrainGroupTableRowProps extends CemitComponentProps {
  activeTrainGroups: TrainGroup[],
  trainGroup: TrainGroup,
  columns: TableGroupOverviewTableColumn[]
  tabIndex: number
}

/**
 * Shows a TrainOverview TableRow for a TrainGroup
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param componentProps
 * @constructor
 */
const TrainOverviewTrainGroupTableRow = (
  {
    appProps,
    organizationProps,
    trainProps,
    componentProps
  }: TrainAppTrainComponentDependencyProps<TrainOverviewTrainGroupTableRowProps>) => {
  const {trainGroup, activeTrainGroups, columns, tabIndex} = componentProps;
  const formattedColumns: JSX.Element[] = map((column: TableGroupOverviewTableColumn) => {
      // Resolve the TableCell value for the given column (TableGroupOverviewColumn) and row (TrainGroup)
      const value = resolveTrainGroupOverviewTableColumnValue(
        {appProps, organizationProps, trainProps},
        trainGroup,
        column
      );

      return (
        <TableCell key={column.id} align={column.align || 'right'} sx={{
          ...(column.minWidth ? {minWidth: column.minWidth} : {}),
          ...(column.maxWidth ? {maxWidth: column.maxWidth} : {})
        }}>
          {column.format && typeof value === 'number'
            ? column.format(value)
            : value}
        </TableCell>
      );
    }, columns
  );
  return (
    <TableRow key={trainGroup.id} {...{
      role: 'checkbox', tabIndex, selected: includes(trainGroup, activeTrainGroups)
    }}>
      {formattedColumns}
    </TableRow>
  );
};
export default TrainOverviewTrainGroupTableRow;
