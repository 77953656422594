import React, {useEffect, useState} from 'react';
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import SecondaryButton
  from 'pages/trafficsim/trafficSimComponents/themedComponents/buttons/GenericButtons/SecondaryButton/SecondaryButton.tsx';
import PrimaryButton
  from 'pages/trafficsim/trafficSimComponents/themedComponents/buttons/GenericButtons/PrimaryButton/PrimaryButton.tsx';
import {restrictionTypesKeys} from 'pages/trafficsim/api/data/consts.ts';
import {STATION_LOCATIONS} from 'pages/trafficsim/trafficSimComponents/track/utils/stations.ts';
import {useTheme} from "@mui/styles";
import CustomTooltip from "../themedComponents/CustomTooltip.tsx";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmationDialog from "../themedComponents/DeleteConfirmationDialog.tsx";

const styles = {
  formControl: {
    margin: '4px 0',
  },
  button: {
    margin: '8px 4px 4px 0',
  },
  drawerContent: {
    padding: '8px',
    width: '280px',
    maxHeight: '100vh',
    overflowY: 'auto',
  },
  textField: {
    fontSize: '0.7rem',
  },
  inputRoot: {
    fontSize: '0.7rem',
    height: '20px',
  },
  labelRoot: {
    fontSize: '0.7rem',
  },
};

const filter = createFilterOptions();

const AddLimitation = ({
                         isNetworkCallActive = false,
                         onMoveToCoord = (coordinates, zoom = 16) => {
                         },
                         showPositionMarker = (status) => {
                         },
                         clearEditData = () => {
                         },
                         onCloseDrawer = () => {
                         },
                         onCreateRestriction = (restrictionDataObject) => {
                         },
                         onUpdateRestriction = (restrictionDataObject) => {
                         },
                         onDeleteRestriction = (restriction) => {},
                         dataToEdit,
                       }) => {
  const theme = useTheme();
  const checkEditDataString = (value) => (value ? value : '');
  const checkEditDataNull = (value) => (value ? value : null);
  const checkBoolean = (value) => (value ? value : false);
  const checkIsFurtherNotice = (value) => (value === null || value === undefined);

  const startDate = dataToEdit.startTimestamp ? new Date(dataToEdit.startTimestamp) : null;
  const startDateString = startDate ? startDate.toISOString().substring(0, 10) : "";
  const startDateTimeString = startDate ? startDate.toTimeString().substring(0, 5) : "";

  const endDate = dataToEdit.endTimestamp ? new Date(dataToEdit.endTimestamp) : null;
  const endDateString = endDate ? endDate.toISOString().substring(0, 10) : "";
  const endDateTimeString = endDate ? endDate.toTimeString().substring(0, 5) : "";
  const [isDialogOpen, setDialogOpen] = useState(false);

  const stations = STATION_LOCATIONS;

  const [selectedRestrictionType, setSelectedRestrictionType] = useState(
    checkEditDataString(dataToEdit.restrictionType),
  );
  const [statPlas, setStatPlas] = useState(checkEditDataNull(dataToEdit.startPlace));
  const [slutPlat, setSlutPlat] = useState(checkEditDataNull(dataToEdit.endPlace));
  const [berordaVaxlar, setBerordaVaxlar] = useState(
    checkEditDataString(dataToEdit.switchId),
  );
  const [orsakTillBegransning, setOrsakTillBegransning] = useState(
    checkEditDataString(dataToEdit.creationReason),
  );
  const [notering, setNotering] = useState(checkEditDataString(dataToEdit.notes));
  const [begransningStatus, setBegransningStatus] = useState(
    dataToEdit.status && dataToEdit.status === "completed" ? 'upcoming' : 'current',
  );
  const [authorizedBy, setAuthorizedBy] = useState(
    checkEditDataString(dataToEdit.authorizedBy),
  );
  const [hastighet, setHastighet] = useState(checkEditDataString(dataToEdit.speedLimit));
  const [isTilFurtherNoticeChecked, setIsTilFurtherNoticeChecked] = useState<boolean>(checkIsFurtherNotice(dataToEdit.endTimestamp));
  const [isRunningInBothDirection, setIsRunningInBothDirection] = useState<boolean>(checkBoolean(dataToEdit.isRunningInBothDirection));

  const [restrictionStartDay, setRestrictionStartDay] = useState(
    checkEditDataString(startDateString),
  );
  const [restrictionEndDay, setRestrictionEndDay] = useState(
    checkEditDataString(endDateString),
  );
  const [restrictionStartDayTime, setRestrictionStartDayTime] = useState(
    checkEditDataString(startDateTimeString),
  );
  const [restrictionEndDayTime, setRestrictionEndDayTime] = useState(
    checkEditDataString(endDateTimeString),
  );
  const [error, setErrorStatus] = useState(false);
  const [errorLog, setErrorLog] = useState({});

  useEffect(() => {
    if (Object.keys(dataToEdit).length > 0) {
      const coordinates = dataToEdit.coordinates;
      onMoveToCoord(coordinates);
    }
    showPositionMarker(true);
    return () => {
      showPositionMarker(false);
    };
  }, []);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteClick = () => {
    setDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    onDeleteRestriction(dataToEdit);
    setDialogOpen(false);
  };

  const handleSelectBegransningType = (event) => {
    setErrorStatus(false);
    setSelectedRestrictionType(event.target.value);
  };

  const handleChangeBegransningStatus = (event) => {
    setErrorStatus(false);
    setBegransningStatus(event.target.value);
  };

  const checkEntry = (entry, entryName) => {
    if (entry && entry !== '') {
      delete errorLog[entryName];
      return true;
    }
    errorLog[entryName] = `${entryName} måste anges`;
    return false;
  };

  const checkSpeedLimitEntry = (entry, entryName) => {
    const speedLimit = parseInt(entry, 10);
    if (speedLimit) {
      delete errorLog[entryName];
      return true;
    }
    errorLog[entryName] = `${entryName}  måste vara ett giltigt nummer`;
    return false;
  };


  const showError = () => {
    setErrorStatus(true);
    setErrorLog(errorLog);
  };

  const validateEntries = () => {
    setErrorStatus(false);
    if (
      checkEntry(selectedRestrictionType, 'Begränsning') &&
      checkEntry(statPlas, 'StatPlas') &&
      checkEntry(slutPlat, 'SlutPlat') &&
      checkEntry(restrictionStartDay, 'Beraknad tidsperiod') &&
      checkEntry(restrictionStartDayTime, 'Beraknad tid') &&
      checkEntry(authorizedBy, '"Begransning inlagd på begaran av"') &&
      checkEntry(begransningStatus, 'Begränsning Status')
    ) {
      if (selectedRestrictionType === restrictionTypesKeys.speedLimit) {
        if (!checkSpeedLimitEntry(hastighet, 'Hastighetsbegransning')) return false;
      }

      if (!isTilFurtherNoticeChecked) {
        return (
          checkEntry(restrictionEndDay, 'Beraknad tidsperiod') &&
          checkEntry(restrictionEndDayTime, 'Beraknad tid')
        );
      }

      return true;
    }
    Object.entries(errorLog).forEach(([id, str]) => console.log(str));
    return false;
  };

  const restrictionOptions = [
    {value: 'closedTrack', label: 'Avstängt spår'},
    {value: 'backProhibition', label: 'Backförbud'},
    {value: 'speedLimit', label: 'Hastighetsbegränsning'},
    {value: 'other', label: 'Annan begränsning'},
  ];

  return (
    <div style={styles.drawerContent}>
      <FormControl sx={{m: 0, minWidth: 260, marginBottom: 1}} size="small">
        <InputLabel id="restriction-select-label" sx={{fontSize: '12px'}}>
          Ange begränsning
        </InputLabel>
        <Select
          labelId="restriction-select-label"
          id="restriction-select"
          value={selectedRestrictionType}
          onChange={handleSelectBegransningType}
          label="Ange begransning"
          sx={{fontSize: '12px'}}
        >
          {restrictionOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}
                      sx={{fontSize: '12px', color: theme.palette.text.primary}}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box>
        <Typography variant="subtitle2">Plats/riktning</Typography>

        <Autocomplete
          value={statPlas}
          onChange={(event, newValue) => {
            setErrorStatus(false);
            if (typeof newValue === 'string') {
              setStatPlas(newValue);
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setStatPlas(newValue.inputValue);
            } else {
              setStatPlas(newValue);
              if (
                newValue !== null &&
                Array.isArray(newValue.coordinates) &&
                newValue.coordinates.length > 1
              ) {
                const coordinates = newValue.coordinates;
                onMoveToCoord(coordinates);
              }
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const {inputValue} = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.name);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="free-solo-with-text-demo"
          options={stations}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.name;
          }}
          renderOption={(props, option) => {
            const key = option.id;
            delete props.key;
            return <li key={key} {...props}>{option.name}</li>
          }}
          sx={{margin: '8px 4px 4px 0'}}
          freeSolo
          renderInput={(params) => <TextField {...params} label="Startplats"/>}
          margin="dense"
          fullWidth
        />

        <Autocomplete
          value={slutPlat}
          onChange={(event, newValue) => {
            setErrorStatus(false);

            if (typeof newValue === 'string') {
              setSlutPlat(newValue);
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setSlutPlat(newValue.inputValue);
            } else {
              setSlutPlat(newValue);
              if (
                newValue !== null &&
                Array.isArray(newValue.coordinates) &&
                newValue.coordinates.length > 1
              ) {
                const coordinates = newValue.coordinates;
                onMoveToCoord(coordinates);
              }
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const {inputValue} = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.name);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="free-solo-with-text-demo"
          options={stations}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.name;
          }}
          renderOption={(props, option) => {
            const key = option.id;
            delete props.key;
            return <li key={key} {...props}>{option.name}</li>
          }}
          sx={{margin: '8px 4px 4px 0'}}
          freeSolo
          renderInput={(params) => <TextField {...params} label="Slutplats"/>}
          margin="dense"
          fullWidth
        />

        <Box style={{textAlign: 'right'}}>
          <SecondaryButton
            color="primary"
            size="small"
            onClick={() => {
              if (Object.keys(dataToEdit).length > 0) {
                const coordinates = dataToEdit.coordinates;
                onMoveToCoord(coordinates);
                return;
              }

              if (slutPlat) {
                const {coordinates} = slutPlat;
                onMoveToCoord(coordinates);
                return;
              }

              if (statPlas) {
                const {coordinates} = statPlas;
                onMoveToCoord(coordinates);
              }
            }}
          >
            Visa
          </SecondaryButton>
        </Box>
      </Box>

      <Box style={{display: 'flex', justifyContent: 'flex-start'}}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={isRunningInBothDirection}
                onChange={() => {
                  setIsRunningInBothDirection(!isRunningInBothDirection);
                }}
                size="small"
              />
            }
            label={<Typography style={{fontSize: '14px'}}>Båda riktningar</Typography>}
            style={styles.labelRoot}
          />
        </FormGroup>
      </Box>

      {selectedRestrictionType &&
        selectedRestrictionType === restrictionTypesKeys.speedLimit && (
          <Box>
            <Typography variant="subtitle2">Största tillåtna hastighet (km/h)</Typography>
            <TextField
              margin="dense"
              fullWidth
              InputProps={{style: styles.inputRoot}}
              InputLabelProps={{style: styles.labelRoot}}
              value={hastighet}
              onChange={(event) => {
                setHastighet(event.target.value);
              }}
            />
          </Box>
        )}

      <Box>
        <Typography variant="subtitle2">Berörda växlar</Typography>
        <TextField
          margin="dense"
          fullWidth
          InputProps={{style: styles.inputRoot}}
          InputLabelProps={{style: styles.labelRoot}}
          value={berordaVaxlar}
          onChange={(event) => {
            setBerordaVaxlar(event.target.value);
          }}
        />
      </Box>
      <Box>
        <Typography variant="subtitle2">Orsak till begränsning</Typography>
        <TextField
          value={orsakTillBegransning}
          onChange={(event) => {
            setOrsakTillBegransning(event.target.value);
          }}
          margin="dense"
          fullWidth
          InputProps={{style: styles.inputRoot}}
          InputLabelProps={{style: styles.labelRoot}}
        />
      </Box>
      <Box style={{marginTop: '30px'}}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <TextField
              label="Beräknad tidsperiod"
              type="date"
              fullWidth
              value={restrictionStartDay}
              InputLabelProps={{
                shrink: true,
                style: styles.labelRoot,
              }}
              InputProps={{style: styles.inputRoot}}
              onChange={(event) => {
                setErrorStatus(false);
                setRestrictionStartDay(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs>
            <TextField
              label=""
              type="time"
              fullWidth
              value={restrictionStartDayTime}
              InputLabelProps={{
                shrink: true,
                style: styles.labelRoot,
              }}
              InputProps={{style: styles.inputRoot}}
              onChange={(event) => {
                setErrorStatus(false);
                setRestrictionStartDayTime(event.target.value);
              }}
            />
          </Grid>
        </Grid>

        {!isTilFurtherNoticeChecked && (
          <Grid style={{marginTop: '8px'}} container spacing={1} alignItems="center">
            <Grid item xs>
              <TextField
                label="Beraknad sluttidsperiod"
                type="date"
                fullWidth
                value={restrictionEndDay}
                InputLabelProps={{
                  shrink: true,
                  style: styles.labelRoot,
                }}
                InputProps={{style: styles.inputRoot}}
                onChange={(event) => {
                  setErrorStatus(false);
                  setRestrictionEndDay(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                label=""
                type="time"
                fullWidth
                value={restrictionEndDayTime}
                InputLabelProps={{
                  shrink: true,
                  style: styles.labelRoot,
                }}
                InputProps={{style: styles.inputRoot}}
                onChange={(event) => {
                  setErrorStatus(false);
                  setRestrictionEndDayTime(event.target.value);
                }}
              />
            </Grid>
          </Grid>
        )}

        <Box style={{display: 'flex', justifyContent: 'flex-start'}}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isTilFurtherNoticeChecked}
                  onChange={() => {
                    setErrorStatus(false);
                    setIsTilFurtherNoticeChecked(!isTilFurtherNoticeChecked);
                  }}
                  size="small"
                />
              }
              label={<Typography style={{fontSize: '14px'}}>Tills vidare</Typography>}
              style={styles.labelRoot}
            />
          </FormGroup>
        </Box>
      </Box>
      <Box>
        <Typography variant="subtitle2">Begränsning inlagd på begäran av:</Typography>
        <TextField
          margin="dense"
          fullWidth
          InputProps={{style: styles.inputRoot}}
          InputLabelProps={{style: styles.labelRoot}}
          value={authorizedBy}
          onChange={(event) => {
            setErrorStatus(false);
            setAuthorizedBy(event.target.value);
          }}
        />
      </Box>
      <Box>
        <Typography variant="subtitle2">Notering</Typography>
        <TextField
          margin="dense"
          fullWidth
          rows={4}
          multiline
          InputProps={{style: styles.textField}}
          InputLabelProps={{style: styles.labelRoot}}
          value={notering}
          onChange={(event) => {
            setNotering(event.target.value);
          }}
        />
      </Box>

      <FormControl component="fieldset" size="small">
        <FormLabel component="legend" style={styles.labelRoot}>
          Status
        </FormLabel>
        <RadioGroup
          aria-label="status"
          name="status"
          value={begransningStatus}
          onChange={handleChangeBegransningStatus}
        >
          <FormControlLabel
            value="current"
            control={<Radio size="small"/>}
            label={
              <Typography style={{fontSize: '14px'}}>Aktuell begränsning</Typography>
            }
          />
          <FormControlLabel
            value="upcoming"
            control={<Radio size="small"/>}
            label={
              <Typography style={{fontSize: '14px'}}>Kommande begränsning</Typography>
            }
          />
        </RadioGroup>
      </FormControl>

      {error && (
        <div
          style={{
            display: 'flex',
            color: 'red',
            marginTop: '8px',
            justifyContent: 'flex-start',
          }}
        >
          <Box>
            <Typography variant="subtitle2">
              Fel i formuläret - rätta till och försök igen:
            </Typography>
            {Object.entries(errorLog).map(([id, errorStr]) => (
              <li key={id}>{errorStr}</li>
            ))}
          </Box>
        </div>
      )}

      <div style={{display: 'flex', marginTop: '8px', justifyContent: 'space-between'}}>
        <Box>
          {dataToEdit && dataToEdit.status !== undefined && <Stack direction="row" spacing={2}>
            <CustomTooltip title="Ta bort begränsning">
              <IconButton onClick={() => {
                // onDeleteRestriction(dataToEdit)
                handleDeleteClick()
              }} color="primary" aria-label="zoom to location">
                <DeleteIcon fontSize={"medium"}/>
              </IconButton>
            </CustomTooltip>
            <DeleteConfirmationDialog
                open={isDialogOpen}
                onClose={handleClose}
                onConfirm={handleConfirmDelete}
            />
          </Stack>}
        </Box>

        <Box style={{display: 'flex', justifyContent: 'flex-end'}}>
          {isNetworkCallActive && <CircularProgress/>}
          <PrimaryButton
            variant="contained"
            size="small"
            style={{marginLeft: '5px', marginRight: '5px'}}
            onClick={() => {
              if (validateEntries()) {
                if (Object.keys(dataToEdit).length > 0) {
                  onUpdateRestriction({
                    id: dataToEdit.id,
                    endPlace: slutPlat,
                    startPlace: statPlas,
                    switchId: berordaVaxlar,
                    creationReason: orsakTillBegransning,
                    notes: notering,
                    status: begransningStatus,
                    isRunningInBothDirection,
                    authorizedBy,
                    startTimestamp: `${restrictionStartDay}T${restrictionStartDayTime}`,
                    endTimestamp: !isTilFurtherNoticeChecked && restrictionStartDay !== null ? `${restrictionEndDay}T${restrictionEndDayTime}` : null,
                    restrictionType: selectedRestrictionType,
                    speedLimit: hastighet === "" ? null : hastighet,
                  });
                  return;
                }
                onCreateRestriction({
                  endPlace: slutPlat,
                  startPlace: statPlas,
                  switchId: berordaVaxlar,
                  creationReason: orsakTillBegransning,
                  notes: notering,
                  status: begransningStatus,
                  isRunningInBothDirection,
                  authorizedBy,
                  startTimestamp: `${restrictionStartDay}T${restrictionStartDayTime}`,
                  endTimestamp: !isTilFurtherNoticeChecked && restrictionEndDay !== null ? `${restrictionEndDay}T${restrictionEndDayTime}` : null,
                  restrictionType: selectedRestrictionType,
                  speedLimit: hastighet === "" ? null : hastighet,
                });
                return;
              }
              showError();
            }}
          >
            Spara
          </PrimaryButton>
          <PrimaryButton
            variant="outlined"
            size="small"
            onClick={() => {
              onCloseDrawer();
              showPositionMarker(false);
              clearEditData();
            }}
          >
            Avbryt
          </PrimaryButton>
        </Box>
      </div>
    </div>
  );
};

export default AddLimitation;
