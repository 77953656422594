import React, {SyntheticEvent, useMemo, useState} from 'react';
import {equals, is, map} from 'ramda';
import {Button, Menu, MenuItem, Stack, Typography} from '@mui/material';
import {cemitColors} from 'theme/cemitColors.ts';
import {ArrowDropDown} from '@mui/icons-material';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {TFunction} from 'i18next';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {TrainGroupClassification} from 'types/trainGroups/trainGroupClassification.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {findOrThrow} from 'utils/functional/functionalUtils.ts';
import {
  useNotLoadingMemoResolveCurrentTrainGroupClassification
} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainGroupHooks.ts';

export interface TrainGroupClassificationPickerProps extends CemitComponentProps {
  t: TFunction;
  trainGroupClassifications: TrainGroupClassification[];
  trainGroupClassificationSourceKey: string;
  setTrainGroupClassificationSourceKey: StateSetter<string>;
}

/**
 * Allows setting appProps.trainGroupClassification from trainGroupClassifications
 * @param trainGroupClassifications
 * @param trainGroupClassification
 * @param setTrainGroupClassificationSourceKey
 * @constructor
 */
const TrainGroupClassificationPicker = (
  {
    t,
    trainGroupClassifications,
    trainGroupClassificationSourceKey,
    setTrainGroupClassificationSourceKey
  }: TrainGroupClassificationPickerProps) => {
  const [anchorEl, setAnchorEl] = useState<Perhaps<EventTarget & Element>>(undefined);

  const trainGroupClassification: TrainGroupClassification = useNotLoadingMemoResolveCurrentTrainGroupClassification(
    false,
    trainGroupClassifications,
    trainGroupClassificationSourceKey
  );
  const open = Boolean(anchorEl);
  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = (_e: SyntheticEvent, trainGroupClassification: TrainGroupClassification) => {
    setAnchorEl(undefined);
    // if trainGroupClassification comes in as the string 'backDrop',
    // it means the user clicked outside the menu
    if (!is(String, trainGroupClassification)) {
      setTrainGroupClassificationSourceKey(trainGroupClassification.sourceKey);
    }
  };
  const trainGroupClassificationComponents = useMemo<JSX.Element[]>(() => {
    return map((trainGroupClassification: TrainGroupClassification) => {
      return (
        <MenuItem
          key={trainGroupClassification.name}
          {...{
            disabled: equals(trainGroupClassificationSourceKey, trainGroupClassification.sourceKey),
            onClick: (e: SyntheticEvent) => onClose(e, trainGroupClassification)
          }}
        >
          {trainGroupClassification.name}
        </MenuItem>
      );
    }, trainGroupClassifications);
  }, [trainGroupClassifications, trainGroupClassificationSourceKey]);

  return (
    <Stack {...{sx: {alignSelf: 'center'}}}>
      <Stack {...{spacing: 0.5, direction: 'row', sx: {alignItems: 'center'}}} >
        <Typography {...{color: 'secondary'}}>{t('selectedTrainClass')}</Typography>
        <Button
          key="button"
          {...{
            id: 'trainGroupClassification-button',
            color: 'secondary',
            'aria-controls': open ? 'trainGroupClassification-positioned-menu' : undefined,
            'aria-haspopup': 'true',
            'aria-expanded': open ? 'true' : undefined,
            onClick: handleClick
          }}
        >
          <Stack {...{direction: 'row', spacing: 1, sx: {alignItems: 'center'}}}>
            <ArrowDropDown key="icon" />
            <Typography key="label">{trainGroupClassification.name}</Typography>
          </Stack>
        </Button>
      </Stack>
      <Menu
        key="menu"
        {...{
          id: 'formation-positioned-menu',
          'aria-labelledby': 'formation-button',
          anchorEl,
          open,
          onClose,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
      >
        {trainGroupClassificationComponents}
      </Menu>
    </Stack>
  );
};
export default TrainGroupClassificationPicker;
