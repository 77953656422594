import {trainApiConfig} from './trainApiOrganizationConfig.ts';
import {OrganizationManifest} from 'types/organizations/organizationManifest';
import {CemitTypename} from 'types/cemitTypename.ts';
import {OrganizationMinimized} from 'types/organizations/organization.ts';

import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {visionApiConfig} from 'apis/apiOrganizationConfigs/visionApiOrganizationConfig.ts';
import networkRailAngelTrains from 'assets/images/organizations/networkRailAngelTrains.png'

// OrganizationSourceKeys match OrganizationModels from the train-api

export enum OrganizationSourceKey {
  flytoget = 'flytoget',
  gothenburg = 'gothenburg',
  kiwiRail = 'kiwi_rail',
  mantena = 'mantena',
  sporveien = 'sporveien',
  southWesternRailway = 'south_western_railway',
  transportise = 'transportise',
  angelTrain = 'angel_train',
  atgNamibia = 'atg_namibia',
  leipzig = 'leipzig',
  highSpeed1 = 'high_speed_1',
  jernhusen = 'jernhusen'
}

export const organizationManifests: OrganizationManifest[] = [
  clsOrType<OrganizationManifest>(CemitTypename.organization, {
    apiConfig: trainApiConfig,
    organization: clsOrType<OrganizationMinimized>(CemitTypename.organizationMinimized, {
      sourceKey: OrganizationSourceKey.flytoget,
    }),
  }),
  clsOrType<OrganizationManifest>(CemitTypename.organization, {
    apiConfig: trainApiConfig,
    organization: clsOrType<OrganizationMinimized>(CemitTypename.organizationMinimized, {
      sourceKey: OrganizationSourceKey.gothenburg,
      isDarkLightEnabled: true,
    }),
    // This is needed because admins don't get access to tracksim unless they have this accessKey
    applicationAccess: ['tracksim'],
  }),
  clsOrType<OrganizationManifest>(CemitTypename.organization, {
    apiConfig: trainApiConfig,
    organization: clsOrType<OrganizationMinimized>(CemitTypename.organizationMinimized, {
      sourceKey: OrganizationSourceKey.kiwiRail,
    }),
  }),
  clsOrType<OrganizationManifest>(CemitTypename.organization, {
    apiConfig: trainApiConfig,
    organization: clsOrType<OrganizationMinimized>(CemitTypename.organizationMinimized, {
      sourceKey: OrganizationSourceKey.sporveien,
    }),
  }),
  // TEMP hack
  clsOrType<OrganizationManifest>(CemitTypename.organization, {
    apiConfig: visionApiConfig,
    organization: clsOrType<OrganizationMinimized>(CemitTypename.organizationMinimized, {
      sourceKey: OrganizationSourceKey.sporveien + 'VISION',
    }),
  }),
  clsOrType<OrganizationManifest>(CemitTypename.organization, {
    apiConfig: trainApiConfig,
    organization: clsOrType<OrganizationMinimized>(CemitTypename.organizationMinimized, {
      sourceKey: OrganizationSourceKey.southWesternRailway,
    }),
    partnerOrganization: clsOrType<OrganizationMinimized>(
      CemitTypename.organizationMinimized,
      {
        sourceKey: OrganizationSourceKey.transportise,
      },
    ),
  }),
  clsOrType<OrganizationManifest>(CemitTypename.organization, {
    apiConfig: trainApiConfig,
    organization: clsOrType<OrganizationMinimized>(CemitTypename.organizationMinimized, {
      sourceKey: OrganizationSourceKey.angelTrain,
      logos: [networkRailAngelTrains]
    }),
  }),
  clsOrType<OrganizationManifest>(CemitTypename.organization, {
    apiConfig: trainApiConfig,
    organization: clsOrType<OrganizationMinimized>(CemitTypename.organizationMinimized, {
      sourceKey: OrganizationSourceKey.atgNamibia,
    }),
  }),
  clsOrType<OrganizationManifest>(CemitTypename.organization, {
    apiConfig: trainApiConfig,
    organization: clsOrType<OrganizationMinimized>(CemitTypename.organizationMinimized, {
      sourceKey: OrganizationSourceKey.atgNamibia,
    }),
  }),
  clsOrType<OrganizationManifest>(CemitTypename.organization, {
    apiConfig: trainApiConfig,
    organization: clsOrType<OrganizationMinimized>(CemitTypename.organizationMinimized, {
      sourceKey: OrganizationSourceKey.leipzig
    }),
  }),
  clsOrType<OrganizationManifest>(CemitTypename.organization, {
    apiConfig: trainApiConfig,
    organization: clsOrType<OrganizationMinimized>(CemitTypename.organizationMinimized, {
      sourceKey: OrganizationSourceKey.highSpeed1
    }),
  }),
  clsOrType<OrganizationManifest>(CemitTypename.organization, {
    apiConfig: trainApiConfig,
    organization: clsOrType<OrganizationMinimized>(CemitTypename.organizationMinimized, {
      sourceKey: OrganizationSourceKey.jernhusen
    }),
  }),
];
