export type Order = "asc" | "desc"

export interface TableHeaderData {
    text: string,
    key: string
}

export const tableHeaderObj: { [n: string]: TableHeaderData } = {
    restriction: {
        text: "Begränsning",
        key: "restrictionType"
    },
    place: {
        text: "Plats",
        key: "startPlace"
    },
    switchId: {
        text: "Berörda",
        key: "switchId"
    },
    speedLimit: {
        text: "Hastighet",
        key: "speedLimit"
    },
    creationReason: {
        text: "Orsak",
        key: "creationReason"
    },
    startTimestamp: {
        text: "Starttid",
        key: "startTimestamp"
    },
    endTimestamp: {
        text: "Sluttid",
        key: "endTimestamp"
    },
    details: {
        text: "Detaljer",
        key: "details"
    },
}
export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {

    let leftValue: any;
    let rightValue: any;

    if(a && a.restriction !== undefined){
        leftValue = b.restriction[orderBy];
        rightValue = a.restriction[orderBy];
    } else {
        leftValue = b[orderBy];
        rightValue = a[orderBy];
    }


    if(!leftValue) return -1;
    if(!rightValue) return 1;


    if(orderBy === tableHeaderObj.place.key){
        leftValue = leftValue.name;
        rightValue = rightValue.name;
    }

    if(orderBy === tableHeaderObj.startTimestamp || orderBy === tableHeaderObj.endTimestamp){
        leftValue = new Date(leftValue);
        rightValue = new Date(rightValue);
    }


    if (leftValue < rightValue) {
        return -1;
    }
    if (leftValue > rightValue) {
        return 1;
    }

    return 0;
}

export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
