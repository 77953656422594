// These variables are written in by Webpack
import {TFunction} from 'i18next';
import {TrainIcon} from 'components/apps/cemitAppComponents/svgIconComponents/sidebar/trainIcon.tsx';
import {TrafficSimIcon} from 'components/apps/cemitAppComponents/svgIconComponents/sidebar/trafficSimIcon.tsx';
import {SoftprioIcon} from 'components/apps/cemitAppComponents/svgIconComponents/sidebar/softprioIcon.tsx';
import {RailIcon} from 'components/apps/cemitAppComponents/svgIconComponents/sidebar/railIcon.tsx';
import {CatenaryIcon} from 'components/apps/cemitAppComponents/svgIconComponents/sidebar/catenaryIcon.tsx';
import {StationIcon} from 'components/apps/cemitAppComponents/svgIconComponents/sidebar/stationIcon.tsx';
import {GrpsIcon} from 'components/apps/cemitAppComponents/svgIconComponents/sidebar/grpsIcon.tsx';
import {DepotIcon} from 'components/apps/cemitAppComponents/svgIconComponents/sidebar/depotIcon.tsx';
import {EventIcon} from 'components/apps/cemitAppComponents/svgIconComponents/sidebar/eventIcon.tsx';
import {BudgetIcon} from 'components/apps/cemitAppComponents/svgIconComponents/sidebar/budgetIcon.tsx';
import ProfileIcon from '../../components/apps/cemitAppComponents/svgIconComponents/sidebar/profileIcon.tsx';
import {SvgProps} from 'components/apps/cemitAppComponents/svgIconComponents/sidebar/SvgProps.ts';
import {AppSettings} from './appSettings.ts';
import {SwitchIcon} from 'components/apps/cemitAppComponents/svgIconComponents/sidebar/switchIcon.tsx';
import {CemitTypename} from 'types/cemitTypename.ts';
import {ApplicationNavigation} from 'types/navigation/applicationNavigation';
import {VisionIcon} from 'components/apps/cemitAppComponents/svgIconComponents/sidebar/visionIcon.tsx';
import {clsOrTypes} from 'appUtils/typeUtils/clsOrTypes.ts';

/**
 * Creates a list of TabNavigation items representing the possible Visualizer applications
 *
 * See also trafficSimPages/auth/utils.ts for application access control.
 *
 * @param t Used for translation
 * @returns The TabNavigation items
 */
export const applicationNavigationConfig = (
  t: TFunction,
): {
  name: string;
  to: string;
  routeRegex: RegExp;
  Icon: ({fill, stroke}: SvgProps) => JSX.Element;
  accessKey: string;
}[] => {
  const appsPath = AppSettings.appsPath;

  return clsOrTypes<ApplicationNavigation>(CemitTypename.applicationNavigation, [
    {
      accessKey: 'train',
      name: t('train'),
      to: `/${appsPath}/train/overview`,
      routeRegexp: new RegExp(`/${appsPath}/train(/.*)?$`),
      Icon: TrainIcon,
      isVisible:
        process.env.REACT_FEATURE_TRAIN === 'true' ||
        process.env.REACT_FEATURE_MENU_TRAIN === 'true',
      isEnabled: process.env.REACT_FEATURE_TRAIN === 'true',
    },
    {
      accessKey: 'tracksim',
      name: t('trafficsim'),
      to: `/${appsPath}/trafficsim/overview`,
      routeRegexp: new RegExp(`/${appsPath}/trafficsim(/.*)?$`),
      Icon: TrafficSimIcon,
      isHiddenWithoutAccessKey: true,
      isVisible:
        process.env.REACT_FEATURE_TRACK_SIM === 'true' ||
        process.env.REACT_FEATURE_MENU_TRACK_SIM === 'true',
      isEnabled: process.env.REACT_FEATURE_TRACK_SIM === 'true',
    },
    {
      accessKey: 'softprio',
      name: t('softprio'),
      to: `/${appsPath}/softprio/overview`,
      routeRegexp: new RegExp(`/${appsPath}/softprio(/.*)?$`),
      Icon: SoftprioIcon,
      isHiddenWithoutAccessKey: true,
      isVisible:
        process.env.REACT_FEATURE_SOFTPRIO === 'true' ||
        process.env.REACT_FEATURE_MENU_SOFTPRIO === 'true',
      isEnabled: process.env.REACT_FEATURE_SOFTPRIO === 'true',
    },
    {
      accessKey: 'switch',
      name: t('switch'),
      to: `/${appsPath}/switch/overview`,
      routeRegexp: new RegExp(`/${appsPath}/switch(/.*)?$`),
      Icon: SwitchIcon,
      isHiddenWithoutAccessKey: false,
      isVisible:
        process.env.REACT_FEATURE_SWITCH === 'true' ||
        process.env.REACT_FEATURE_MENU_SWITCH === 'true',
      isEnabled: process.env.REACT_FEATURE_SWITCH === 'true',
    },
    {
      accessKey: 'vision_poc',
      name: t('vision'),
      to: `/${appsPath}/vision/overview?year=2024&week=34&km=7.507109014557967&trackIdentifier=no_oslo_subway_kolsas_ib&fromIcon=true`,
      routeRegexp: new RegExp(`/${appsPath}/vision(/.*)?$`),
      Icon: VisionIcon,
      isHiddenWithoutAccessKey: true,
      isVisible:
        process.env.REACT_FEATURE_VISION === 'true' ||
        process.env.REACT_FEATURE_MENU_VISION === 'true',
      isEnabled: process.env.REACT_FEATURE_VISION === 'true',
      // Only show this button as disabled when it is active. Hide other apps
      // This is temp for Vision to be shown in a separate tab
      hideAllOthersWhenActive: true,
    },
    {
      accessKey: 'rails',
      name: t('track'),
      to: `/${appsPath}/index/overview`,
      routeRegexp: new RegExp(`/${appsPath}/index(/.*)?$`),
      Icon: RailIcon,
      isVisible:
        process.env.REACT_FEATURE_TRACK === 'true' ||
        process.env.REACT_FEATURE_MENU_TRACK === 'true',
      isEnabled: process.env.REACT_FEATURE_TRACK === 'true',
    },
    {
      accessKey: 'catenary',
      name: t('catenary'),
      to: `/${appsPath}/catenary/overview`,
      routeRegexp: new RegExp(`/${appsPath}/catenary(/.*)?$`),
      Icon: CatenaryIcon,
      isVisible:
        process.env.REACT_FEATURE_CATENARY === 'true' ||
        process.env.REACT_FEATURE_MENU_CATENARY === 'true',
      isEnabled: process.env.REACT_FEATURE_CATENARY === 'true',
    },
    {
      accessKey: 'stations',
      name: t('stations'),
      to: `/${appsPath}/station/overview`,
      routeRegexp: new RegExp(`/${appsPath}/station(/.*)?$`),
      Icon: StationIcon,
      isVisible:
        process.env.REACT_FEATURE_STATIONS === 'true' ||
        process.env.REACT_FEATURE_MENU_STATIONS === 'true',
      isEnabled: process.env.REACT_FEATURE_STATIONS === 'true',
    },
    {
      accessKey: 'depots',
      name: t('depots'),
      to: `/${appsPath}/depots/overview`,
      routeRegexp: new RegExp(`/${appsPath}/depots(/.*)?$`),
      Icon: DepotIcon,
      isVisible:
        process.env.REACT_FEATURE_DEPOTS === 'true' ||
        process.env.REACT_FEATURE_MENU_DEPOTS === 'true',
      isEnabled: process.env.REACT_FEATURE_DEPOTS === 'true',
    },
    {
      accessKey: 'grps',
      name: t('grps'),
      to: `/${appsPath}/grps/overview`,
      routeRegexp: new RegExp(`/${appsPath}/grps(/.*)?$`),
      Icon: GrpsIcon,
      isVisible:
        process.env.REACT_FEATURE_ROUTE_PLANNER === 'true' ||
        process.env.REACT_FEATURE_MENU_ROUTE_PLANNER === 'true',
      isEnabled: process.env.REACT_FEATURE_ROUTE_PLANNER === 'true',
    },
    {
      accessKey: 'events',
      name: t('events'),
      to: `/${appsPath}/events`,
      routeRegexp: new RegExp(`/${appsPath}/events(/.*)?$`),
      Icon: EventIcon,
      isVisible:
        process.env.REACT_FEATURE_EVENTS === 'true' ||
        process.env.REACT_FEATURE_MENU_EVENTS === 'true',
      isEnabled: process.env.REACT_FEATURE_EVENTS === 'true',
    },
    {
      accessKey: 'budgets',
      name: t('budgets'),
      to: `/${appsPath}/budgets/overview`,
      routeRegexp: new RegExp(`/${appsPath}/budgets/overview(/.*)?$`),
      Icon: BudgetIcon,
      isVisible:
        process.env.REACT_FEATURE_BUDGET === 'true' ||
        process.env.REACT_FEATURE_MENU_BUDGET === 'true',
      isEnabled: process.env.REACT_FEATURE_BUDGET === 'true',
    },
    {
      accessKey: null,
      name: t('profile'),
      to: `/profile`,
      routeRegexp: new RegExp(`/profile(/.*)?$`),
      Icon: ProfileIcon,
      isVisible:
        process.env.REACT_FEATURE_PROFILE === undefined ||
        process.env.REACT_FEATURE_PROFILE === 'true' ||
        process.env.REACT_FEATURE_MENU_PROFILE === 'true',
      isEnabled:
        process.env.REACT_FEATURE_PROFILE === undefined ||
        process.env.REACT_FEATURE_PROFILE === 'true',
    },
  ]);
};
