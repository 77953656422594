import React, {useEffect, useState} from 'react';
import {useTheme} from '@mui/styles';
import {Box, Container, Drawer, IconButton, Typography} from '@mui/material';
import CloseStyledButton from 'pages/trafficsim/trafficSimComponents/themedComponents/CloseStyledButton.tsx';
import {ArrowBackIosNewRounded, ArrowForwardIosRounded} from '@mui/icons-material';
import CustomTooltip from 'pages/trafficsim/trafficSimComponents/themedComponents/CustomTooltip.tsx';
import {TRAFFIC_SIM_PAGE_NAME} from "../../utils/page_names.ts";

function DrawerStyled({
  open,
  onClose,
  title,
  children,
  currentPage,
  isExpanded,
  setIsExpanded,
}) {
  const theme = useTheme();
  const [drawerWidth, setDrawerWidth] = useState('300px');

  const getIcon = () =>
    isExpanded ? (
      <ArrowForwardIosRounded
        sx={{
          fontSize: '14px',
          color: theme.palette.common.greyDark,
        }}
      />
    ) : (
      <ArrowBackIosNewRounded
        sx={{
          fontSize: '14px',
          color: theme.palette.common.greyDark,
        }}
      />
    );

  useEffect(() => {
    if ([TRAFFIC_SIM_PAGE_NAME.HISTORY].includes(currentPage)) {
      setDrawerWidth('97.3vw');
      setIsExpanded(true);
    } else {
      setDrawerWidth('300px');
      setIsExpanded(false);
    }
  }, [currentPage, setIsExpanded]);

  const toggleDrawerWidth = () => {
    if ([TRAFFIC_SIM_PAGE_NAME.LIMITATIONS, TRAFFIC_SIM_PAGE_NAME.UPCOMING_LIMITATION,].includes(currentPage)) {
      setIsExpanded(!isExpanded);
      setDrawerWidth(isExpanded ? '300px' : '97.3vw');
    }
  };

  const shouldShowToggleButton = [
      TRAFFIC_SIM_PAGE_NAME.LIMITATIONS, TRAFFIC_SIM_PAGE_NAME.UPCOMING_LIMITATION
  ].includes(currentPage);

  const getTopOffset = () => {
    const breakpoints = theme.breakpoints;
    if (window.innerWidth < breakpoints.values.sm) {
      return 105; // Mobil
    } else if (window.innerWidth < breakpoints.values.md) {
      return 120; // Ipad
    } else {
      return 124; // Desktop
    }
  };

  const topOffset = getTopOffset();

  const drawerStyles = {
    anchor: 'right',
    PaperProps: {
      style: {
        width: drawerWidth,
        overflowX: 'auto',
        top: `${topOffset}px`,
        opacity: 1,
        boxShadow: 'none',
        padding: 0,
        border: 'none',
        zIndex: 2,
        backgroundColor: theme.palette.background.surfaceTertiary,
        height: `calc(100vh - ${topOffset}px)`,
      },
    },
    ModalProps: {
      keepMounted: true,
      BackdropProps: {
        invisible: true,
        style: {pointerEvents: 'none'},
      },
    },
  };

  return (
    <Drawer {...drawerStyles} open={open} variant="persistent">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: 'auto',
          padding: '0 10px',
          margin: 0,
          backgroundColor: theme.palette.background.surfaceTertiary,
          position: 'relative',
        }}
      >
        {shouldShowToggleButton && (
          <CustomTooltip
            title={isExpanded ? 'Klicka för att minska' : 'Klicka för att expandera'}
            placement="bottom"
          >
            <IconButton
              onClick={toggleDrawerWidth}
              sx={{
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                marginRight: '8px',
                padding: 0,
                backgroundColor: theme.palette.action.active,
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
                '&:focus': {
                  backgroundColor: theme.palette.action.focus,
                },
              }}
            >
              {getIcon()}
            </IconButton>
          </CustomTooltip>
        )}
        <Typography variant="subtitle1">{title}</Typography>
        <Box sx={{flexGrow: 1}} /> <CloseStyledButton onClose={onClose} />
      </Box>
      <Container
        style={{
          width: '100vw',
          maxWidth: '100%',
          padding: '10px',
          margin: 0,
          overflowY: 'auto',
          height: 'auto',
        }}
      >
        {children}
      </Container>
    </Drawer>
  );
}

export default DrawerStyled;
