import React, {useContext, useEffect, useState} from 'react';
import {useTheme} from '@mui/styles';
import {Box, Card, Typography} from '@mui/material';
import CardMatarpunkter from 'pages/trafficsim/trafficSimComponents/themedComponents/CardMatarpunkter.tsx';
import {ACCESS_LEVELS} from 'pages/trafficsim/utils/access_levels.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {UserContext, UserContextType} from 'pages/auth/UserContext.ts';
import {UserStateLoaded} from 'types/userState/userState';

const Feedpoints = ({
  mapRef,
  feedPoints,
  stations,
  sections,
  onStationSaveState,
  onFeedPointSaveState,
}) => {
  const theme = useTheme();
  const [
    {isCardOpen, selectedStationId, selectedFeedpointId, activeStation},
    setCardState,
  ] = useState({
    isCardOpen: false,
    selectedStationId: null,
    selectedFeedpointId: null,
    activeStation: [],
  });

  const userState = useContext<Perhaps<UserContextType>>(UserContext)!
    .userState as UserStateLoaded;

  const handleStationClick = (activeStation) => {
    const stationId = activeStation.internal_id;
    const mapZoomLevel = mapRef.current.getZoom();
    const zoomLevel = mapZoomLevel > 16 ? mapZoomLevel : 16;
    mapRef.current.flyTo({
      center: activeStation.props.coordinates,
      zoom: zoomLevel,
      essential: true,
    });

    if (!isNotReadOnlyAccess()) return;

    if (activeStation.feedpoints) {
      const isAnySectionClosed = activeStation.feedpoints.some((fpId) => {
        const feedPoint = feedPoints.filter((f) => f.internal_id === fpId)[0];
        const section =
          feedPoint !== undefined
            ? sections.filter((sec) => sec.internal_id === feedPoint.sections)[0]
            : undefined;
        return section !== undefined
          ? section.isInSafeTrackMode || !section.isOpen
          : false;
      });

      if (!isAnySectionClosed) {
        setCardState({
          selectedFeedpointId: null,
          selectedStationId: stationId,
          isCardOpen: true,
          activeStation: activeStation,
        });
        return;
      }

      setCardState({
        selectedFeedpointId: null,
        selectedStationId: null,
        isCardOpen: false,
        activeStation: null,
      });
    }
  };

  const getFeedPoints = (feedPointsInStation) => {
    if (feedPointsInStation === undefined || feedPointsInStation === null) return [];
    if (
      !Array.isArray(feedPointsInStation) ||
      Array.from(feedPointsInStation).length === 0
    )
      return [];
    let arr = Array.from([]);
    feedPointsInStation.forEach((feedPointId) => {
      const fp = feedPoints.filter((f) => f.internal_id === feedPointId)[0];
      if (fp !== undefined) arr.push(fp);
    });
    return arr;
  };

  const handleFeedpointClick = (fpId, coordinates, isSectionClosed) => {
    const mapZoomLevel = mapRef.current.getZoom();
    const zoomLevel = mapZoomLevel > 16 ? mapZoomLevel : 16;
    mapRef.current.flyTo({
      center: coordinates,
      zoom: zoomLevel,
      essential: true,
    });

    if (!isNotReadOnlyAccess()) return;

    if (!isSectionClosed) {
      setCardState({
        selectedFeedpointId: fpId,
        selectedStationId: null,
        isCardOpen: true,
        activeStation: null,
      });
      return;
    }

    setCardState({
      selectedFeedpointId: null,
      selectedStationId: null,
      isCardOpen: false,
      activeStation: null,
    });
  };

  const onCloseCard = () => {
    setCardState({
      selectedFeedpointId: selectedFeedpointId,
      selectedStationId: selectedStationId,
      isCardOpen: false,
    });
  };

  const isNotReadOnlyAccess = () => {
    return (
      userState.access.trafficSimAccessLevel &&
      userState.access.trafficSimAccessLevel !== ACCESS_LEVELS.READ_ONLY
    );
  };

  let activeStationFp;
  if (activeStation) {
    activeStationFp = getFeedPoints(activeStation.feedpoints);
  }

  let feedPointData = null;
  if (selectedFeedpointId) {
    feedPointData = feedPoints.filter((f) => f.internal_id === selectedFeedpointId)[0];
  }

  useEffect(() => {}, [selectedFeedpointId, selectedStationId]);

  return (
    <Box
      sx={{
        width: '280px',
        position: 'relative',
        overflowY: 'auto',
        minHeight: '100vh',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(60px, 1fr))',
        gridGap: '3px',
        pt: isCardOpen ? '360px' : '0px',
        backgroundColor: 'none',
      }}
    >
      {isCardOpen && selectedStationId && (
        <CardMatarpunkter
          type={'station'}
          feedPointOrStationID={selectedStationId}
          feedPointData={null}
          activeStation={activeStation}
          activeStationFeedPoints={activeStationFp}
          onStationSaveState={onStationSaveState}
          onFeedPointSaveState={onFeedPointSaveState}
          onCloseCard={onCloseCard}
          stations={stations}
          feedPoints={feedPoints}
          sections={sections}
        />
      )}

      {isCardOpen && selectedFeedpointId && (
        <CardMatarpunkter
          type={'feedpoint'}
          feedPointOrStationID={selectedFeedpointId}
          feedPointData={feedPointData}
          activeStation={null}
          activeStationFeedPoints={null}
          onStationSaveState={onStationSaveState}
          onFeedPointSaveState={onFeedPointSaveState}
          onCloseCard={onCloseCard}
          stations={stations}
          feedPoints={feedPoints}
          sections={sections}
        />
      )}
      <Box
        sx={{
          width: '280px',
          mt: isCardOpen ? '10px' : '0px',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(60px, 1fr))',
          gridGap: '3px',
          padding: '0px',
          backgroundColor: 'none',
          alignItems: 'center',
        }}
      >
        {stations.map((station) => {
          let stationColor = station.isStationOpen === true ? '#379C54' : '#E14E56';
          const stationId = station.internal_id;
          return (
            <Card
              key={stationId}
              onClick={() => {
                handleStationClick(station);
              }}
              sx={{
                height: '110px',
                paddingTop: '5px',
                textAlign: 'center',
                margin: '0px',
                cursor: 'pointer',
                padding: '3px',
                backgroundColor: theme.palette.background.surfaceSecondary,
                border:
                  stationId === selectedStationId
                    ? `3px solid ${theme.palette.primary.main}`
                    : 'none',
              }}
            >
              <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  color: station.color,
                  fontWeight: 'bold',
                }}
              >
                {stationId}
              </Typography>
              {station.feedpoints && station.feedpoints.length > 0 && (
                <div>
                  {station.feedpoints.map((fpId) => {
                    let feedPointColor;
                    const feedPoint = feedPoints.filter((f) => f.internal_id === fpId)[0];

                    if (feedPoint && feedPoint.isSpare && feedPoint.isActive === false) return;
                    console.log(feedPoint)
                    const _section = sections.filter(
                      (sec) => sec.internal_id === feedPoint.sections,
                    )[0];

                    if (_section !== undefined && feedPoint !== undefined) {
                      const isSectionInSafeTrackOrClosed =
                        _section.isInSafeTrackMode || !_section.isOpen;
                      feedPointColor =
                        station.isOpen === false || isSectionInSafeTrackOrClosed
                          ? '#E14E56'
                          : feedPoint.isOpen
                            ? '#379C54'
                            : '#E5C349';
                    }

                    const isSectionClosed =
                      _section !== undefined
                        ? _section.isInSafeTrackMode || !_section.isOpen
                        : false;

                    if (!feedPoint) {
                      return (
                        <Typography key={fpId} sx={{marginTop: 1}}>
                          Feedpoint {fpId} data is missing
                        </Typography>
                      );
                    }

                    return (
                      <Card
                        key={fpId}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleFeedpointClick(
                            fpId,
                            feedPoint.props.coordinates,
                            isSectionClosed,
                          );
                        }}
                        sx={{
                          color: 'white',
                          display: 'block',
                          padding: '0px',
                          margin: '2px',
                          cursor: 'pointer',
                          boxShadow: 'none',
                          backgroundColor: feedPointColor,
                          border:
                            fpId === selectedFeedpointId
                              ? `3px solid ${theme.palette.primary.main}`
                              : 'none',
                          '&:hover': {
                            boxShadow: 'none',
                          },
                        }}
                      >
                        <Typography variant="subtitle2">{fpId}</Typography>
                      </Card>
                    );
                  })}
                </div>
              )}
            </Card>
          );
        })}
      </Box>
    </Box>
  );
};

export default Feedpoints;
