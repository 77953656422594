import {Box, Stack} from '@mui/material';
import {ArrowBackRounded, ArrowForwardRounded} from '@mui/icons-material';
import React from 'react';

import {ViewSlider} from '../../../types/propTypes/viewSlider.ts';

import {CemitComponentProps} from '../../../types/propTypes/cemitComponenProps';
import {
  TrainPageSliderButtonSideProps,
  TrainPageSliderSideButton
} from './TrainPageSliderSideButton.tsx';
import {
  TrainAppMapComponentDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapComponentDependencyProps.ts';

export interface TrainPageSliderButtonProps extends CemitComponentProps {
  left: TrainPageSliderButtonSideProps;
  right: TrainPageSliderButtonSideProps;
}

const TrainPagesExpandCollapseButtons = (
  {
    appProps,
    mapProps,
    componentProps: {left, right}
  }: TrainAppMapComponentDependencyProps<TrainPageSliderButtonProps>) => {
  return (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        minHeight: 0,
        overflow: 'hidden',
        minWidth: 0,
        marginRight: '5px',
        position: 'relative',
        paddingY: '10px',
        borderRadius: '100%',
        alignItems: 'center'
      }}
    >
      {/* Left "Collapse" button */}
      {appProps.viewSlider != ViewSlider.collapsed &&
        TrainPageSliderSideButton({
          appProps,
          mapProps,
          componentProps: {
            ...left,
            onClick: () => {
              appProps.setViewSlider(
                appProps.viewSlider != ViewSlider.normal
                  ? ViewSlider.normal
                  : ViewSlider.collapsed
              );
              // Set a timeout because the Slide child is not actually unmounted quite yet :/
              // If this is problematic then put a ref on the child and check when its unmounted
              mapProps.trainMap &&
              setTimeout(() => {
                mapProps.trainMap.resize();
              }, 500);
            },
            componentKey: 'left',
            defaultIcon: ArrowBackRounded,
            'aria-label': 'left expand/collapse'
          } as TrainPageSliderButtonSideProps
        })}
      {/* Right "Expand */}
      {appProps.viewSlider != ViewSlider.expanded &&
        TrainPageSliderSideButton({
          appProps,
          mapProps,
          componentProps: {
            ...right,
            onClick: () => {
              appProps.setViewSlider(
                appProps.viewSlider != ViewSlider.normal
                  ? ViewSlider.normal
                  : ViewSlider.expanded
              );
            },
            componentKey: 'right',
            defaultIcon: ArrowForwardRounded,
            'aria-label': 'right expand/collapse'
          } as TrainPageSliderButtonSideProps
        })}
    </Stack>
  );
};

export default TrainPagesExpandCollapseButtons;
