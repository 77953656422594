import React, {ReactElement, ReactNode, useContext} from 'react';
import CemitThemeContext from 'theme/CemitThemeContext.ts';
import {ThemeProvider} from '@mui/material';
import {
  CemitAppOrganizationDependencyProps
} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppOrganizationDependencyProps.ts';
import {CemitAppDependencyProps} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppDependencyProps.ts';
import OrganizationDependency from 'async/trainAppAsync/OrganizationDependency.tsx';
import CemitAppDependency from 'async/cemitAppAsync/CemitAppDependency.tsx';
import {ThemeEnum, themeLookup} from 'theme/lightDarkTheme.ts';

/**
 * Wraps an application component in a CemitAppDependency and OrganizationDependency
 * to supply minimum application props and train-api organization props
 * @param AppComponent
 */
// export const RequireAccessWithAppAndOrgProps = ({app}: {app: ApplicationType}) => {
//   const renderChildren = ({
//     appProps,
//     organizationProps,
//   }: OrganizationCemitAppDependencyProps) => {
//     return (
//       <RequireAccess
//         {...{
//           app,
//           context: {
//             appProps,
//             organizationProps,
//           },
//         }}
//       ></RequireAccess>
//     );
//   };
//
//   const renderOrganizationDependency = ({appProps}: CemitAppDependencyProps) => {
//     return <OrganizationDependency {...{appProps, renderChildren}} />;
//   };
//
//   return <CemitAppPropsDependency {...{renderChildren: renderOrganizationDependency}} />;
// };
// export const WrapRouteOutletInAppAndOrgProps = ({
//   renderComponent,
// }: {
//   renderComponent: FC<OrganizationCemitAppDependencyProps>;
// }) => {
//   const {appProps, organizationProps} =
//     useOutletContext<OrganizationCemitAppDependencyProps>();
//   return createElement(renderComponent, {appProps, organizationProps});
// };

interface WrapTitledApplicationInAppAndOrgProps {
  defaultTheme: ThemeEnum
}
/**
 * Wraps an application with the given title key component in a CemitAppDependency and OrganizationDependency
 * to supply minimum application props and train-api organization props
 * @param titleKey
 * @param AppComponent
 * @param props
 */
export const wrapTitledApplicationInAppAndOrgProps = (
  titleKey: string,
  AppComponent: React.FC<CemitAppOrganizationDependencyProps>,
  props?: WrapTitledApplicationInAppAndOrgProps
) => {
  const theme = themeLookup[props?.defaultTheme || ThemeEnum.dark]
  const renderChildren = ({
    appProps,
    organizationProps,
  }: CemitAppOrganizationDependencyProps) => {
    return <AppComponent {...{appProps, organizationProps}} />;
  };

  const renderOrganizationDependency = ({appProps}: CemitAppDependencyProps) => {
    return <OrganizationDependency {...{appProps, renderChildren}} />;
  };
  return (
    <ThemeProvider theme={theme}>
      <CemitAppDependency {...{titleKey, renderChildren: renderOrganizationDependency}} />
    </ThemeProvider>
  );
};

/**
 * Wraps ThemeProvider to give it the CemitThemeContext
 * @param children
 * @constructor
 */
export const WrappedThemeProvider = ({children}: {children: ReactNode}): ReactElement => {
  const {theme} = useContext(CemitThemeContext);
  return <ThemeProvider {...{theme}}>{children}</ThemeProvider>;
};
