import {Box, Button, CardActions, Paper, Typography} from "@mui/material";
import {throwUnlessDefined} from "../../../utils/functional/functionalUtils.ts";
import React, { FC } from "react";
import { useToken } from "utils/hooks/useTokenHook.ts";

const mainAppToLegacyPathMapping: {[key: string]: string}  = {
  "index": throwUnlessDefined(process.env.REACT_LEGACY_PATH_RAILS),
  "catenary": throwUnlessDefined(process.env.REACT_LEGACY_PATH_CATENARY),
  "depot": throwUnlessDefined(process.env.REACT_LEGACY_PATH_DEPOT),
  "routePlanner": throwUnlessDefined(process.env.REACT_LEGACY_PATH_GRPS),
  "switch": throwUnlessDefined(process.env.REACT_LEGACY_PATH_SWITCH),
  "station": throwUnlessDefined(process.env.REACT_LEGACY_PATH_STATION),
  "events": throwUnlessDefined(process.env.REACT_LEGACY_PATH_EVENTS),
  //"budget": throwUnlessDefined(process.env.REACT_LEGACY_PATH_BUDGET)
}
  /**
 * Component that redirects to the Legacy app, just as CrossAppLink redirects from the Legacy app to this app
 */
const LegacyAppLink: FC<{app: string}> = ({app}) => {
  const path = mainAppToLegacyPathMapping?.[app];
  if (!path) {
    console.log(`Unknown remote product ${app}`);
    return <></>;
  }
  const [token] = useToken();
  const legacyBaseUrl: string = throwUnlessDefined(process.env.REACT_APP_LEGACY_URL);
  const params = new URLSearchParams();
  if (token) {
    params.append("token", token);
  }
  const url: string = legacyBaseUrl + path + '?' + params.toString();

  // If debug mode is one then only show the link with a button to manually redirect
  if (process.env.REACT_LEGACY_DEBUG_LINK === 'true') {
    return <Box
        sx={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'center',
          background: '#22292e',
          color: 'white',
          h1: {
            fontSize: '200%',
          },
          h2: {
            marginBottom: '1em',
          },
        }}
      >
      <Paper>
        <Box width={400} display="flex" flexDirection="column" alignItems="center" sx={{ minWidth: 275 }}>
          <Typography padding={'1em'} sx={{wordWrap: 'anywhere'}}>
            Legacy Link: {url}
          </Typography>
          <CardActions>
            <Button href={url}>Go to Legacy Site</Button>
          </CardActions>
        </Box>
      </Paper>
    </Box>
  }
  window.location.href = url;
  return <></>;
}

export default LegacyAppLink
