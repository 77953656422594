import React, {createContext, ReactElement, useCallback, useState} from 'react';
import {
  ChartPayloadItem,
  ChartPayloadItemDerived,
} from 'types/dataVisualizations/chartPayloadItem';
import {CemitTypename} from 'types/cemitTypename.ts';
import {
  useWhatChangedLoadingExplanation,
  useWhatIsLoading,
} from '../../trainAppHooks/loadingExplanationHooks.ts';
import {isLoadingStringOfDependencyUnit} from '../trainDependencyUnitConfig.ts';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {useNotLoadingEffect, useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {ChartMapInteractionProps} from 'types/propTypes/trainPropTypes/chartMapInteractionProps';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {Feature} from 'geojson';
import {includes, map, prop} from 'ramda';
import {
  removeCursorFromChart,
  removeCursorFromTrainMap,
  updateHoverFeatureFromPayloadItems,
} from 'utils/chart/chartMapInteractionUtils.ts';
import {FrontendView} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {getOrganizationViews} from 'utils/organization/organizationUtils.ts';
import {Organization} from 'types/organizations/organization.ts';
import {useDebouncedCallback} from 'use-debounce';
import {useNotLoadingEffectUpdateTrainGroupHoverOnMap} from 'async/cemitAppAsync/cemitAppHooks/mapHooks/trainMapChartInteractionMapLayerHooks.ts';
import {includesAll} from 'utils/functional/functionalUtils.ts';
import {correctPayload} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainGroupUtil.ts';

const defaultInteractionProps = {
  removeCursorFromChart: () => {},
  updateHoverFeatureFromPayloadItems: () => {},
  removeCursorFromTrainMap: () => {},
};

export const ChartMapInteractionContext =
  createContext<Perhaps<ChartMapInteractionProps>>(undefined);

/**
 * Provides a context ChartMapInteractionContext so that currently hovered item on a chart or map
 * can be shared between the chart, map, and ChartAndMapDataContainer without forcing all the other
 * trafficSimComponents to rerender. Since hovering generates a stream of events, setting mostRecentTooltipPayload
 * is debounced
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param children
 * @returns {*}
 * @constructor
 */
const ChartMapInteractionDependency = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
  renderChildren,
  loading,
}: TrainAppMapDependencyProps): ReactElement => {
  // Used by dataVisualizations and maps to store what was hovered over last so we can display it
  const [mostRecentTooltipPayload, _setMostRecentTooltipPayload] =
    useState<Perhaps<ChartPayloadItemDerived[]>>(undefined);
  const setMostRecentTooltipPayload = useDebouncedCallback(
    (mostRecentTooltipPayload: Perhaps<ChartPayloadItem[]>) => {
      _setMostRecentTooltipPayload(mostRecentTooltipPayload),
        {
          leading: true,
          trailing: false,
        };
    },
    100,
  );
  // The feature currently hovered over on the TrainMap so Charts can position there
  const [hoverFeature, _setHoverFeature] = useState<Perhaps<Feature>>(undefined);
  const setHoverFeature = useCallback((value) => {
    _setHoverFeature(value);
  }, []);

  const isTrainMapUsed = useNotLoadingMemo(
    loading!,
    (organization: Organization) => {
      return includes(FrontendView.map, getOrganizationViews(organization));
    },
    [organizationProps.organization] as const,
  );

  const interactionProps: Perhaps<Partial<ChartMapInteractionProps>> = useNotLoadingMemo(
    loading || !isTrainMapUsed,
    (trainMap) => {
      // Initialize functions for cross component trainMap interaction, like hovering the mouse on the
      // chart and show the corresponding point on the map, and visa-versa
      return {
        updateHoverFeatureFromPayloadItems,
        removeCursorFromChart: removeCursorFromChart(trainMap),
        removeCursorFromTrainMap,
      };
    },
    [mapProps.trainMap] as const,
    defaultInteractionProps,
  );

  // Normally the mostRecentTooltipPayload is set by hovering, but if the TrainGroups change,
  // we need to update the mostRecentTooltipPayload here.
  // If mostRecentTooltipPayload items doesn't match activeTrainGroupsWithoutErrors, they'll be omitted
  useNotLoadingEffect(
    loading!,
    (activeTrainGroupsWithoutErrors) => {
      const trainGroupIds: string[] = map(
        (payloadItem: ChartPayloadItem) => payloadItem.trainGroup.id,
        mostRecentTooltipPayload || [],
      );
      const activeTrainGroupIds: string[] = map(
        prop('id'),
        activeTrainGroupsWithoutErrors || [],
      );
      if (!includesAll(trainGroupIds, activeTrainGroupIds)) {
        // If activeTrainGroupsWithoutErrors changed, clear MostRecentTooltipPayload
        setMostRecentTooltipPayload([]);
      } else {
        const correctedPayloadItems = correctPayload(
          appProps,
          activeTrainGroupsWithoutErrors,
          mostRecentTooltipPayload,
        );
        setMostRecentTooltipPayload(correctedPayloadItems);
      }
    },
    [trainProps.trainGroupActivityProps?.activeTrainGroupsWithoutErrors] as const,
  );

  const whatIsLoading = useWhatIsLoading(
    loading!,
    isLoadingStringOfDependencyUnit(ChartMapInteractionDependency.name),
    ChartMapInteractionDependency.name,
    // Nothing is ever in a loading state
    {},
    [],
    appProps.setWhatDependenciesAreLoading,
  );

  const chartMapInteractionProps = useMemoClsOrType<
    Omit<ChartMapInteractionProps, 'loading'>
  >(CemitTypename.chartMapInteractionProps, {
    whatIsLoading,
    mostRecentTooltipPayload,
    setMostRecentTooltipPayload,
    hoverFeature,
    setHoverFeature,
    ...interactionProps,
  });

  useNotLoadingEffectUpdateTrainGroupHoverOnMap(
    Boolean(loading),
    mapProps.trainMap,
    chartMapInteractionProps?.mostRecentTooltipPayload,
    mapProps.setChangeStatuses,
    mapProps.setMapboxImages
  );
  // Whenever activeTrainGroups change, update the cursor layer on the map to show on the map
  // where the user is hovering on a chart
  // useNotLoadingEffect(
  //   loading ||
  //     !isTrainMapUsed ||
  //     !interactionProps ||
  //     !trainProps.trainGroupSingleTrainRunProps.trainGroup,
  //   (moveCursorAlongChartLine, mostRecentTooltipPayload, _activeTrainGroups) => {
  //     if (mostRecentTooltipPayload)
  //       // Use whatever the current state of trainPropsMerged is, but don't list as a dependency
  //       // since it contains hoverFeature, which is updated here
  //       moveCursorAlongChartLine({trainProps, mapProps}, mostRecentTooltipPayload);
  //   },
  //   [
  //     moveCursorAlongChartLine,
  //     mostRecentTooltipPayload,
  //     trainProps.trainGroupActivityProps?.activeTrainGroups,
  //   ] as const,
  // );

  // const trainPropsMerged = useMemoMergeTrainProps(
  //   trainProps,
  //   trainProps.__typename,
  //   'trainGroupSingleTrainRunProps.chartMapInteractionProps',
  //   chartMapInteractionProps,
  //   // No new CemitFilter for this dependency
  //   undefined,
  // );

  // useNotLoadingEffect(
  //   loading || !isTrainMapUsed || !interactionProps,
  //   (mostRecentTooltipPayload) => {
  //     // Use whatever the current state of trainPropsMerged and mapProps is, but don't list as a dependency
  //     // since it contains hoverFeature, which is updated here
  //     moveCursorAlongChartLine(
  //       {trainProps: trainPropsMerged, mapProps: mapProps},
  //       mostRecentTooltipPayload,
  //     );
  //   },
  //   [mostRecentTooltipPayload] as const,
  // );

  useWhatChangedLoadingExplanation(
    whatIsLoading,
    chartMapInteractionProps,
    'ChartMapInteractionDependency',
  );

  const children = renderChildren!({
    appProps,
    organizationProps,
    trainProps,
    mapProps,
  });

  return (
    <ChartMapInteractionContext.Provider {...{value: chartMapInteractionProps}}>
      {children}
    </ChartMapInteractionContext.Provider>
  );
};

ChartMapInteractionDependency.displayName = 'ChartMapInteractionDependency';
export default ChartMapInteractionDependency;
//export default memo(
// ChartMapInteractionDependency,
/* (prevProps, currentProps) => {
    const appPropsEqual = prevProps.appProps == currentProps.appProps;
    const orgPropsEqual = prevProps.organizationProps == currentProps.organizationProps;
    const trainPropsEqual = prevProps.trainProps == currentProps.trainProps;
    const mapPropsEqual = prevProps.mapProps == currentProps.mapProps;
    return appPropsEqual && orgPropsEqual && trainPropsEqual && mapPropsEqual;
  }, */
//);
