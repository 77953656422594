import {Box, Stack} from '@mui/material';
import React from 'react';
import {Perhaps} from 'types/typeHelpers/perhaps';
import TrainPageExpandCollapseButtons from 'pages/trainApp/trainPageSubContainers/TrainPagesExpandCollapseButtons.tsx';
import {FrontendView} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {getOrganizationViews} from 'utils/organization/organizationUtils.ts';
import {equals, includes, map} from 'ramda';
import {HideSource, Public, VerticalSplit} from '@mui/icons-material';
import {ViewSlider} from 'types/propTypes/viewSlider.ts';
import {CEMIT_DARK_RED, CEMIT_WHITE} from 'theme/cemitColors.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {CemitAppProfileItem} from 'components/apps/cemitAppComponents/CemitAppProfileItem.tsx';
import LoaderWithText from '../../../components/loading/LoaderWithText.tsx';
import {
  AlertChoosersComponent
} from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/AlertChoosersComponent.tsx';
import TrainGroupOverviewHeader
  from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/TrainGroupOverviewHeader.tsx';
import {needToShowTrainGroupOverview} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/activityUtils.ts';
import ToggleSwitchStyled from 'pages/trafficsim/trafficSimComponents/themedComponents/ToggleSwitchStyled.tsx';
import TrainMapThemeToggle from 'pages/trainApp/mapbox/TrainThemeToggle.tsx';

const TrainFilterHeaderComponent = (
  {
    appProps,
    organizationProps,
    trainProps,
    mapProps
  }: TrainAppMapDependencyProps) => {
  // The slider isn't need if the map isn't included
  // If the organization has a map or detail page, include the expander/contractor arrow
  const views: FrontendView[] = getOrganizationViews(organizationProps.organization)!;
  if (!views) {
    return undefined;
  }

  // Show the TrainGroup overview if there are no active TrainGroups or
  // the FrontendView.forceTrainGroupOverview has been explicitly requested by the user
  const showTrainGroupOverview = needToShowTrainGroupOverview({appProps});
  // Only show the map if showTrainGroupOverview is false and the FrontendView.map is in views
  const needsTrainMap: boolean = !showTrainGroupOverview && includes(FrontendView.map, views);
  const showMapThemeToggle: boolean = !mapProps.trainMapLoading && needsTrainMap && organizationProps?.organization?.isDarkLightEnabled;

  const sliderIsNormal = equals(ViewSlider.normal, appProps.viewSlider);
  const leftMessage = appProps.t(sliderIsNormal ? 'expandMap' : 'showMap');
  const rightMessage = appProps.t(sliderIsNormal ? 'hideMap' : 'shrinkMap');
  const trainPageSliderButton: Perhaps<JSX.Element> = needsTrainMap ? (
    <TrainPageExpandCollapseButtons
      {...{
        appProps,
        mapProps,
        componentProps: {
          left: {
            iconComponent: (
              <Public
                {...{
                  fontSize: sliderIsNormal ? 'large' : 'medium',
                  sx: {color: CEMIT_WHITE}
                }}
              />
            ),
            tooltipMessage: leftMessage
          },
          right: {
            iconComponent: (
              <Box {...{position: 'relative', display: 'contents'}}>
                {sliderIsNormal ? (
                  [
                    <Public
                      key="mapIcon"
                      {...{
                        fontSize: 'small',
                        sx: {
                          position: 'absolute',
                          left: '50%',
                          top: '50%',
                          transform: 'translate(-50%,-50%)',
                          zIndex: 1
                        }
                      }}
                    />,
                    <HideSource
                      key="hideIcon"
                      {...{
                        fontSize: 'large',
                        sx: {color: CEMIT_DARK_RED, zIndex: 2}
                      }}
                    />
                  ]
                ) : (
                  <VerticalSplit {...{fontSize: 'medium', sx: {color: CEMIT_WHITE}}} />
                )}
              </Box>
            ),
            tooltipMessage: rightMessage
          }
        }
      }}
    ></TrainPageExpandCollapseButtons>
  ) : undefined;

  return (
    <Stack
      key="sliderAndFilterAndProfile"
      {...{
        direction: 'row',
        spacing: 2,
        sx: {
          paddingRight: '10px',
          minHeight: 0,
          flex: 1,
          overflow: 'hidden',
          maxHeight: '70px',
          // Force the TrainBoard to be full width
          minWidth: '100%',
          width: '100%',
          height: '100%',
          zIndex: 2,
          justifyContent: 'space-between'
        }
      }}
    >
      {
        (trainProps.trainFormationDateProps.loading ?
          // Make the Component take up space with an empty LoaderWithText
          <LoaderWithText {...{text: ''}} /> : (
            // If no detail view is active, we show TrainGroupOverviewHeader instead of filter controls,
            // since the TrainGroupOverview will display until a TrainGroup is selected
            showTrainGroupOverview ?
              <Box {...{sx: {minWidth: 0, width: '100%'}}}>
                <TrainGroupOverviewHeader {...{appProps, organizationProps, trainProps, mapProps}} />
              </Box> :
              (
                <AlertChoosersComponent
                  key="rideComfortChoosersComponent"
                  {...{
                    appProps,
                    organizationProps,
                    trainProps
                  }}
                />
              )))
      }
      <Stack
        key="SliderAndProfile"
        {...{
          direction: 'row',
          spacing: 1,
          sx: {
            minWidth: 0,
            minHeight: 0,
            overflow: 'hidden',
            flexShrink: 0,
            alignItems: 'center'
          }
        }}
      >

        {showMapThemeToggle &&
          <Stack {...{direction: 'row', spacing: 1, sx: {maxHeight: '20px'}}} >
            {
              // If not on the overview and the organization allows changing theme, show the theme toggle
              // This only applies to the Map for now
              showMapThemeToggle && <TrainMapThemeToggle />
            }
          </Stack>
        }
        {trainPageSliderButton}
        {organizationProps?.organization?.logos &&
          <Stack {...{direction: 'row', spacing: 1, sx: {maxHeight: '50px'}}} >
            {map(
              (logo: string) => {
                return <Box {...{component: 'img', src: logo}} />;
              },
              organizationProps?.organization?.logos
            )}
          </Stack>
        }
        <CemitAppProfileItem
          {...{
            appProps,
            organizationProps,
            trainProps
          }}
        />
      </Stack>
    </Stack>
  );
};
export default TrainFilterHeaderComponent;
