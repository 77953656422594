import {Theme} from '@mui/system';
import {AppSettings} from 'config/appConfigs/appSettings.ts';

const {mapboxStyleDark, mapboxStyleLight, mapboxStyle} = AppSettings;

/**
 * Returns the style url of the theme's palette.mode.
 * If isDarkLightEnabled is false, always return the legacy style
 * @param isDarkLightEnabled
 * @param theme
 */
export const mapboxStyleUrlOfTheme = (isDarkLightEnabled: boolean, themeMode: string): string | never => {
  if (!isDarkLightEnabled) {
    return mapboxStyle;
  }
  if (themeMode === 'dark') {
    return mapboxStyleDark;
  } else if (themeMode == 'light') {
    return mapboxStyleLight;
  } else {
    throw new Error('Unexpected them');
  }
};
