import React from 'react';
import {SvgIcon} from '@mui/material';

/**
 * Creates station icon that can be colored as needed
 * @param stroke
 * @constructor
 */
export const StationSvgIcon = (
  {
    stroke
  }: {stroke: string}) => {
  return (
    <SvgIcon>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
           viewBox="0 0 83.334 100" enableBackground="new 0 0 83.334 100">
    <g fill="none"
       stroke={stroke} strokeWidth="4">
        <path d="M37.875,39.051h3.792h3.792c0.721,0.002,1.286-0.588,1.286-1.287v-2.19c0-0.701-0.599-1.276-1.286-1.287h-3.792h-3.792
		c-0.688,0.011-1.283,0.586-1.283,1.287v2.19C36.593,38.462,37.156,39.053,37.875,39.051z"></path>
      <path d="M54.952,49.482V44.47c-0.012-2.09-1.364-3.913-3.918-3.917h-9.367H32.3c-2.552,0.004-3.903,1.827-3.917,3.917v5.012
		c-0.005,2.428,1.757,3.905,3.917,3.917h9.367h9.367C53.197,53.388,54.959,51.91,54.952,49.482z"></path>
      <path d="M31.861,64.679c-1.885-0.01-3.421,1.524-3.416,3.415c-0.005,1.897,1.531,3.431,3.416,3.446
		c1.9-0.016,3.438-1.549,3.443-3.446C35.297,66.203,33.762,64.669,31.861,64.679z"></path>
      <path d="M41.667,16.667C18.655,16.667,0,35.322,0,58.333C0,81.345,18.655,100,41.667,100c23.013,0,41.667-18.655,41.667-41.667
		C83.334,35.322,64.68,16.667,41.667,16.667z M44.55,27.551c1.191-0.012,2.163,0.957,2.163,2.163c0,1.188-0.972,2.156-2.163,2.162
		c-1.2-0.005-2.169-0.974-2.161-2.162C42.381,28.508,43.35,27.538,44.55,27.551z M38.784,27.551
		c1.203-0.013,2.172,0.957,2.163,2.163c0.009,1.188-0.96,2.156-2.163,2.162c-1.188-0.005-2.159-0.974-2.161-2.162
		C36.625,28.508,37.596,27.538,38.784,27.551z M56.956,89.117l-6.453-9.464h-8.836h-8.836l-6.423,9.464h-5.199l8.992-13.505
		c-2.998-0.499-5.99-3.632-5.984-7.455V40.24c-0.006-3.937,3.312-7.555,7.83-7.55h9.651h9.617c4.492-0.005,7.81,3.613,7.802,7.55
		v27.917c0.008,3.823-2.984,6.956-5.984,7.455l8.992,13.505H56.956z"></path>
      <path d="M54.826,68.094c-0.01-1.891-1.543-3.425-3.448-3.414c-1.886-0.009-3.417,1.523-3.411,3.414
		c-0.006,1.897,1.525,3.431,3.411,3.446C53.283,71.524,54.816,69.991,54.826,68.094z"></path></g></svg>
    </SvgIcon>
  );
};

StationSvgIcon.displayProps = 'SubwaySvgIcon';
export default StationSvgIcon;
