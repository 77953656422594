import {Stack} from '@mui/material';
import React from 'react';
import {TrainAppTrainComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';
import {AlertRealtimeToggleButton} from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/AlertRealtimeToggleButton.tsx';
import {AlertTypeToggleButtonGroup} from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/AlertTypeToggleButtonGroup.tsx';
import {ZoomToAlerts} from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/ZoomToAlerts.tsx';
import {TrainAppMapComponentDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapComponentDependencyProps.ts';

export interface AlertOptionsMenuProps extends CemitComponentProps {}

/**
 * Alert display options.
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param mapProps
 * @param componentProps
 * @constructor
 */
const AlertOptionsMenu = ({
  appProps,
  organizationProps,
  trainProps,
  mapProps,
  componentProps,
}: TrainAppMapComponentDependencyProps<AlertOptionsMenuProps>) => {
  return (
    <Stack {...{spacing: 1, sx: {minWidth: '40px', maxWidth: '40px', alignItems: 'center'}}}>
      {organizationProps.organization.realtimeSpaceId ? (
        // Enable the realtime toggle if the organization has realtimeSpaceId, meaning it can subscribe to realtime data
        <AlertRealtimeToggleButton {...{appProps, componentProps}} />
      ) : undefined}
      <ZoomToAlerts {...{appProps, trainProps, mapProps, componentProps}} />
      <AlertTypeToggleButtonGroup {...{appProps, trainProps, componentProps}} />
    </Stack>
  );
};
export default AlertOptionsMenu;
