// Matches TrainGroupOverviewViewType to the overview or criticalAlerts resolver
export enum TrainGroupOverviewColumnAttributes {
  trendIcon = 'trendIcon',
  trainAndClasses = 'trainAndClasses',
  rideComfort = 'rideComfort',
  reviewDetails = 'reviewDetails',
  rateOfChange = 'rateOfChange',
  numberOfCriticalAlerts = 'numberOfCriticalAlerts',
  hoursInOperation = 'hoursInOperation',
  // distanceCovered = 'distanceCovered',
  // averageSpeed = 'averageSpeed'
}
