import {Box, Tooltip} from '@mui/material';
import {TooltipProps} from '@mui/material/Tooltip/Tooltip';
import {PropsWithChildren} from 'react';

/**
 * Puts a Box inside Tooltip so it always has a child that can take a ref
 * @param props
 * @constructor
 */
const TooltipBox = ({children, ...props}: PropsWithChildren<TooltipProps>) => {
  return <Tooltip {...props}><Box>{children}</Box></Tooltip>;
};

export default TooltipBox;
