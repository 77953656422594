export enum CemitTypename {
  organization = 'Organization',
  organizationMinimized = 'OrganizationMinimized',
  operator = 'Operator',

  trainFormationMinimized = 'TrainFormationMinimized',
  trainFormation = 'TrainFormation',
  trainFormationDetail = 'TrainFormationDetail',

  trainGroupPreloaded = 'TrainGroupPreloaded',
  trainGroupMinimized = 'TrainGroupMinimized',
  trainGroupIncomplete = 'TrainGroupIncomplete',

  // TrainGroup that can have zero or more TrainRuns
  trainGroup = 'TrainGroup',
  // TrainGroup that is only a TrainFormation without TrainRuns
  trainGroupOnlyTrainFormation = 'TrainGroupOnlyTrainFormation',
  // TrainGroup that is limited to a single TrainRun
  trainGroupSingleTrainRun = 'TrainGroupSingleTrainRun',

  // Indicates that derived geojson and similar has been calculated
  trainGroupDerived = 'TrainGroup',

  trainGroupWithFormatting = 'TrainGroupWithFormatting',
  sensorDataTrainGroup = 'SensorDataTrainGroup',
  equipmentAttributeSensorDataTrainGroup = 'EquipmentAttributeSensorDataTrainGroup',

  // For references
  trainRouteMinimized = 'TrainRouteMinimized',
  // Loaded TrainRoute
  trainRoute = 'TrainRoute',
  // Indicates that derived geojson and similar has been calculated
  trainRouteDerived = 'TrainRouteDerived',

  trainRouteGroupMinimized = 'TrainRouteGroupMinimized',
  trainRouteGroup = 'TrainRouteGroup',
  trainRouteGroupWithOrderedRoutePointsDerived = 'TrainRouteGroupWithOrderedRoutePointsDerived',
  trainRouteGroupDerived = 'TrainRouteGroupDerived',

  railwayLine = 'RailwayLine',
  railwayLineMinimized = 'RailwayLineMinimized',

  trainRouteOrGroupMinimized = 'TrainRouteOrGroupMinimized',
  trainRouteOrGroup = 'TrainRouteOrGroup',
  trainRouteOrGroupDerived = 'TrainRouteOrGroupDerived',
  trainRouteOrGroupTrackData = 'TrainRouteOrGroupTrackData',
  trainRouteOrGroupTrackDataDerived = 'TrainRouteOrGroupTrackDataDerived',

  routePoint = 'RoutePoint',
  routePointDerived = 'RoutePointDerived',
  orderedRoutePoint = 'OrderedRoutePoint',
  orderedRoutePointDerived = 'OrderedRoutePointsDerived',
  pointProjection = 'PointProjection',
  scheduledStopPoint = 'ScheduledStopPoint',
  scheduledStopPointDerived = 'ScheduledStopPointDerived',
  scheduledStopPointAndMaybeDateTime = 'ScheduledStopPointAndMaybeDateTime',
  scheduledStopPointAndMaybeDateTimeDerived = 'ScheduledStopPointAndMaybeDateTimeDerived',

  attributeAlert = 'AttributeAlert',
  trainRun = 'TrainRun',

  cemitFilter = 'CemitFilter',
  cemitFilterDateInterval = 'CemitFilterDateInterval',
  cemitFilterDateRecurrence = 'CemitFilterDateRecurrence',
  cemitFilterAttributeAlert = 'cemitFilterAttributeAlert',
  cemitFilterAttributeAlertChart = 'cemitFilterAttributeAlertChart',
  cemitFilterTrainFormation = 'cemitFilterTrainFormation',
  cemitFilterDateRecurrenceDate = 'CemitFilterDateRecurrenceDate',
  cemitFilterDateRecurrenceTime = 'CemitFilterDateRecurrenceTime',
  cemitFilterDateRecurrenceDay = 'CemitFilterDateRecurrenceDay',
  cemitFilterTrainRouteGroup = 'CemitFilterTrainRouteGroup',
  cemitFilterTrainAttributeAlert = 'CemitFilterTrainAttributeAlert',
  cemitFilterTrainAttributeAlertChart = 'CemitFilterTrainAttributeAlertChart',

  trainGroupWheelAlert = 'TrainGroupWheelAlert',
  wheelGroup = 'WheelGroup',
  wheelAttributeTimeSeries = 'WheelAttributeTimeSeries',
  wheelAttributeTimeSeriesItem = 'WheelAttributeTimeSeriesItem',
  wheelAttributeTimeSeriesItemBackreferenced = 'WheelAttributeTimeSeriesItemBackreferenced',
  wheelWithWheelAttributeTimeSeries = 'WheelWithWheelAttributeTimeSeries',
  trend = 'Trend',
  trendValue = 'TrendValue',
  trendWithBackreference = 'TrendWithBackreference',
  userStateMinimized = 'UserStateMinimized',
  userState = 'UserState',
  userStateInitial = 'UserStateInitial',
  userStateLoaded = 'UserStateLoaded',
  userStateFailed = 'UserStateFailed',
  trainDistanceInterval = 'TrainDistanceInterval',
  typeAttributeTrendCollection = 'TypeAttributeTrendCollection',
  typeAttributeTrendCollectionWithBackreferences = 'TypeAttributeTrendCollectionWithBackreferences',
  typeAttributeFlattenedTrendCollection = 'TypeAttributeFlattenedTrendCollection',

  vehicle = 'Vehicle',
  vehicleDetail = 'VehicleDetail',
  orderedVehicle = 'OrderedVehicle',
  bogie = 'Bogie',
  wheelSet = 'WheelSet',
  wheel = 'Wheel',
  wheelWithEquipmentAssets = 'WheelWithEquipmentAssets',

  equipmentAssetMinimized = 'EquipmentAssetMinimized',
  equipmentAsset = 'EquipmentAsset',
  historyAndTrendEquipmentAsset = 'HistoryAndTrendEquipmentAsset',
  wheelSetAsset = 'WheelSetAsset',
  singleSideEquipmentAsset = 'SingleSideEquipmentAsset',

  activity = 'Activity',

  chartCategoryDataConfig = 'ChartCategoryDataConfig',
  chartCategoryDataCommonConfig = 'ChartCategoryDataCommonConfig',
  chartDataConfig = 'ChartDataConfig',
  dataFeatureSet = 'DataFeatureSet',
  trackDataFeatureSet = 'TrackDataFeatureSet',
  nonSpatialDataFeatureSetMinimized = 'ChartDataFeatureSetMinimized',
  nonSpatialDataFeatureSet = 'ChartDataFeatureSet',
  dataFeatureCollection = 'DataFeatureCollection',
  dataFeatureCollectionWithBackReference = 'DataFeatureCollectionWithBackReference',
  dataFeature = 'DateFeature',
  dataFeatureSetAndTrainRUnGroupWithFormatting = 'DataFeatureSetAndTrainRUnGroupWithFormatting',
  chartPayloadItem = 'ChartPayloadItem',
  chartPayloadItemDerived = 'ChartPayloadItemDerived',
  chartPayloadItemComplete = 'ChartPayloadItemComplete',
  accelerationConfig = 'AccelerationConfig',
  accelerationOptions = 'AccelerationOptions',

  loadingStatus = 'LoadingStatus',
  // Top-level props
  trainProps = 'TrainProps',
  singleTrainGroupTrainProps = 'SingleTrainGroupTrainProps',
  trainLoadedProps = 'TrainLoadedProps',
  trainDerivedProps = 'TrainDerivedProps',

  trainRouteGroupProps = 'TrainRouteGroupProps',
  trainRouteOrGroupDerivedProps = 'TrainRouteOrGroupDerivedProps',
  railwayLineMinimizedProps = 'RailwayLineMinimizedProps',
  railwayLineLoadedProps = 'RailwayLineLoadedProps',
  railwayLineDerivedProps = 'RailwayLineDerivedProps',
  serviceLineProps = 'ServiceLineProps',
  serviceLineDerivedProps = 'ServiceLineDerivedProps',
  filteredTrainGroupProps = 'FilteredTrainGroupProps',
  trainGroupOnlyTrainFormationProps = 'TrainGroupOnlyTrainFormationProps',
  trainGroupActivityProps = 'TrainGroupActivityProps',
  trainGroupSingleTrainRunProps = 'TrainGroupSingleTrainRunProps',
  singleTrainGroupProps = 'SingleTrainGroupProps',
  trainGroupDerivedProps = 'TrainGroupProps',
  distanceRange = 'DistanceRange',
  trainRouteDateDerivedProps = 'DateDerivedProps',
  trainFormationDateDerivedProps = 'TrainFormationDateDerivedProps',
  attributeAlertChartProps = 'AttributeAlertChartProps',
  trainGroupSensorProps = 'TrainGroupSensorProps',
  trainGroupSensorDerivedProps = 'TrainGroupSensorDerivedProps',
  trainGroupEquipmentAttributeSensorProps = 'TrainGroupEquipmentSensorProps',
  trainGroupEquipmentSensorDerivedProps = 'TrainGroupEquipmentSensorDerivedProps',
  trainGroupGeojsonProps = 'TrainGroupGeojsonProps',
  trainGroupGeojsonDerivedProps = 'TrainGroupGeojsonDerivedProps',
  trainGroupChartProps = 'TrainGroupChartProps',
  trainGroupChartDerivedProps = 'TrainGroupChartDerivedProps',
  dateInterval = 'DateInterval',
  dateRecurrence = 'DateRecurrence',
  dateRecurrenceTime = 'DateRecurrenceTime',

  // Some props are passed around enough that they are treated as Cemit types.
  trainRouteOrGroupLineProps = 'TrainRouteOrGroupLineProps',
  trainRouteOrGroupLineFinalizedProps = 'TrainRouteOrGroupLineFinalizedProps',
  wheelSetAssetLifetime = 'WheelSetAssetLifetime',
  wheelSetAssetLifetimeFeature = 'WheelSetAssetLifetimeFeature',
  chartDataConfigAndFilteredDataFeatureSets = 'ChartDataConfigAndFilteredDataFeatureSets',
  chartDataCommonConfigFinalized = 'ChartDataCommonConfigFinalized',
  attributeAlertSummary = 'AttributeAlertSummary',
  chartDataConfigWithCustomControls = 'ChartDataConfigWithCustomControls',
  chartDataCommonConfig = 'ChartDataCommonConfig',
  dateDistance = 'DateDistance',
  lifetimeInput = 'LifetimeInput',
  attributeTableCellConfig = 'AttributeTableCellConfig',
  serviceLine = 'ServiceLine',
  serviceLineMinimized = 'ServiceLineMinimized',
  loadingExplanation = 'LoadingExplanation',
  whatIsLoading = 'WhatIsLoading',
  trackRoute = 'TrackRoute',

  trainGroupGeojson = 'TrainGroupGeojson',

  // Indicates that derived geojson and similar has been calculated
  trackDataMinimized = 'TrackDataMinimized',
  trackData = 'TrackData',
  dependencyUnit = 'DependencyUnit',

  singleTrainFormationTrainDependencyProps = 'SingleTrainFormationTrainDependencyProps',

  alertTrainGroupProps = 'AlertTrainGroupProps',
  alertConfigProps = 'AlertConfigProps',

  trainMapContainerProps = 'TrainMapContainerProps',
  trainMapComponentProps = 'TrainMapComponentProps',
  alertChoosersComponentProps = 'AlertChoosersComponentProps',
  alertErrorLevelComponentProps = 'AlertErrorLevelComponentProps',
  alertErrorLevelConfig = 'AlertErrorLevelConfig',
  alertTrainComponentProps = 'AlertTrainComponentProps',
  alertTrainStatusComponentProps = 'AlertTrainStatusComponentProps',
  alertGaugeByTimePeriod = 'AlertGaugeByTimePeriod',
  alertGauge = 'AlertGauge',
  alertGaugeValue = 'AlertGaugeValue',
  alertHeatMapData = 'alertHeatMapData',
  alertHeatMapDatum = 'alertHeatMapDatum',
  alertIntervalDescription = 'AlertIntervalDescription',
  alertScopeProps = 'AlertScopeProps',
  alertReadOnlyTrainGroupProps = 'AlertReadOnlyTrainGroupProps',

  trainPageProps = 'TrainPageProps',

  trainFormationCollectionDevice = 'TrainFormationCollectionDevice',
  trainApiTrainRunRequestProps = 'TrainApiTrainRunRequestProps',
  dataThreshold = 'DataThreshold',
  dataThresholdConfig = 'DataThresholdConfig',
  mapSourceVisual = 'MapSourceVisual',
  mapSourceVisualForTrainGroup = 'MapSourceVisualForTrainGroup',
  mapSourceInfo = 'MapSourceInfo',
  cemitApiRequest = 'CemitApiRequest',
  trainMapSensorProps = 'TrainMapSensorProps',
  trainGroupsGrouping = 'TrainGroupsGrouping',
  trainGroupsGroupingCollection = 'TrainGroupsGroupingCollection',
  dateProps = 'DateProps',
  changeStatus = 'ChangeStatus',
  mapProps = 'MapProps',
  cemitFilterTrainFormationProps = 'CemitFilterTrainFormationProps',
  cemitFilterTrainGroupOnlyFormationProps = 'CemitFilterTrainGroupOnlyFormationProps',
  frontendViewGroup = 'FrontendViewGroup',
  trainGroupTypeChooserProps = 'TrainGroupTypeChooserProps',
  trainGroupTypeOptionProps = 'TrainGroupTypeOptionProps',
  trainGroupTypeOptionContainerProps = 'TrainGroupTypeOptionContainerProps',
  frontendViewGroupSelection = 'FrontendViewGroupSelection',
  frontendViewGroupSelectionDisplay = 'FrontendViewGroupSelectionDisplay',
  frontendViewGroupSelectionDisplayConfig = 'FrontendViewGroupSelectionDisplayConfig',
  cemitFilterTrainRouteGroupProps = 'CemitFilterTrainRouteGroupProps',
  cemitFilterConfig = 'CemitFilterConfig',
  cemitFilterRightSideExpression = 'CemitFilterRightSideExpression',
  localStorageProps = 'LocalStorageProps',
  organizationMenuProps = 'OrganizationMenuProps',
  appProps = 'AppProps',
  trainAppProps = 'TrainAppProps',
  access = 'Access',
  trainGroupMenuProps = 'TrainGroupMenuProps',
  applicationNavigation = 'ApplicationNavigation',
  trainRunComponentProps = 'TrainRunComponentProps',
  trainGroupSelectButtonProps = 'TrainGroupSelectButtonProps',
  trainGroupLongAndCompactContainer = 'TrainGroupLongAndCompactContainer',
  cemitFilterTrainFormationsContainerProps = 'CemitFilterTrainFormationsContainerProps',
  trainGroupTableProps = 'TrainGroupTableProps',
  singleTrainFormationTrainProps = 'SingleTrainFormationTrainProps',
  trainGroupScopeProps = 'TrainGroupScopeProps',
  trainGroupsScopeProps = 'TrainGroupsScopeProps',
  cemitApiTrainFormationsRoute = 'CemitApiTrainFormationsRoute',
  cemitApiOrganizationRoute = 'CemitApiOrganizationRoute',
  cemitApiAvailableDatesRoute = 'CemitApiAvailableDatesRoute',
  cemitApiServiceLinesRoute = 'CemitApiServiceLinesRoute',
  cemitApiRailwayLinesRoute = 'CemitApiRailwayLinesRoute',
  cemitApiTrainRouteGroupsRoute = 'CemitApiTrainRouteGroupsRoute',
  cemitApiTrainRoutesRoute = 'CemitApiTrainRoutesRoute',
  cemitApiTrainRunsRoute = 'CemitApiTrainRunsRoute',
  cemitApiTrainGroupDetailsRoute = 'CemitApiTrainGroupDetailsRoute',
  cemitApiTrainGroupAlertsRoute = 'CemitApiTrainGroupAlertsRoute',
  cemitApiAttributeTimeSeriesRoute = 'CemitApiAttributeTimeSeriesRoute',
  cemitApiVisionNearbyPicturesRoute = 'CemitApiVisionNearbyPicturesRoute',
  trainFormationChooserProps = 'TrainFormationChooserProps',
  trainRoutOrGroupSensorDataRequestProps = 'TrainRoutOrGroupSensorDataRequestProps',
  trainApiTrainRunsRequestProps = 'TrainApiTrainRunsRequestProps',
  trainRouteOrGroupRequestProps = 'TrainRouteOrGroupRequestProps',
  distanceRangeTrainGroupGeojsons = 'DistanceRangeTrainGroupGeojsons',
  trainGroupSensorDataGeojson = 'TrainGroupSensorDataGeojson',
  sensorDataDateInterval = 'SensorDataDateInterval',
  sensorDataPointGeojson = 'SensorDataPointGeojson',
  dataFeatureSetMinimized = 'DataFeatureSetMinimized',
  trainGroupSet = 'TrainGroupSet',
  sensorDataFeature = 'SensorDataFeature',
  sensorDataFeatureDerived = 'SensorDataFeatureDerived',
  chartMapInteractionProps = 'ChartMapInteractionProps',
  sensorDataPointsCustomTooltipProps = 'SensorDataPointsCustomTooltipProps',
  configuredComposedChartProps = 'ConfiguredComposedChartProps',
  alertType = 'AlertType',
  alertGraphqlResponseReportData = 'AlertGraphqlResponseReportData',
  alertReportData = 'AlertReportData',
  alertReportRowSection = 'AlertReportRowSection',
  alertExcelDownloadData = 'AlertExcelDownloadData',
  stackedDataPathComparisonChartProps = 'StackedDataPathComparisonChartProps',
  alertPeriodMapData = 'AlertPeriodMapData',
  alertLevelDatum = 'AlertLevelDatum',
  equipmentAttributeSensorDataDateInterval = 'EquipmentAttributeSensorDataDateInterval',
  crudList = 'CrudList',
  cemitApiLoadingStatus = 'CemitApiLoadingStatus',
  alertViewOption = 'AlertViewOption',
  organizationProps = 'OrganizationProps',
  cemitFilterTrainGroupsContainerProps = 'CemitFilterTrainGroupsContainerProps',
  trainGroupsTitleContainerProps = 'TrainGroupsTitleContainerProps',
  alertTrainGroupGeojson = 'AlertTrainGroupGeojson',
  trainGroupHover = 'TrainGroupHover',
  sensorDataFeatureWithDerivedProps = 'SensorDataFeatureWithDerivedProps',
  themeContextInterface = 'ThemeContextInterface',
  themeContextInterfaceWithToggle = 'ThemeContextInterfaceWithToggle',
  jurisdiction = 'Jurisdiction',
  address = 'Address',
  trafficSimConfig = 'TrafficSimConfig',
  trafficSimPageConfig = 'TrafficSimPageConfig',
  trafficSimAppProps = 'TrafficSimAppProps',
  namedMapboxExpression = 'NamedMapboxExpression',
  realtimeTrainGroupProps = 'RealtimeTrainGroupProps',
  realtimeTrainConfigProps = 'RealtimeTrainConfigProps',
  realtimeTrainScopeProps = 'RealtimeTrainScopeProps',
  realtimeTrainGroupGeojson = 'RealtimeTrainGroupGeojson',
  realtimeTrainSignal = 'RealtimeTrainSignal',
  alertTypeConfig = 'AlertTypeConfig',
  weekYear = 'WeekYear',
  visionTimestamp = 'VisionTimestamp',
  visionDependencyProps = 'VisionDependencyProps',
  visionProps = 'VisionProps',
  visionMeta = 'VisionMeta',
  trackRouteMinimized = 'TrackRouteMinimized',
  cemitApi = 'CemitApi',
  trainApiVisionNearbyPicturesRequest = 'TrainApiVisionNearbyPicturesRequest',
  visionConfig = 'VisionConfig',
  alertTypeConfigVisibleAttributeAlertLevelEnum = 'AlertTypeConfigVisibleAttributeAlertLevelEnum',
  mapLayerProps = 'MapLayerProps',
  trainMapLayerProps = 'TrainMapLayerProps',
  alertGraphqlResponseAlertData = 'AlertGraphqlResponseAlertData',
  trainFormationRunCount = 'TrainFormationRunCount',
  sortConfig = 'SortConfig',
  dateIntervalDescription = 'DateIntervalDescription',
  graphqlResponseDateIntervalOverviewAlertData = 'GraphqlResponseDateIntervalOverviewAlertData',
  overviewAlertData = 'OverviewAlertData',
  tableGroupOverviewColumn = 'TableGroupOverviewColumn',
  trainGroupClassification = 'TrainGroupClassification'
}
