import {TrainGroup} from 'types/trainGroups/trainGroup.ts';
import {Cemited} from 'types/cemited';

export interface TrainGroupClassification extends Cemited {
  // Unique key, such as the name before internationalization
  sourceKey: string,
  // The name of the TrainGroupClassification
  name: string,
  // The description of the TrainGroupClassification
  description: string,
  // The TrainGroups in the TrainGroupClassification
  trainGroups: TrainGroup[]
}

// all is currently the only TrainGroupClassification that all organizations have
export enum TrainGroupClassificationEnum {
  allTrains= 'allTrains'
}
