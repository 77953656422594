import {chain, forEach, join, map, zipWith} from 'ramda';
import {Map} from 'mapbox-gl';
import {TrainRouteOrGroup} from 'types/trainRouteGroups/trainRouteOrGroup';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {
  DataThreshold,
  DataThresholdStyles,
  ZoomLevelValue
} from 'types/dataVisualizations/dataThreshold';
import {FeatureCollection} from 'geojson';
import TrainFacingSvgIcon from 'components/apps/cemitAppComponents/svgIconComponents/trainFacingSvgIcon.tsx';
import {
  removeMapboxLayersAndSources,
  setMapboxSourceAndLayersSets
} from 'utils/map/mapboxSourceUtils.ts';
import {
  CHART_HOVER_TRAIN_GROUP_LAYER_PREFIX,
  CHART_HOVER_TRAIN_GROUP_SOURCE_PREFIX
} from 'config/appConfigs/trainConfigs/trainConfig.ts';
import {CemitTypename} from 'types/cemitTypename.ts';

import {PerhapsIfLoading} from 'types/logic/requireIfLoaded.ts';

import {TrainGroup} from 'types/trainGroups/trainGroup';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {ts} from 'appUtils/typeUtils/clsOrType.ts';
import {
  ChartPayloadItem,
  ChartPayloadItemDerived
} from 'types/dataVisualizations/chartPayloadItem';
import {featureCollectionFromPayload} from 'utils/chart/chartMapInteractionUtils.ts';
import {Cemited} from 'types/cemited';
import {svgIconComponentToBase64Encoded} from 'utils/svg/svgUtils.ts';
import {
  MapboxIconConfig,
  MapboxLayer,
  MapSourceVisual,
  MapSourceVisualForTrainGroup
} from 'types/mapbox/mapSourceVisual';
import {clsOrTypes} from 'appUtils/typeUtils/clsOrTypes.ts';
import {useNotLoadingMemo, useNotLoadingSetterEffect} from 'utils/hooks/useMemoHooks.ts';
import {MapSourceChangeStatus} from 'types/mapbox/mapSourceChangeStatus';
import {memoizedTrainGroupIconImagesSourceAndLayers} from 'utils/map/layers/dynamicIconLayers.ts';
import {svgDataUrlIconNameForIconOfTrainGroup} from 'appUtils/trainAppUtils/trainGroupMapUtils/trainGroupMapUtils.ts';
import {onlyOneValueOrThrow} from 'utils/functional/functionalUtils.ts';
import {MapboxImage} from 'types/mapbox/MapboxImage.ts';

export type UseUpdate3dForRideComfortTrainMapHookProps<L extends boolean> = {
  trainMap: Map;
  trainRouteOrGroup: PerhapsIfLoading<L, TrainRouteOrGroup>;
  set3dLayersUpdating: StateSetter<boolean>;
  featurePropPath: string;
  dataThresholds: DataThreshold[];
  dataColumns3DLayerAreVisible: boolean;
  extrude: boolean;
};

interface TrainGroupHover extends Cemited {
  trainGroup: TrainGroup;
  payloadItem?: ChartPayloadItemDerived;
  featureCollection?: FeatureCollection;
}

/**
 * Updates the position of TrainGroups on the map according to mostRecentTooltipPayloadItems
 * from the charts
 * @param loading If true, do nothing
 * @param trainMap
 * @param mostRecentTooltipPayloadItems
 * @param setChangeStatuses
 * @param setMapboxImages
 */
export const useNotLoadingEffectUpdateTrainGroupHoverOnMap = (
  loading: boolean,
  trainMap: Map,
  mostRecentTooltipPayloadItems: Perhaps<ChartPayloadItem[]>,
  setChangeStatuses: StateSetter<MapSourceChangeStatus[]>,
  setMapboxImages: StateSetter<MapboxImage[]>
): void => {
  // TODO the dataThresholds doesn't care about a feature prop value right now
  const featurePropPath = 'value';
  // Currently this threshold is used for any value
  const dataThresholds = clsOrTypes<DataThreshold>(CemitTypename.dataThreshold, [
    {
      sourceKey: 'default',
      label: 'default',
      value: Infinity,
      style: {
        color: '#6FCF97',
        icon: 'train',
        size: [
          // The train icon size is currently always 2
          {zoom: 0, outputValue: 1} as ZoomLevelValue
        ]
      } as Partial<DataThresholdStyles>
    }
  ]);

  const trainGroups: TrainGroup[] = map(
    (mostRecentTooltipPayloadItem: ChartPayloadItem) =>
      mostRecentTooltipPayloadItem.trainGroup,
    mostRecentTooltipPayloadItems || []
  );
  // Hash the TrainGroups so we avoid regenerating the svg icons if we don't have to
  const trainGroupIdColorHash = join(
    '\n',
    map((trainGroup) => {
      return join('-', [
        trainGroup.id,
        trainGroup?.activity?.isVisible,
        trainGroup?.activity?.isActiveColor
      ]);
    }, trainGroups || [])
  );
  // MapboxIconConfigs need a unique name per trainGroup per icon
  // The svgs must be data urls
  const iconPrefix = 'hover';

  // Precalculate the MapboxIconConfig sets per TrainGroup so we can memoize and not do it every hover
  const trainGroupIconConfigSets: Perhaps<MapboxIconConfig[][]> =
    useNotLoadingMemo(loading, () => {
      return map((trainGroup: TrainGroup) => {
        // Convert the React component svg to a data url that Mapbox can use
        const trainGroupSvgIconEncodedSvg = svgIconComponentToBase64Encoded(
          TrainFacingSvgIcon,
          {
            fill: trainGroup!.activity!.isActiveColor
          }
        );

        // this in an array in case we want to support multiple icons for different circumstances in the future
        return [
          ts<MapboxIconConfig>({
            name: svgDataUrlIconNameForIconOfTrainGroup(iconPrefix, trainGroup),
            svg: trainGroupSvgIconEncodedSvg
          })
        ];
      }, trainGroups);
    }, [trainGroups]);

  // Create update the Mapbox hover source/layer for each TrainGroup
  return useNotLoadingSetterEffect(
    {
      loading: loading || !mostRecentTooltipPayloadItems!
    },
    trainGroupHoverLayers,
    ts<TrainGroupHoverLayersProps>({
      mostRecentTooltipPayloadItems: mostRecentTooltipPayloadItems!,
      featurePropPath,
      dataThresholds,
      trainGroupIconConfigSets: trainGroupIconConfigSets!,
      iconPrefix
    }),
    // Update the trainMap to the sources and layers that are defined.
    // Those not defined indicate that the corresponding TrainGroup's RideComfort is in a loading state
    (mapSourceVisuals: MapSourceVisualForTrainGroup[]) => {
      // Set the heatmap sources and layers on TrainMap
      setMapboxSourceAndLayersSets(
        trainMap,
        setChangeStatuses,
        mapSourceVisuals,
        setMapboxImages,
        false
      );
      // Move these layers to the top so they are above tracks
      forEach(
        (layer: MapboxLayer) => {
          trainMap.moveLayer(layer.id);
        },
        chain((mapSourceVisuals: MapSourceVisualForTrainGroup) => {
          return mapSourceVisuals.layers;
        }, mapSourceVisuals)
      );

      // Remove any RIDE_COMFORT_TRAIN_GROUP_LAYER_PREFIX layers from previous iterations that are no longer present
      removeMapboxLayersAndSources({
        layerPrefix: CHART_HOVER_TRAIN_GROUP_LAYER_PREFIX,
        sourcePrefix: CHART_HOVER_TRAIN_GROUP_SOURCE_PREFIX,
        preserveSourceVisuals: mapSourceVisuals,
        mapboxMap: trainMap
      });
    },
    [loading, trainMap, mostRecentTooltipPayloadItems] as const
  );
};

interface TrainGroupHoverLayersProps {
  mostRecentTooltipPayloadItems: ChartPayloadItem[];
  featurePropPath: string;
  dataThresholds: DataThreshold[];
  trainGroupIconConfigSets: MapboxIconConfig[][];
  iconPrefix: string;
}

/**
 * Creates a an icon hover Mapbox source and layer for a TrainGroup
 * @param mostRecentTooltipPayloadItems
 * @param featurePropPath
 * @param dataThresholds
 * @param trainGroupIconConfigSets Passed in so that icons generation can be memoized based on changes
 * to the TrainGroups or their colors or visibility
 * @param iconPrefix
 */
const trainGroupHoverLayers = ({
                                 mostRecentTooltipPayloadItems,
                                 featurePropPath,
                                 dataThresholds,
                                 trainGroupIconConfigSets,
                                 iconPrefix
                               }: TrainGroupHoverLayersProps): MapSourceVisual[] => {
  // Creates a list of mapSourceVisual sets, where each trainGroup produces
  // an icon indicating where it is according to the user's chart hover
  return zipWith(
    (
      mostRecentTooltipPayloadItem: ChartPayloadItem,
      trainGroupIconConfigs: MapboxIconConfig[]
    ): MapSourceVisual => {
      const trainGroup = mostRecentTooltipPayloadItem.trainGroup;
      const featureCollection = featureCollectionFromPayload([
        mostRecentTooltipPayloadItem
      ]);

      const mapSourceVisual: MapSourceVisual =
        memoizedTrainGroupIconImagesSourceAndLayers({
          trainGroup,
          trainGroupIconConfigs,
          featurePropPath,
          geojson: featureCollection,
          dataThresholds,
          sourceNamePrefix: CHART_HOVER_TRAIN_GROUP_SOURCE_PREFIX,
          layerNamePrefix: CHART_HOVER_TRAIN_GROUP_LAYER_PREFIX,
          iconPrefix,
          iconConfigSize: 40
        });
      return mapSourceVisual;
    },
    mostRecentTooltipPayloadItems,
    trainGroupIconConfigSets
  );
};
