import React from 'react';
import {ifElse} from 'ramda';
import SandboxAppContainer from 'pages/sandbox/SandboxAppContainer.tsx';
import CemitAppDependency from 'async/cemitAppAsync/CemitAppDependency.tsx';
import TrainAppDependency from 'async/trainAppAsync/trainAppDependencies/TrainAppDependency.tsx';
import {TrainAppOrganizationDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppOrganizationDependencyProps.ts';
import TrainDependencyUnits from 'async/trainAppAsync/trainAppDependencies/TrainDependencyUnits.tsx';
import {OrganizationSourceKey} from 'apis/apiOrganizationConfigs/organizationManifest.ts';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {ThemeEnum, themeLookup} from 'theme/lightDarkTheme.ts';
import {ThemeProvider} from '@mui/material';

/**
 * Temporary solution to show the Sandbox app as the Train app of South Western Railway
 * @constructor
 */
export const TrainAppRoute = () => {
  // Only the dark theme is supported in Train apps, except when computing the Map style
  const theme = themeLookup[ThemeEnum.dark];
  return (
    <CemitAppDependency {...{appTitleKey: 'train'}}>
      <TrainAppDependency
        organizationDependencyChildren={({
          appProps,
          organizationProps,
        }: TrainAppOrganizationDependencyProps) => {
          // Depends on appProps and organizationProps since we
          // need to rerender all of TrainDependency's Dependencies whenever something in appProps organizationProps changes
          return useNotLoadingMemo(
            organizationProps.loading,
            (appProps, organizationProps) => {
              const app = ifElse(
                Boolean,
                () => {
                  return <SandboxAppContainer {...{appProps, organizationProps}} />;
                },
                () => {
                  return <TrainDependencyUnits {...{appProps, organizationProps}} />;
                },
              )(
                process.env.REACT_FEATURE_SANDBOX === 'true' &&
                  organizationProps.organization.sourceKey ==
                    OrganizationSourceKey.southWesternRailway,
              );
              return <ThemeProvider {...{theme}}>{app}</ThemeProvider>;
            },
            [appProps, organizationProps] as const,
          );
        }}
      />
    </CemitAppDependency>
  );
};

export default TrainAppRoute;
