import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {Stack, Typography} from '@mui/material';

import {length} from 'ramda';
import TrainGroupOverviewDateIntervalPicker
  from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/TrainGroupOverviewDateIntervalPicker.tsx';
import {SyntheticEvent} from 'react';
import {AlertOverviewByTimePeriod} from 'types/alerts/alertOverviewByTimePeriod.ts';
import TrainGroupClassificationPicker, {
  TrainGroupClassificationPickerProps
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/TrainGroupClassificationPicker.tsx';

/**
 * A Header for the TrainGroup Overview table
 * @param appProps
 * @param trainProps
 * @constructor
 */
const TrainGroupOverviewHeader = (
  {
    appProps,
    trainProps
  }: TrainAppMapDependencyProps
) => {
  // Set  appProps.alertOverviewByTimePeriod to the selected AlertOverviewByTimePeriod
  const handleTrainGroupOverviewDateIntervalClick = (event: SyntheticEvent, alertOverviewByTimePeriod: AlertOverviewByTimePeriod) => {
    event.stopPropagation();
    appProps.setAlertOverviewByTimePeriod(alertOverviewByTimePeriod);
  };
  const trainGroupClassifications = trainProps.trainGroupOnlyTrainFormationProps.trainGroupClassifications;

  return <Stack {...{
    direction: 'row', sx: {
      minWidth: 0,
      minHeight: 0,
      flex: 1,
      overflow: 'hidden',
      position: 'relative',
      width: '100%',
      height: '100%',
      justifyContent: 'space-between'
    }
  }}>
    <Stack {...{direction: 'row', spacing: 2, sx: {justifyContent: 'flex-start', alignItems: 'center'}}}>
      <Typography {...{
        variant: 'h4', sx: {textAlign: 'center', alignSelf: 'center'}, color: 'secondary'
      }}>
        {appProps.t('trainOverview')}
      </Typography>
      {
        // Show a TrainGroupClassification picker if there is more than the default TrainGroupClassification
        // This lets the user limit the TrainGroups to a certain TrainGroupClassification
        length(trainProps.trainGroupOnlyTrainFormationProps.trainGroupClassifications || []) > 1 ?
          <TrainGroupClassificationPicker {...{
            t: appProps.t,
            trainGroupClassifications,
            trainGroupClassificationSourceKey: appProps.trainGroupClassificationSourceKey,
            setTrainGroupClassificationSourceKey: appProps.setTrainGroupClassificationSourceKey
          } as TrainGroupClassificationPickerProps} /> : undefined
      }
    </Stack>
    <Stack {...{direction: 'row', sx: {justifyContent: 'flex-end', alignItems: 'center'}}}>
      <TrainGroupOverviewDateIntervalPicker {...{
        t: appProps.t,
        handleClick: handleTrainGroupOverviewDateIntervalClick,
        alertOverviewByTimePeriod: appProps.alertOverviewByTimePeriod
      }} />
    </Stack>
  </Stack>;
};
export default TrainGroupOverviewHeader;
