import {TFunction} from 'i18next';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {
  AlertTypeKey,
  AlertTypeLabels,
  AlertTypeShortLabels,
} from 'types/alerts/alertTypeKey.ts';
import {
  brakingAlertLevelToLabel,
  BrakingAttributeAlertSValue,
  brakingLevelToAttribute,
  brakingLevelToAttributeForType,
} from 'types/alerts/braking/brakingAlertLevel.ts';
import {brakingErrorLevelConfigs} from 'types/alerts/braking/brakingErrorLevelConfigs.ts';
import {BrakingAttributeAlertLevel} from 'types/alerts/braking/brakingAttributeAlertLevel.ts';
import {brakingDataThresholds} from 'types/alerts/braking/brakingDataThresholds.ts';
import {alertTypeConfigTrainGroupIconConfigs} from 'types/alerts/alertTypeTrainGroupIconConfigs.ts';

export const brakingAlertTypeConfig = (t: TFunction): AlertTypeConfig => {
  return clsOrType<AlertTypeConfig>(CemitTypename.alertTypeConfig, {
    alertTypeKey: AlertTypeKey.brakingPointId,
    alertLevelToAttribute: brakingLevelToAttribute,
    alertLevelToAttributeForTypeKeys: brakingLevelToAttributeForType,
    alertLevelToLabel: brakingAlertLevelToLabel(t),
    label: t(AlertTypeLabels.brakingPointId),
    labelShort: t(AlertTypeShortLabels.brakingPointId),
    attributeAlertLevelConfigs: brakingErrorLevelConfigs(t),
    criticalSValueLevel: BrakingAttributeAlertSValue.braking,
    warningLevels: [BrakingAttributeAlertLevel.hardBraking],
    criticalLevels: [
      BrakingAttributeAlertLevel.fullEmergencyBraking,
      BrakingAttributeAlertLevel.emergencyBraking,
    ],
    dataThresholds: brakingDataThresholds(t),
    attributeAlertLevelEnum: BrakingAttributeAlertLevel,
    defaultVisibleAttributeAlertLevels: [
      BrakingAttributeAlertLevel.fullEmergencyBraking,
      BrakingAttributeAlertLevel.emergencyBraking,
    ],

    propsResolver: (node) => {
      return {
        retardation: node.data.rawValue,
      };
    },
    unit: 'METERS_PER_SECOND_SQUARED',
    levelAllValueGreaterThan: 1.0,
    useUnitInLevelAllQuery: true,
  });
};
