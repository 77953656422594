import {OrganizationProps} from '../../../types/propTypes/organizationPropTypes/organizationProps';
import {MapboxIconConfig, MapboxIconsConfig, MapboxLayer, MapSourceVisual} from '../../../types/mapbox/mapSourceVisual';
import {join, keys, map} from 'ramda';
import {
  SCHEDULED_STOP_POINT_LAYER_PREFIX,
  SCHEDULED_STOP_POINT_SOURCE_PREFIX
} from '../../../config/appConfigs/cemitAppConfigs/railwayLineConfig.ts';
import {mapboxGeojsonSource} from '../../../utils/map/mapboxSourceUtils.ts';
import {CEMIT_TRAIN_BUTTON_BACKGROUND, CEMIT_WHITE} from '../../../theme/cemitColors.ts';
import {CemitTypename} from '../../../types/cemitTypename.ts';
import {combineFeatureCollections} from '../../../utils/geojson/geojsonUtils.ts';
import {clsOrType} from '../../typeUtils/clsOrType.ts';
import StationSvgIcon from 'components/apps/cemitAppComponents/svgIconComponents/StationSvgIcon.tsx';
import {svgIconComponentToBase64Encoded} from 'utils/svg/svgUtils.ts';
import {AnyLayout} from 'mapbox-gl';
import {themeLookup} from 'theme/lightDarkTheme.ts';

/**
 * Extracts nodes that represent ScheduledStopPoints from  organizationProps.organization.stopFeatureCollections
 * @param organizationProps
 * @param themeMode The current theme light or dark
 */
export const scheduledStopPointMapboxSourcesAndLayersSets = (
  {organizationProps, themeMode}:
    {organizationProps: OrganizationProps, themeMode: string}
): MapSourceVisual[] => {

  const scheduledStopPointGeojson = combineFeatureCollections(
    organizationProps.organization.stopFeatureCollections
  );

  const organizationId = organizationProps.organization.id;
  const scheduledStopPointSourceName = join('-', [SCHEDULED_STOP_POINT_SOURCE_PREFIX, organizationId]);

  const scheduledStopPointSource = mapboxGeojsonSource({
    sourceName: scheduledStopPointSourceName,
    featureCollection: scheduledStopPointGeojson
  });

  // Make a layer for each theme, light and dark, hiding the one that isn't active
  const scheduledStopPointLayers: MapboxLayer[] = map((lightOrDarkThemeMode: string) => {
    const color = lightOrDarkThemeMode == 'light' ? CEMIT_TRAIN_BUTTON_BACKGROUND : CEMIT_WHITE;
    const svg = svgIconComponentToBase64Encoded(StationSvgIcon, {
      stroke: color
    });
    const scheduledStopPointLayerId = join('-', [SCHEDULED_STOP_POINT_LAYER_PREFIX, organizationId, lightOrDarkThemeMode]);
    return {
      id: scheduledStopPointLayerId,
      type: 'symbol',
      source:  scheduledStopPointSourceName,
      iconConfig: {
        width: 20,
        height: 20,
        iconConfigs: [{name: `${color}Station`, svg} as MapboxIconConfig]
      } as MapboxIconsConfig,
      paint: {
        'text-color': color
      },
      layout: {
        'text-size': {
          'stops': [
            [0, 0],
            [9, 0],
            [10, 10],
            [13, 14]
          ]
        },
        'text-justify': 'auto',
        'text-rotation-alignment': 'auto',
        'text-field': ['get', 'name'],
        'icon-image': `${color}Station`,
        'icon-anchor': 'center',
        'icon-size': [
          'interpolate',
          ['linear'],
          ['zoom'],
          0, 0,
          8, 1
        ],
        'text-offset': [0.5, 0.5],
        'text-variable-anchor': ['top-left'],
        //  One of these two layers will be set to visibility 'visible' based on if it's color is the current theme
        visibility: themeMode === lightOrDarkThemeMode ? 'visible' : 'none',
      } as AnyLayout
    } as MapboxLayer;
  }, keys(themeLookup))

  return [clsOrType<MapSourceVisual>(CemitTypename.mapSourceVisual, {
    source: scheduledStopPointSource,
    layers: scheduledStopPointLayers
  })];
};
