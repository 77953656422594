import {TFunction} from 'i18next';
import {AlertScopeProps} from 'types/alerts/alertScopeProps.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {
  AlertDatum,
  GraphqlResponseAlertData,
  GraphqlResponseDateIntervalOverviewAlertData,
  OverviewAlertData
} from 'types/alerts/alertMapData.ts';
import {mapKeys, mapMDeep} from '@rescapes/ramda';
import {
  add,
  chain,
  filter,
  find,
  fromPairs,
  groupBy,
  head,
  identity,
  includes, indexOf,
  join,
  keys,
  lensProp,
  map,
  mapObjIndexed,
  over,
  prop,
  reduce,
  split,
  tail,
  toPairs,
  values,
  zipWith
} from 'ramda';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig.ts';
import {findOrThrow, stringifyDate} from 'utils/functional/functionalUtils.ts';
import {AlertLevels} from 'types/alerts/alertLevels.ts';
import {simplyFetchFromGraph} from 'appUtils/alertUtils/graphqlQueryUtils.ts';
import {clsOrTypes} from 'appUtils/typeUtils/clsOrTypes.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {getAlertDateIntervals} from './graphqlSingleAlertTypeQueries';

import {findFirstVehicleCollectionDeviceWithPointIds} from 'appUtils/alertUtils/alertUtils.ts';
import {VehicleCollectionDevice} from 'types/sensors/vehicleCollectionDevice';
import {AlertGaugeByTimePeriod} from 'types/alerts/alertGaugeByTimePeriod.ts';
import {DateIntervalDescription} from 'types/datetime/dateIntervalDescription.ts';
import {AlertTypeKey} from 'types/alerts/alertTypeKey.ts';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {differenceInDays, differenceInMinutes, parseISO, subDays} from 'date-fns';
import {clsOrType} from 'appUtils/typeUtils/clsOrType';
import {createDateInterval} from 'classes/typeCrud/dateIntervalCrud.ts';
import {isWithinInterval} from 'date-fns/isWithinInterval';
import {AlertOverviewByTimePeriod, AlertOverviewByTimePeriodPrevious} from 'types/alerts/alertOverviewByTimePeriod.ts';

/**
 * Creates several graphql queries that are sent together to the server.
 * A queries are number AlertType * number of AlertLevels per AlertType * number of TrainGroup
 * These queries don't return positions, just counts of each alert
 * @param t
 * @param alertScopeProps
 */
export async function getAllTrainGroupsSummaryAlertGraphqlData(
  t: TFunction,
  alertScopeProps: AlertScopeProps
): Promise<Perhaps<{
  summaryAlertGraphqlResponseAlertData: GraphqlResponseAlertData[],
  overviewAlertGraphqlResponseAlertData: GraphqlResponseDateIntervalOverviewAlertData[]
}>> {

  const {alertTypeConfigs} = alertScopeProps.alertConfigProps;
  // Find the TrainFormation's VehicleCollectionDevice that has pointIds configured.
  // TODO There could be two VehicleCollectionDevices's (CDCs) on a TrainFormation, but
  // we only consider the first we find with point ids configured
  const vehicleCollectionDevice: VehicleCollectionDevice = findFirstVehicleCollectionDeviceWithPointIds(alertScopeProps);
  // The summary queries
  const summarySignalAggregations: string[] = alertSummarySignalAggregations(t, vehicleCollectionDevice, alertScopeProps, alertTypeConfigs);
  // The overview query. This only concerns RideComfort
  const rideComfortAlertTypeConfig = findOrThrow(
    (alertTypeConfig: AlertTypeConfig) => {
      return alertTypeConfig.alertTypeKey == AlertTypeKey.alertPointId;
    },
    alertTypeConfigs
  );

  // The dateInterval is always from now back 180 days for the overview
  const now = new Date();
  const overviewDateInterval = clsOrType<DateInterval>(CemitTypename.dateInterval, {
    start: subDays(now, 180),
    end: now
  });
  const overviewSignalAggregations: string[] = alertOverviewSignalAggregation(t, overviewDateInterval, vehicleCollectionDevice, alertScopeProps, rideComfortAlertTypeConfig);

  const pointsData = `
  query{
    ${join('\n', [...summarySignalAggregations, ...overviewSignalAggregations])}
  }`;
  try {
    const {data} = await simplyFetchFromGraph({
      query: pointsData
    });

    // Create each AlertGraphqlResponseAlertData, backreferencing the alertTypeConfig used for the query
    const _dataByAlertType = groupBy(
      ([key, value]: [string, any]) => {
        if (includes('Days180', key)) {
          // Separate at overview results
          return 'Overview';
        }
        return head(split('_', key));
      },
      toPairs(data)
    );

    // Operate on the lists of each object, removing the key from the pair
    const dataByAlertType = map(
      fromPairs,
      mapMDeep(
        2,
        ([key, value]: [string, any]) => {
          return [join('_', tail(split('_', key))), value];
        },
        _dataByAlertType
      )
    );

    const summaryAlertGraphqlResponseAlertData = clsOrTypes<GraphqlResponseAlertData>(
      CemitTypename.alertGraphqlResponseAlertData,
      map((alertTypeConfig: AlertTypeConfig) => {
        const data = dataByAlertType[alertTypeConfig.labelShort];
        return {
          data,
          alertTypeConfig
        };
      }, alertTypeConfigs)
    );
    const overviewDataWithDatesByLevel: Record<string, AlertDatum[]> = map(
      (alertData: AlertDatum[]): AlertDatum[] => {
        return map(
          (alertDatum: AlertDatum): AlertDatum => {
            return over(
              lensProp('time'),
              (time: Perhaps<string | Date>): Date => {
                return parseISO(time as string);
              },
              alertDatum);
          },
          alertData);
      },
      dataByAlertType['Overview']
    );

    // Aggregate overview data by 1 day, 30, days, 90 days, and 180 days
    const overviewAggregationDateIntervals: Record<AlertOverviewByTimePeriod, DateInterval> = {
      [AlertOverviewByTimePeriod.Days1]: createDateInterval(subDays(overviewDateInterval.end, 1), overviewDateInterval.end),
      [AlertOverviewByTimePeriod.Days7]: createDateInterval(subDays(overviewDateInterval.end, 7), overviewDateInterval.end),
      [AlertOverviewByTimePeriod.Days14]: createDateInterval(subDays(overviewDateInterval.end, 14), overviewDateInterval.end),
      [AlertOverviewByTimePeriod.Days30]: createDateInterval(subDays(overviewDateInterval.end, 30), overviewDateInterval.end),
      [AlertOverviewByTimePeriod.Days90]: createDateInterval(subDays(overviewDateInterval.end, 90), overviewDateInterval.end),
      [AlertOverviewByTimePeriod.Days180]: overviewDateInterval,

      // Previous intervals relative to those above, except Days180. This lets us calculate rate of change
      [AlertOverviewByTimePeriodPrevious.Days2To1]: createDateInterval(
        subDays(overviewDateInterval.end, 2),
        subDays(overviewDateInterval.end, 1)),
      [AlertOverviewByTimePeriodPrevious.Days14To7]: createDateInterval(
        subDays(overviewDateInterval.end, 14),
        subDays(overviewDateInterval.end, 7)),
      [AlertOverviewByTimePeriodPrevious.Days28To14]: createDateInterval(
        subDays(overviewDateInterval.end, 28),
        subDays(overviewDateInterval.end, 14)),
      [AlertOverviewByTimePeriodPrevious.Days60To30]: createDateInterval(
        subDays(overviewDateInterval.end, 60),
        subDays(overviewDateInterval.end, 30)),
      [AlertOverviewByTimePeriodPrevious.Days180To90]: createDateInterval(
        subDays(overviewDateInterval.end, 180),
        subDays(overviewDateInterval.end, 90)),
    };


    // Get the overview data that fits each overviewAggregationDateInterval
    const overviewDataBrokenDownByDateIntervalByLevel = map(
      (overviewDataWithDates: AlertDatum[]): Record<string, AlertDatum[]> => {
        return map(
          (overviewAggregationDateInterval: DateInterval) => {
            return filter(
              ({time}: {time: Date}) => {
                return isWithinInterval(time, overviewAggregationDateInterval);
              }, overviewDataWithDates
            );
          },
          overviewAggregationDateIntervals
        );
      },
      overviewDataWithDatesByLevel
    );
    // Combine the different levels to make a percent
    // ((L0) / (L0 + L1 + L2 + L3)) * 100
    const overviewAlertGraphqlResponseAlertData: GraphqlResponseDateIntervalOverviewAlertData[] = values(mapObjIndexed(
      (dateInterval: DateInterval, dateIntervalLabel: string) => {
        // TODO should be from AlertTypeConfig
        const levels =  ['L0', 'L1', 'L2', 'L3']
        // TODO temp for debugging
        const name = alertScopeProps.scopedTrainGroup.trainFormation.name
        if (!name) {console.log(name)}
        const normalLevelIndex = indexOf('L0', levels)
        const sums: number[] = map(
          (levelLabel: string): number[] => {
            return reduce(add, 0, map(prop('count'), overviewDataBrokenDownByDateIntervalByLevel[`${levelLabel}_Days180`][dateIntervalLabel]));
          },
          levels
        );
        const total: number = reduce(
          add,
          0,
          sums
        );
        const percent: number = 100 * (sums[normalLevelIndex] / total);

        // Operational hour per day(or other) =( Count (all signals) x 20(sec) )/ (60secx60min) = Count (all signals) / (20x3600)
        // TODO this was used as a divident for operationHoursPerDay
        // const daysInDateInterval = differenceInDays(dateInterval.end, dateInterval.start)
        const operationHours = Math.round(total * 20 / 3600)

        return clsOrType<GraphqlResponseDateIntervalOverviewAlertData>(CemitTypename.graphqlResponseDateIntervalOverviewAlertData, {
            dateIntervalLabel,
            dateInterval,
            percent,
            operationHours,
            // Convert L1_Days180 to L1, etc
            overviewAlertData: clsOrType<OverviewAlertData>(CemitTypename.overviewAlertData, mapKeys(
              (label: string) => {
                return split('_', label)[0];
              },
              map((overviewDataBrokenDownByDateIntervalOfLevel: Record<string, AlertDatum[]>) => {
                return overviewDataBrokenDownByDateIntervalOfLevel[dateIntervalLabel];
              }, overviewDataBrokenDownByDateIntervalByLevel)
            ))
          }
        );
      },
      overviewAggregationDateIntervals
    ));

    return {
      summaryAlertGraphqlResponseAlertData,
      overviewAlertGraphqlResponseAlertData
    };
  } catch (error) {
    return undefined;
  }
}


/**
 * Creates a query for each alertTypeConfig for each alert level
 */
const alertSummarySignalAggregations = (
  t: TFunction,
  vehicleCollectionDevice: VehicleCollectionDevice,
  alertScopeProps: AlertScopeProps,
  alertTypeConfigs: AlertTypeConfig[]
): string[] => {
  // Map over the alertTypeConfigs, which is a config for each alert type
  // We create a separate query for each alert type with the current date interval
  const signalAggregations: string[][] = map(
    (alertTypeConfig: AlertTypeConfig): string[] => {

      // Get the pointId of this alert type for the TrainFormation's sensor
      const pointId = vehicleCollectionDevice[alertTypeConfig.alertTypeKey];

      // Get configured AlertGaugeByTimePeriod, either AlertGaugeByTimePeriod.today,
      // AlertGaugeByTimePeriod.week, or AlertGaugeByTimePeriod.month
      const alertGaugeTimePeriod: keyof AlertGaugeByTimePeriod = alertScopeProps.alertConfigProps.alertGaugeTimePeriod;

      // The AlertIntervalDescription specified by the user for TrainGroupFormationOnly or
      // the AlertIntervalDescription based on the TrainGroup for TrainGroupSingleTrainRun
      const dateIntervalDescription: DateIntervalDescription = alertScopeProps.alertTrainGroupProps.dateIntervalDescription!;

      const dateInterval = dateIntervalDescription.dateInterval;
      // The alert levels of the AlertType
      const levels: (keyof AlertLevels)[] = keys(alertTypeConfig.alertLevelToAttribute);
      // The alert level keys, e.g. L0, L1, L2, L3
      const types: (keyof AlertLevels)[] = keys(
        alertTypeConfig.alertLevelToAttributeForTypeKeys ||
        alertTypeConfig.alertLevelToAttribute
      );

      // Gets components of the query that are dependent on dateInterval
      // filteredDurations is a single list describing the AlertGaugeByTimePeriod and a function to make
      // the correct date interval based upon it. E.g. today creates a 24-hour date interval
      // fromFunc similarly helps calculate the correct date interval
      // windows is a structure that tells us what window to use for the query based on the number of
      // secconds of dateInterval
      const {filteredDurations, fromFunc, windows} =
        getAlertDateIntervals(
          t,
          dateIntervalDescription,
          alertGaugeTimePeriod,
          dateInterval,
          true
        );
      const unit = alertTypeConfig.unit;

      // Create signalAggregations for each level/type for the single item in filteredDurations
      const signalAggregations: string[] = chain((duration) => {
        return zipWith(
          (level, type) => {
            // Use the interval of from - chosen duration to from or dateInterval.start if defined
            const [fromDate, toDate] = [
              fromFunc(duration, dateInterval),
              dateInterval.end
            ];
            const [fromStr, toStr] = map(stringifyDate, [fromDate, toDate]);
            // TODO Currently limiting the window DAY_1 so that Today summary query returns
            // the same percent as the overview query, which always uses DAY_1
            //const seconds = (toDate - fromDate) / 1000;
            const windowToUse = find((win) => {
              return win.label == 'DAY_1'
              //return win.value > seconds;
            }, windows);

            return `${alertTypeConfig.labelShort}_${level}_${duration.label}:signalsAggregation(
      where: { pointId:"${pointId}", type: "${type}", unit: ${unit} }
      aggregate: {
        from:"${fromStr}"
        to: "${toStr}"
        window: ${windowToUse.label}
      }
    ) {
      count
    }`;
          },
          levels,
          types
        );
      }, filteredDurations);

      return signalAggregations;
    },
    alertTypeConfigs
  );
  return chain(identity, signalAggregations);
};

/**
 * Creates an overview querys for the RideComfort alert type
 * Since the overview needs stats for 1 day, 30 days, 90 days, and 180 days,
 * we query for everything from 180 days back to now and process the
 * query results later to split them up by the desired time periods
 */
const alertOverviewSignalAggregation = (
  t: TFunction,
  dateInterval: DateInterval,
  vehicleCollectionDevice: VehicleCollectionDevice,
  alertScopeProps: AlertScopeProps,
  alertTypeConfig: AlertTypeConfig
): string[] => {


  // Get the pointId of this alert type for the TrainFormation's sensor
  const pointId = vehicleCollectionDevice[alertTypeConfig.alertTypeKey];

  // Get configured AlertGaugeByTimePeriod, either AlertGaugeByTimePeriod.today,
  // AlertGaugeByTimePeriod.week, or AlertGaugeByTimePeriod.month
  const alertGaugeTimePeriod: keyof AlertGaugeByTimePeriod = alertScopeProps.alertConfigProps.alertGaugeTimePeriod;


  // A DateIntervalDescription based on dateInterval
  const dateIntervalDescription: DateIntervalDescription = clsOrType<DateIntervalDescription>(CemitTypename.dateIntervalDescription, {
    label: '180 days',
    durationText: '180 days',
    duration: differenceInMinutes(dateInterval.end, dateInterval.start),
    dateInterval
  });

  // The alert levels of the AlertType
  const levels: (keyof AlertLevels)[] = keys(alertTypeConfig.alertLevelToAttribute);
  // The alert level keys, e.g. L0, L1, L2, L3
  const types: (keyof AlertLevels)[] = keys(
    alertTypeConfig.alertLevelToAttributeForTypeKeys ||
    alertTypeConfig.alertLevelToAttribute
  );

  // windows is a structure that tells us what window to use for the query based on the number of
  // seconds of dateInterval
  const {windows} =
    getAlertDateIntervals(
      t,
      dateIntervalDescription,
      alertGaugeTimePeriod,
      dateInterval,
      true
    );
  const unit = alertTypeConfig.unit;

  // Create signalAggregations for each level/type for the 180 day duration
  const signalAggregations: string[] = zipWith(
    (level, type) => {

      const [fromStr, toStr] = map(stringifyDate, [dateInterval.start, dateInterval.end]);
      const seconds = (dateInterval.end - dateInterval.start) / 1000;
      const windowToUse = find((win) => {
        return win.value > seconds;
      }, windows);

      return `${alertTypeConfig.labelShort}_${level}_Days180:signalsAggregation(
      where: { pointId:"${pointId}", type: "${type}", unit: ${unit} }
      aggregate: {
        from:"${fromStr}"
        to: "${toStr}"
        window: ${windowToUse.label}
      }
    ) {
      time
      count
    }`;
    },
    levels,
    types
  );

  return signalAggregations;
};



