import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import {CemitTypename} from 'types/cemitTypename.ts';
import {mergeTrainGroup} from 'appUtils/trainAppUtils/trainAppTypeMerging/trainGroupMerging.ts';
import {Activity} from 'types/userState/activity';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {TrainRunResponse} from 'types/trainRuns/trainRun';
import {TrainFormation} from 'types/trains/trainFormation';
import {completeLoadingStatus} from 'utils/loading/loadingStatusUtils.ts';
import {classifyObject} from 'appUtils/typeUtils/classifyObject.ts';
import {createEmptyRideComfortScopeProps} from 'classes/typeCrud/rideComfortScopePropsCrud.ts';
import {
  createTrainGroupActivityAsActive,
  createTrainGroupActivityAsInactive
} from 'classes/typeCrud/trainGroupActivityCrud.ts';
import {createEmptyRealtimeScopeProps} from 'classes/typeCrud/realtimeScopePropsCrud.ts';
import {pick} from 'ramda';

/**
 * Merges activity: { isActive: true, isVisible: true }
 * into TrainGroupOnlyTrainFormation
 * @param trainGroupOnlyTrainFormation
 */
export const activateTrainGroupOnlyTrainFormation = (
  trainGroupOnlyTrainFormation: TrainGroupOnlyTrainFormation
): TrainGroupOnlyTrainFormation => {
  return mergeTrainGroup<TrainGroupOnlyTrainFormation, TrainGroupOnlyTrainFormation>(
    trainGroupOnlyTrainFormation,
    {
      __typename: CemitTypename.trainGroupOnlyTrainFormation,
      // Default isActive and isVisible to true
      activity: createTrainGroupActivityAsActive(
        trainGroupOnlyTrainFormation.activity?.isActiveColor ?
          clsOrType<Activity>(CemitTypename.activity, {
            isActiveColor: trainGroupOnlyTrainFormation?.activity?.isActiveColor,
          }) :
          undefined
      )
    }
  );
};
export const deactivateTrainGroupOnlyTrainFormation = (
  trainGroupOnlyTrainFormation: TrainGroupOnlyTrainFormation
): TrainGroupOnlyTrainFormation => {
  return mergeTrainGroup<TrainGroupOnlyTrainFormation, TrainGroupOnlyTrainFormation>(
    trainGroupOnlyTrainFormation,
    {
      __typename: CemitTypename.trainGroupOnlyTrainFormation,
      // Default isActive and isVisible to true
      activity: clsOrType<Activity>(CemitTypename.activity, {
        isActive: false
      })
    }
  );
};

export const trainFormationResponseToTrainGroupOnlyTrainFormation = (
  trainRunResponse: TrainRunResponse
): TrainGroupOnlyTrainFormation => {
  return classifyObject<TrainGroupOnlyTrainFormation>(
    CemitTypename.trainGroupOnlyTrainFormation,
    {
      id: trainRunResponse.id,
      // These values are extract from the trainRun to complete the TrainGroup's interface
      trainFormation: clsOrType<TrainFormation>(CemitTypename.trainFormation, {
        id: trainRunResponse.id
      }),
      // Loading is complete is we reach this constructor
      // This error/retry would probably only be used in a case where the Api query failed and we use a
      // TrainGroupMinimized as a placeholder
      loadingStatus: completeLoadingStatus,
      sensorDataPoints: trainRunResponse.sensorDataPoints,
      alertScopeProps: createEmptyRideComfortScopeProps(),
      alertScopeSummaryProps: createEmptyRideComfortScopeProps(),
      activity: createTrainGroupActivityAsInactive()
    }
  );
};

export const createTrainGroupOnlyTrainFormationFromTrainFormation = (
  trainFormation: TrainFormation
): TrainGroupOnlyTrainFormation => {
  return classifyObject<TrainGroupOnlyTrainFormation>(
    CemitTypename.trainGroupOnlyTrainFormation,
    {
      localUpdateDate: new Date(),
      localUpdateVersion: 0,
      // TODO temp use of trainFormation.id for uniqueness until we have first class ids for TrainGroup
      id: trainFormation.id,
      trainFormation,
      // Loading is complete is we reach this constructor
      // This error/retry would probably only be used in a case where the Api query failed and we use a
      // TrainGroupMinimized as a placeholder
      loadingStatus: completeLoadingStatus,
      // Initially empty, set later by RideComfort
      alertScopeProps: createEmptyRideComfortScopeProps(),
      alertScopeSummaryProps: createEmptyRideComfortScopeProps(),
      alertGeojson: undefined,
      // Initially empty, set later by RealtimeTrain
      realtimeTrainScopeProps: createEmptyRealtimeScopeProps(),
      realtimeTrainGeojson: undefined,
      activity: createTrainGroupActivityAsInactive()
    }
  );
};
