import {WeekYear} from 'types/datetime/weekYear';
import {clsOrType, ts} from 'appUtils/typeUtils/clsOrType.ts';
import {
  TrainApiVisionRequestParams,
  TrainApiVisionRequestProps,
  VisionMeta,
  VisionMetaResponse,
} from 'types/vision/visionMeta';
import {trainDataSimpleDateTimeString} from 'utils/datetime/timeUtils.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {map} from 'ramda';
import {CemitTypename} from 'types/cemitTypename.ts';
import {addMonths, format, subMonths} from 'date-fns';
import {cemitApiGetUrl} from 'apis/cemitApis/apiConfig.ts';
import {visionApi} from 'apis/cemitApis/trainApi/visionApiImplementation.ts';
import {CemitApiVisionRoute} from 'apis/cemitApis/trainApi/visionApi.ts';
import {CemitApiRequest, CemitApiUrlResolver} from 'types/apis/cemitApi';

/**
 * Converts Vision/NearbyPictures response to VisionMeta[]
 * @param _visionMetas
 * @param error
 * @param request
 */
export const trainApiVisionNearbyPicturesResponseResolver = ({
  data: _visionMetas,
  error,
  request,
}: {
  data: VisionMetaResponse[];
  error: Error;
  request: CemitApiRequest<TrainApiVisionRequestProps, TrainApiVisionRequestProps>;
}): Perhaps<VisionMeta[]> => {
  if (_visionMetas?.data?.length == 0) {
    return [];
  }
  const weekYear = request.props.weekYear;
  return map((visionMetaOutput: VisionMetaResponse): VisionMeta => {
    return clsOrType<VisionMeta>(CemitTypename.visionMeta, {
      weekYear,
      trackDistanceInMeters: parseFloat(visionMetaOutput.trackDistanceInMeters),
      trackSectionId: visionMetaOutput.trackSectionId,
      pictureUrl: visionMetaOutput.pictureUrl,
      timestamp: visionMetaOutput.timestamp,
      cdcId: visionMetaOutput.cdcId,
      trackGeoPosition: visionMetaOutput.trackGeoPosition,
      geoPosition: visionMetaOutput.geoPosition,
    });
  }, _visionMetas?.data || []);
};
/**
 * Convert the given props to request params for Vision/NearbyPictures
 * @param trackDistanceInMeters
 * @param timestamp
 * @param trackSectionId
 * @param weekYear
 * @param meterDelta
 * @param latestClick TODO temp whether last click was forward or backward
 */
export const visionNearbyPicturesParamsFilter = ({
  trackDistanceInMeters,
  trackSectionId,
  weekYear,
  track_distance_meters_upper,
  track_distance_meters_lower,
}: {
  trackDistanceInMeters: number;
  trackSectionId: string;
  weekYear: WeekYear;
  track_distance_meters_upper: string;
  track_distance_meters_lower: string;
}) => {
  const startDate = weekYear.dateInterval.start;
  const endDate = weekYear.dateInterval.end;
  const params = ts<TrainApiVisionRequestParams>({
    track_section: trackSectionId,
    start_date: format(startDate, trainDataSimpleDateTimeString),
    end_date: format(endDate, trainDataSimpleDateTimeString),
    max_count: 100,
    track_distance_meters_point: trackDistanceInMeters.toString(),
    track_distance_meters_upper: track_distance_meters_upper.toString(),
    track_distance_meters_lower: track_distance_meters_lower.toString(),
  });

  return params;
};
