import React, {PropsWithChildren} from 'react';
import {Box} from '@mui/material';
import {omit} from 'ramda';

/**
 * Creates a span that matches the MUI TableCell format
 * Box is put on the outside so that Tooltips can wrap around then span
 * @props
 * @constructor
 */
const TableGroupOverviewCellSpan = (props: PropsWithChildren) => {
  return <Box>
    <span {...{
      className: 'MuiButtonBase-root MuiTableSortLabel-root',
      ...omit(['children'], props)
    }}>
      {props.children}
    </span>
  </Box>;
};
export default TableGroupOverviewCellSpan;
