import {TFunction} from 'i18next';
import {
  AlertOverviewByTimePeriod,
  AlertOverviewByTimePeriodPrevious,
  resolvePreviousAlertOverviewByTimePeriod
} from 'types/alerts/alertOverviewByTimePeriod.ts';
import {TableGroupOverviewTableColumn} from 'types/summaryPresentations/tableGroupOverviewTableColumn.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {clsOrTypes} from 'appUtils/typeUtils/clsOrTypes.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {
  TrainAppTrainDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup.ts';
import {
  trendOfAlertsForTrainGroup,
  rateOfChangePercentElement,
  resolveAlertRateOfChangeScoreForTimePeriod,
  resolveAlertRateOfChangeScoreForTimePeriodOrNoData,
  resolveCriticalAlertCount,
  resolveHoursInOperationElement,
  resolveNormalAlertPercentValueElement,
  resolvePercentNormalReadingsRateOfChangeElement,
  resolveTrainGroupOverviewTrendType,
  resolveTrainGroupOverviewTrendIconElement,
  TrainOverviewTrendTypeWithDiffAndIcon,
  getTrendQuaternaryAndDiff,
  getTrainOverviewTrendTypeWithDiffAndIcon
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/trainGroupOverviewUtils.ts';
import {createElement, ReactElement} from 'react';
import {Link, Stack, Tooltip} from '@mui/material';
import {resolveAlertScoreForTimePeriod, resolveOverviewAlertDataForTimePeriod} from 'appUtils/alertUtils/alertUtils.ts';
import {ts} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitFilterTrainFormationProps} from 'types/cemitFilters/cemitFilterTrainFormationProps.ts';
import {
  always,
  complement,
  cond,
  filter,
  ifElse,
  includes, is,
  join,
  length,
  map,
  mean,
  prop,
  sum,
  T,
  when,
  without
} from 'ramda';
import {FrontendView} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {addTrainFormationsToCemitFilter} from 'appUtils/cemitFilterUtils/cemitFilterTrainFormationUtils.ts';
import {TrendQuaternary, TrendQuaternaryAndDiff} from 'types/logic/trendQuaternary.ts';
import {TrainGroupClassification, TrainGroupClassificationEnum} from 'types/trainGroups/trainGroupClassification.ts';
import TableGroupOverviewCellSpan
  from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/TableGroupOverviewCellSpan.tsx';
import {GraphqlResponseDateIntervalOverviewAlertData} from 'types/alerts/alertMapData.ts';
import {lengthAsBoolean, roundWithoutNegativeZero, toArrayIfNot} from 'utils/functional/functionalUtils.ts';
import {translateUnlessNumber} from 'utils/translationUtils.ts';

/**
 * The column definitions for the TrainGroupOverviewTable. These, most importantly, include a resolver
 * for the value of each cell
 * @param t
 * @param alertOverviewByTimePeriod
 * @param trainGroupClassification
 */
export const trainGroupOverviewTableColumns = (
  t: TFunction,
  alertOverviewByTimePeriod: AlertOverviewByTimePeriod,
  trainGroupClassification: TrainGroupClassification
): TableGroupOverviewTableColumn[] => {
  const limitedTrainGroupClassificationIsActive: boolean = Boolean(trainGroupClassification?.sourceKey) &&
    (trainGroupClassification.sourceKey !== TrainGroupClassificationEnum.allTrains);

  const alertOverviewByTimePeriodPrevious: Perhaps<AlertOverviewByTimePeriodPrevious> = resolvePreviousAlertOverviewByTimePeriod(alertOverviewByTimePeriod);

  return clsOrTypes<TableGroupOverviewTableColumn>(CemitTypename.tableGroupOverviewColumn, [
    {
      id: 'trendIcon',
      label: 'trend',
      minWidth: 0,
      maxWidth: '40px',
      align: 'center',
      columnAndSortDescription: 'trendIconDescription',
      // Return a colored icon element to indicate improvement or worsening
      resolveValue: ({}: TrainAppTrainDependencyProps, trainGroup: TrainGroup) => {
        return resolveTrainGroupOverviewTrendIconElement(
          t,
          alertOverviewByTimePeriod,
          alertOverviewByTimePeriodPrevious,
          trainGroup
        );
      },
      // Return the trend diff for sorting
      resolveValueForSort: ({}: TrainAppTrainDependencyProps, trainGroup: TrainGroup): number => {
        // Sort by the change in trend, making NaN the lowest possible value
        return when<number, number>(
          isNaN,
          always(-Infinity),
          trendOfAlertsForTrainGroup(alertOverviewByTimePeriod, alertOverviewByTimePeriodPrevious, trainGroup).diff
        );
      },
      resolveValueForTrainGroupClassification: (_trainAppTrainDependencyProps: TrainAppTrainDependencyProps, trainGroupClassification: TrainGroupClassification): ReactElement => {

        const TrainOverviewTrendTypeWithDiffs: TrainOverviewTrendTypeWithDiffAndIcon[] = map(
          (trainGroup: TrainGroup) => {
            return resolveTrainGroupOverviewTrendType(
              alertOverviewByTimePeriod,
              alertOverviewByTimePeriodPrevious,
              trainGroup
            );
          },
          trainGroupClassification.trainGroups
        );
        const trainOverviewTrendTypeWithDiffsWithoutNaNs: TrainOverviewTrendTypeWithDiffAndIcon[] = filter(
          (trainOverviewTrendType: TrainOverviewTrendTypeWithDiffAndIcon) => {
            return !isNaN(trainOverviewTrendType.diff);
          },
          TrainOverviewTrendTypeWithDiffs
        );
        const averageDiff: number = roundWithoutNegativeZero(mean(map(prop('diff'), trainOverviewTrendTypeWithDiffsWithoutNaNs)));
        const trendQuaternaryAndDiff: TrendQuaternaryAndDiff = getTrendQuaternaryAndDiff(averageDiff);
        const trainOverviewTrendTypeWithDiffAndIcon: TrainOverviewTrendTypeWithDiffAndIcon = getTrainOverviewTrendTypeWithDiffAndIcon(trendQuaternaryAndDiff);
        // Resolve the icon and rotate between 0 and 180 degrees.
        // Up arrows get rotated between 0 and 90 degrees
        // Down arrows get rotated negatively between 180 and 90 degrees
        const rotate = cond([
          [
            (trainOverviewTrendTypeWithDiffAndIcon: TrainOverviewTrendTypeWithDiffAndIcon) => {
              return trainOverviewTrendTypeWithDiffAndIcon.diff > 0;
            },
            (trainOverviewTrendTypeWithDiffAndIcon: TrainOverviewTrendTypeWithDiffAndIcon) => {
              // The most positive the trend can be is 100, indicating 100% improvement
              // Subtract (diff fraction times 90) from 90 to get the number of degrees to rotate
              // from 0 toward 90
              return 90 - ((trainOverviewTrendTypeWithDiffAndIcon.diff / 100) * 90);
            }
          ],
          [
            (trainOverviewTrendTypeWithDiffAndIcon: TrainOverviewTrendTypeWithDiffAndIcon) => {
              return trainOverviewTrendTypeWithDiffAndIcon.diff < 0;
            },
            (trainOverviewTrendTypeWithDiffAndIcon: TrainOverviewTrendTypeWithDiffAndIcon) => {
              // The most negative the trend can be is 100, indicating 100% worsening
              // Rotate negatively from 180 (down) to 90 as the diff goes from -100 to 0
              return -(((100 + trainOverviewTrendTypeWithDiffAndIcon.diff) / 100) * 90);
            }
          ],
          [T, always(undefined)]

        ])(trainOverviewTrendTypeWithDiffAndIcon);
        return createElement(
          Tooltip, {
            arrow: true,
            title: join(' ', map(
              tooltipLabel => {
                return translateUnlessNumber(t, tooltipLabel)
              },
              toArrayIfNot(trainOverviewTrendTypeWithDiffAndIcon.tooltipLabels))
            )
          },
          trainOverviewTrendTypeWithDiffAndIcon.icon({
            sx: rotate ? {
              transform: `rotate(${rotate}deg)`
            } : {}
          })
        );
      }
    },
    {
      id: 'trainAndClasses',
      label: limitedTrainGroupClassificationIsActive ? 'trainsOfClass' : 'trains',
      columnAndSortDescription: 'trainAndClassesDescription',
      resolveValue: ({organizationProps}: TrainAppTrainDependencyProps, trainGroup: TrainGroup) => {
        return trainGroup.localizedName(organizationProps!.organization!.timezoneStr);
      },
      /*
        For the top table row we show the count of trains in the current classification, not a performance indicator
      */
      resolveValueForTrainGroupClassification: (_trainAppTrainDependencyProps: TrainAppTrainDependencyProps, trainGroupClassification: TrainGroupClassification): ReactElement => {
        // Complaining that TableGroupOverviewCellSpan can't be wrapped
        // return createElement(
        //   Tooltip, {
        //     arrow: true,
        //     title: t('trainCountInClass')
        //   },
        const count = length(trainGroupClassification.trainGroups);
        return createElement(TableGroupOverviewCellSpan, {}, `${count} ${t(count > 1 ? 'trains' : 'train')}`);
        //);
      }
    },
    {
      id: 'rideComfort',
      label: 'rideComfort',
      minWidth: 60,
      columnAndSortDescription: 'rideComfortDescription',
      align: 'right',
      resolveValue: ({appProps}: TrainAppTrainDependencyProps, trainGroup: TrainGroup): string => {
        // Call the resolver matching appProps.trainGroupOverviewViewType
        // The overview gets the percent of normal alerts in the last day and the percent change from the previous day
        return resolveNormalAlertPercentValueElement(
          t,
          alertOverviewByTimePeriod,
          trainGroup
        );
      },
      /*
        Show the average ride comfort percent for all TrainGroups of the classification
       */
      resolveValueForTrainGroupClassification: (_trainAppTrainDependencyProps: TrainAppTrainDependencyProps, trainGroupClassification: TrainGroupClassification): ReactElement => {
        const overviewPercents: number[] = map(
          (trainGroup: TrainGroup) => {
            return resolveAlertScoreForTimePeriod(alertOverviewByTimePeriod, trainGroup);
          },
          trainGroupClassification.trainGroups
        );
        const overviewPerecentsWithoutNaNs = filter(
          complement(isNaN),
          overviewPercents
        );

        // If there are any values, mean and round
        const averageOverviewPercentWithUnit = ifElse(
          lengthAsBoolean,
          (overviewPercentsWithoutNaNs: number[]) => {
            const averageOverviewPercent: number = roundWithoutNegativeZero(mean(overviewPercentsWithoutNaNs));
            return `${averageOverviewPercent.toFixed(0)}%`;
          },
          always(t('noData'))
        )(overviewPerecentsWithoutNaNs);

        return createElement(Tooltip, {
            arrow: true,
            title: t('percentNormalReadings')
          },
          createElement(Stack, {
              spacing: 0.5,
              direction: 'column',
              sx: {justifyContent: 'flex-end', minWidth: '40px'}
            },
            createElement(TableGroupOverviewCellSpan, {key: 'overviewPercent'}, averageOverviewPercentWithUnit)
          )
        );
      },
      // Get the numeric percent or NaN for sorting
      resolveValueForSort: ({appProps}: TrainAppTrainDependencyProps, trainGroup: TrainGroup): number => {
        return resolveAlertScoreForTimePeriod(alertOverviewByTimePeriod, trainGroup);
      }
    },
    {
      id: 'rateOfChange',
      label: 'rateOfChange',
      minWidth: 60,
      columnAndSortDescription: 'rateOfChangeDescription',
      resolveValue: ({organizationProps}: TrainAppTrainDependencyProps, trainGroup: TrainGroup) => {
        return resolvePercentNormalReadingsRateOfChangeElement(t, alertOverviewByTimePeriod, alertOverviewByTimePeriodPrevious, trainGroup);
      },
      // Get the numeric percent or NaN for sorting
      resolveValueForSort: ({}: TrainAppTrainDependencyProps, trainGroup: TrainGroup): number => {
        const diff: number = alertOverviewByTimePeriodPrevious ? resolveAlertRateOfChangeScoreForTimePeriodOrNoData(t, alertOverviewByTimePeriod, alertOverviewByTimePeriodPrevious, trainGroup) : NaN;
        if (isNaN(diff)) {
          return -Infinity;
        }
        return diff + 0;
      },
      resolveValueForTrainGroupClassification: (_trainAppTrainDependencyProps: TrainAppTrainDependencyProps, trainGroupClassification: TrainGroupClassification): ReactElement => {
        const rateOfChanges: number[] = map(
          (trainGroup: TrainGroup) => {
            return resolveAlertRateOfChangeScoreForTimePeriod(alertOverviewByTimePeriod, alertOverviewByTimePeriodPrevious, trainGroup);
          },
          trainGroupClassification.trainGroups
        );
        const rateOfChangeWithoutNans: number[] = filter(
          complement(isNaN),
          rateOfChanges
        );
        const average: number = roundWithoutNegativeZero(mean(rateOfChangeWithoutNans));
        return rateOfChangePercentElement(
          t,
          alertOverviewByTimePeriod,
          alertOverviewByTimePeriodPrevious,
          !length(rateOfChangeWithoutNans),
          average
        );
      }
    },
    {
      id: 'numberOfCriticalAlerts',
      label: 'numberOfCriticalAlerts',
      minWidth: 60,
      columnAndSortDescription: 'numberOfCriticalAlertsDescription',
      resolveValue: ({}: TrainAppTrainDependencyProps, trainGroup: TrainGroup) => {
        return resolveCriticalAlertCount(alertOverviewByTimePeriod, trainGroup);
      },
      resolveValueForTrainGroupClassification: (_trainAppTrainDependencyProps: TrainAppTrainDependencyProps, trainGroupClassification: TrainGroupClassification): ReactElement => {
        const numberOfCriticalAlertsPerTrainGroup = map(
          (trainGroup: TrainGroup) => {
            return resolveCriticalAlertCount(alertOverviewByTimePeriod, trainGroup);
          },
          trainGroupClassification.trainGroups
        );
        const numberOfCriticalAlertsPerTrainGroupWithoutNans = filter(
          complement(isNaN),
          numberOfCriticalAlertsPerTrainGroup
        );
        const averageNumberOfCriticalAlerts: string = mean(numberOfCriticalAlertsPerTrainGroupWithoutNans).toFixed(1);
        const totalNumberOfCriticalAlerts: number = sum(numberOfCriticalAlertsPerTrainGroupWithoutNans);
        return createElement(TableGroupOverviewCellSpan,
          {key: 'numberOfCriticalAlerts'},
          join(' ', [`${t('averageAbbreviated')} ${averageNumberOfCriticalAlerts}`, `(${t('totalAbbreviated')} ${totalNumberOfCriticalAlerts})`])
        );
      }
    },
    {
      id: 'hoursInOperation',
      label: 'hoursInOperation',
      minWidth: 60,
      columnAndSortDescription: 'hoursInOperationDescription',
      resolveValue: ({}: TrainAppTrainDependencyProps, trainGroup: TrainGroup) => {
        return resolveHoursInOperationElement(
          t,
          alertOverviewByTimePeriod,
          trainGroup
        );
      },
      // Get the numeric percent or NaN for sorting
      resolveValueForSort: ({}: TrainAppTrainDependencyProps, trainGroup: TrainGroup): number => {
        const currentOverviewAlertData: GraphqlResponseDateIntervalOverviewAlertData = resolveOverviewAlertDataForTimePeriod(alertOverviewByTimePeriod, trainGroup);
        const count = currentOverviewAlertData.operationHours;
        if (isNaN(count)) {
          return -1;
        }
        return count;
      },
      resolveValueForTrainGroupClassification: (_trainAppTrainDependencyProps: TrainAppTrainDependencyProps, trainGroupClassification: TrainGroupClassification): ReactElement => {
        const hoursInOperationPerTrainGroup: number[] = map(
          (trainGroup: TrainGroup) => {
            const overviewPercent = resolveAlertScoreForTimePeriod(alertOverviewByTimePeriod, trainGroup);
            return isNaN(overviewPercent) ?
              undefined :
              resolveOverviewAlertDataForTimePeriod(alertOverviewByTimePeriod, trainGroup).operationHours;
          },
          trainGroupClassification.trainGroups
        );
        const hoursInOperationPerTrainGroupWithoutNaNs = filter(
          complement(isNaN),
          hoursInOperationPerTrainGroup
        );
        return createElement(Tooltip, {
            arrow: true,
            title: t('hoursInOperationDescription')
          },
          createElement(Stack, {
              spacing: 0.5,
              direction: 'column',
              sx: {justifyContent: 'flex-end', minWidth: '40px'}
            },
            createElement(TableGroupOverviewCellSpan, {key: 'hoursInOperation'},
              length(hoursInOperationPerTrainGroupWithoutNaNs) ?
                join(' ', [
                    `${t('averageAbbreviated')} ${mean(hoursInOperationPerTrainGroupWithoutNaNs).toFixed(1)}`,
                    `(${t('totalAbbreviated')} ${sum(hoursInOperationPerTrainGroupWithoutNaNs)})`,
                    t('hours')
                  ]
                ) :
                t('noData')
            )
          )
        );

      }
    },
    // {
    //   id: 'distanceCovered',
    //   label: 'distanceCovered',
    //   unit: 'km',
    //   minWidth: 60,
    //   columnAndSortDescription: 'distanceCoveredDescription',
    //   resolveValue: ({organizationProps}: TrainAppTrainDependencyProps, trainGroup: TrainGroup) => {
    //     return 'TBD';
    //   }
    // },
    // {
    //   id: 'averageSpeed',
    //   label: 'averageSpeed',
    //   unit: 'km/h',
    //   minWidth: 60,
    //   columnAndSortDescription: 'averageSpeedDescription',
    //   resolveValue: ({organizationProps}: TrainAppTrainDependencyProps, trainGroup: TrainGroup) => {
    //     return 'TBD';
    //   }
    // },
    {
      id: 'reviewDetails',
      label: 'reviewDetails',
      minWidth: 60,
      align: 'right',
      disableSort: true,
      resolveValue: ({appProps, trainProps}: TrainAppTrainDependencyProps, trainGroup: TrainGroup): ReactElement => {
        const cemitFilterWithTrainFormations =
          trainProps.trainGroupOnlyTrainFormationProps.cemitFilterWithTrainFormations;
        const cemitFilterTrainFormationProps = ts<CemitFilterTrainFormationProps>(
          {
            trainGroupOnlyTrainFormations:
            trainProps.trainGroupOnlyTrainFormationProps.trainGroupOnlyTrainFormations
          }
        );
        const isActive = includes(
          trainGroup,
          trainProps.trainGroupActivityProps!.activeTrainGroups!
        );
        const onClick = () => {
          // Remove FrontendView.forceTrainGroupOverview from the activeViews if present
          appProps.setActiveViews((activeViews: FrontendView[]): FrontendView[] => {
            return without(
              [FrontendView.forceTrainGroupOverview],
              activeViews
            );
          });
          if (!isActive) {
            // Active the TrainGroup
            addTrainFormationsToCemitFilter(
              cemitFilterWithTrainFormations!,
              trainProps.trainGroupOnlyTrainFormationProps?.setCemitFilterWithTrainFormations!,
              [trainGroup],
              cemitFilterTrainFormationProps
            );
          }
        };

        return createElement(Link, {
            component: 'button',
            variant: 'body2',
            onClick
          },
          appProps.t(isActive ? 'backToDetails' : 'reviewDetails')
        );
      },
      resolveValueForTrainGroupClassification: (_trainAppTrainDependencyProps: TrainAppTrainDependencyProps, trainGroupClassification: TrainGroupClassification): ReactElement => {
        return createElement(TableGroupOverviewCellSpan, {key: 'reviewDetails'}, '');
      }
    }
  ]);
};
