import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {addIndex, all, ascend, descend, includes, length, map, slice, sortWith, without} from 'ramda';
import {TrainGroup} from 'types/trainGroups/trainGroup.ts';
import {
  TrainAppTrainDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import LoaderWithText from 'components/loading/LoaderWithText.tsx';
import TrainGroupOverviewTable, {
  TrainGroupOverviewComponentProps
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/TrainGroupOverviewTable.tsx';
import {LoadingStatusEnum} from 'types/apis/loadingStatusEnum.ts';
import {findOrThrow} from 'utils/functional/functionalUtils.ts';
import {IconButton, Stack, Tooltip} from '@mui/material';
import {ArrowBack} from '@mui/icons-material';
import {FrontendView} from 'config/appConfigs/cemitAppConfigs/frontendConfig/frontendView.ts';
import {
  resolveTrainGroupOverviewTableColumnValue,
  resolveTrainGroupOverviewTableColumnValueForSort
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/trainGroupOverviewUtils.ts';
import {TableGroupOverviewTableColumn} from 'types/summaryPresentations/tableGroupOverviewTableColumn.ts';
import {
  TrainGroupOverviewColumnAttributes
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/trainGroupOverviewEnums.ts';
import {
  trainGroupOverviewTableColumns
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/trainGroupOverviewTableColumns.ts';
import TrainOverviewTrainGroupTableRow
  , {
  TrainOverviewTrainGroupTableRowProps
} from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/TrainOverviewTrainGroupTableRow.tsx';
import {
  useNotLoadingMemoResolveCurrentTrainGroupClassification
} from 'async/trainAppAsync/trainAppHooks/typeHooks/trainGroupHooks.ts';
import {TrainGroupClassification, TrainGroupClassificationEnum} from 'types/trainGroups/trainGroupClassification.ts';

/**
 * A TrainGroup overview, including alert trends
 * @param loading
 * @param trainProps
 * @constructor
 */
const TrainGroupOverviewTableContainer = (
  {
    loading,
    appProps,
    organizationProps,
    trainProps
  }: TrainAppTrainDependencyProps) => {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const trainGroupClassification: Perhaps<TrainGroupClassification> = useNotLoadingMemoResolveCurrentTrainGroupClassification(
    loading,
    trainProps.trainGroupOnlyTrainFormationProps.trainGroupClassifications,
    appProps.trainGroupClassificationSourceKey
  );
  const trainGroups: Perhaps<TrainGroup[]> = trainGroupClassification?.trainGroups;

  const allTrainGroupAlertSummaryPropsLoaded = useNotLoadingMemo(loading || !trainGroups,
    (trainGroups) => {
      return all(
        (trainGroup: TrainGroup) => {
          return trainGroup.alertScopeSummaryProps?.alertTrainGroupProps.alertGraphqlStatus == LoadingStatusEnum.complete;
        },
        trainGroups
      );
    },
    [trainGroups]
  );
  const anyLoading = loading || !allTrainGroupAlertSummaryPropsLoaded;

  // Get the TableGroupOverviewColumn[], passing the translation function for the names
  const columns: Perhaps<TableGroupOverviewTableColumn[]> = useNotLoadingMemo(
    loading,
    (t, alertOverviewByTimePeriod, trainGroupClassification ) => {
      return trainGroupOverviewTableColumns(
        t,
        alertOverviewByTimePeriod,
        trainGroupClassification
      );
    }, [appProps.t, appProps.alertOverviewByTimePeriod, trainGroupClassification] as const);

  // Slice the trainGroups to the page
  const sliceTrainGroups: Perhaps<TrainGroup[]> = useNotLoadingMemo(anyLoading || !trainGroups,
    (trainGroups, trainGroupOverviewSortDirection, trainGroupOverviewOrderBy, page, rowsPerPage, columns) => {
      const directionFunc = trainGroupOverviewSortDirection == 'asc' ? ascend : descend;

      // Find the active orderBy TableGroupOverviewTableColumn
      const column: TableGroupOverviewTableColumn = findOrThrow(
        (column: TableGroupOverviewTableColumn) => {
          return column.id === trainGroupOverviewOrderBy;
        },
        columns
      );

      const orderByFunc = (trainGroup: TrainGroup) => {
        // Resolve the TableCell value for the given column (TableGroupOverviewColumn) and row (TrainGroup)
        return resolveTrainGroupOverviewTableColumnValueForSort(
          {appProps, organizationProps, trainProps},
          trainGroup,
          column
        );
      };

      // Sort by the orderByFunc, either ascending or descending
      const sortTrainGroups = sortWith([directionFunc(orderByFunc)])(trainGroups);

      return slice<TrainGroup>(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
        sortTrainGroups
      );
    }, [trainGroups, appProps.trainGroupOverviewSortDirection, appProps.trainGroupOverviewOrderBy, page, rowsPerPage, columns] as const
  );

  // If FrontendView.forceTrainGroupOverview is in the active views, we want to highlight active TrainGroups
  const activeTrainGroups = trainProps.trainGroupActivityProps?.activeTrainGroups;

  // Take the sorted and slice TrainGroups to create the final table rows containing the TableCell for each column
  const trainGroupTableRows: Perhaps<JSX.Element[]> = useNotLoadingMemo(
    !sliceTrainGroups,
    (slicedTrainGroups: TrainGroup[]) => {
      return addIndex(map)(
        (trainGroup: TrainGroup, tabIndex: number) => {
          const componentProps = {
            activeTrainGroups,
            trainGroup,
            columns,
            tabIndex
          } as TrainOverviewTrainGroupTableRowProps;
          // Create a TableRow for each TrainGroup
          return <TrainOverviewTrainGroupTableRow key={trainGroup.id} {...{
            appProps,
            organizationProps,
            trainProps,
            componentProps
          }} />;
        }, slicedTrainGroups);
    }, [sliceTrainGroups] as const);

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: TrainGroupOverviewColumnAttributes
  ) => {
    const isAsc = appProps.trainGroupOverviewOrderBy === property && appProps.trainGroupOverviewSortDirection === 'asc';
    appProps.setTrainGroupOverviewSortDirection(isAsc ? 'desc' : 'asc');
    appProps.setTrainGroupOverviewOrderBy(property);
  };


  const componentProps: Perhaps<TrainGroupOverviewComponentProps> = useNotLoadingMemo(anyLoading, (): TrainGroupOverviewComponentProps => {
    return {
      trainGroups,
      trainGroupTableRows,
      page,
      rowsPerPage,
      handleChangePage,
      handleChangeRowsPerPage,
      columns,
      handleRequestSort
    } as TrainGroupOverviewComponentProps;
  }, [trainGroups, trainGroupTableRows]);


  return anyLoading ?
    <LoaderWithText key="loader" {...{text: 'loadingTrainGroupOverview'}} /> :
    <Stack key="topStack" {...{
      spacing: 0.5, sx: {
        minWidth: 0,
        minHeight: 0,
        flex: 1,
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        height: '100%'
      }
    }}>
      <TrainGroupOverviewTable key="TrainGroupOverviewTable" {...{
        appProps,
        organizationProps,
        trainProps,
        componentProps: componentProps!
      }} />;
    </Stack>;
};
export default TrainGroupOverviewTableContainer;


