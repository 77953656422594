import React from "react";
import MPK_HEADER from "../../../../assets/images/trafficsim/documentation/MPK_Header.png"
import MENU_IMAGE from "../../../../assets/images/trafficsim/documentation/im_menu.png"
import SEARCH_IMAGE from "../../../../assets/images/trafficsim/documentation/im_search.png"
import THEME_IMAGE from "../../../../assets/images/trafficsim/documentation/im_theme.png"
import ORG_IMAGE from "../../../../assets/images/trafficsim/documentation/im_organisation.png"
import FEED_POINT_LIST_IMAGE from "../../../../assets/images/trafficsim/documentation/im_feedpoint_list.png"
import STATE_BOX_IMAGE from "../../../../assets/images/trafficsim/documentation/im_state_box.png"
import CREATE_RESTRICTION_IMAGE from "../../../../assets/images/trafficsim/documentation/im_create_restriction.png"
import ACTUAL_RESTRICTION_IMAGE from "../../../../assets/images/trafficsim/documentation/im_actual_restriction.png"
import INCOMING_RESTRICTION_IMAGE from "../../../../assets/images/trafficsim/documentation/im_incoming_restriction.png"
import LOG_QUERY_IMAGE from "../../../../assets/images/trafficsim/documentation/im_log_query.png"
import RESERVE_FEEDPOINT_IMAGE from "../../../../assets/images/trafficsim/documentation/im_reserve_feedpoint.png"
import NORMAL_FEEDPOINT_IMAGE from "../../../../assets/images/trafficsim/documentation/im_normal_feedpoint.png"
import STATION_IMAGE from "../../../../assets/images/trafficsim/documentation/im_normal_feedpoint.png"
import {LoadingButton} from "@mui/lab";
import {Close} from "@mui/icons-material";
import {Stack} from "@mui/material";

type ManualAbstractFunc = () => void
export interface IManual {
    onClose: ManualAbstractFunc
    theme: any,
}
export function Manual({onClose, theme }: IManual) {

    return (
        <div style={{width: '100%', height: '100vh', overflowY: "scroll", backgroundColor: "white"}}>
            <Stack direction="row-reverse"
                   position="fixed"
                   color="primary"
                   style={{margin: 8, marginRight: theme.spacing(1), marginTop: '40px', right: 0, float: "right",}}
                   spacing={1}>
                <LoadingButton
                    sx={{
                        color: theme.palette.map.white,
                        ":hover": {
                            bgcolor: theme.palette.action.active,
                        },
                        backgroundColor: theme.palette.action.active,
                        borderColor: theme.palette.map.white
                    }}
                    onClick={() => onClose()}
                    loading={false}
                    endIcon={<Close />}
                    loadingPosition="end"
                    variant="outlined">
                    <span>Close</span>
                </LoadingButton>
            </Stack>
            <div style={{
                margin: ' 2em auto',
                height: "auto",
                maxWidth: '900px',
                backgroundColor: 'white',
                color: 'rgb(55, 53, 47)',
                overflowY: "scroll",
            }}>
                <img src={MPK_HEADER} style={{...baseStyle.page_cover_image}}/>
                <div style={{...baseStyle.page_header_icon, ...baseStyle.page_header_icon_with_cover}}>
                    <img
                        style={{
                            display: 'inline-block',
                            maxWidth: '1.2em',
                            maxHeight: '1.2em',
                            textDecoration: 'none',
                            verticalAlign: 'text-bottom',
                            marginRight: '0.5em',
                            borderRadius: '3px',
                        }} src="https://www.notion.so/icons/info-alternate_blue.svg"/>
                </div>
                <div style={{
                    fontSize: '2.5rem',
                    fontWeight: '700',
                    marginTop: 0,
                    marginBottom: '0.75em'
                }}>
                    <h1 style={{
                        fontSize: '2.5rem',
                        marginTop: '1.875rem',
                        letterSpacing: '-0.01em',
                        lineHeight: '1.2',
                        fontWeight: '700',
                        marginBottom: 0,
                    }}>Användarmanual</h1>
                </div>

                <div>
                    <nav style={{...baseStyle.block_color_gray}}>
                        <div style={{...baseStyle.table_of_contents_item}}>
                            <a className="table_of_contents-link" style={{...baseStyle.table_of_contents_link}}
                               href="#menyval">Menyval</a>
                        </div>
                        <div style={{...baseStyle.table_of_contents_item, ...baseStyle.table_of_contents_indent_1,}}>
                            <a style={{...baseStyle.table_of_contents_link}}
                               href="#sökruta">Sökruta</a>
                        </div>
                        <div style={{...baseStyle.table_of_contents_item, ...baseStyle.table_of_contents_indent_1,}}>
                            <a style={{...baseStyle.table_of_contents_link}}
                               href="#väljare">Väljare
                                för mörk eller ljus sida</a>
                        </div>
                        <div style={{...baseStyle.table_of_contents_item, ...baseStyle.table_of_contents_indent_1,}}>
                            <a style={{...baseStyle.table_of_contents_link}}
                               href="#kugghjulet">Kugghjulet</a>
                        </div>
                        <div style={{...baseStyle.table_of_contents_item, ...baseStyle.table_of_contents_indent_1,}}>
                            <a style={{...baseStyle.table_of_contents_link}}
                               href="#matarpunkter">Matarpunkter</a>
                        </div>
                        <div style={{...baseStyle.table_of_contents_item, ...baseStyle.table_of_contents_indent_1,}}>
                            <a style={{...baseStyle.table_of_contents_link}}
                               href="#begränsningar">Begränsningar</a>
                        </div>
                        <div style={{...baseStyle.table_of_contents_item, ...baseStyle.table_of_contents_indent_1,}}>
                            <a style={{...baseStyle.table_of_contents_link}}
                               href="#aktuella">Aktuella</a>
                        </div>
                        <div style={{...baseStyle.table_of_contents_item, ...baseStyle.table_of_contents_indent_1,}}>
                            <a style={{...baseStyle.table_of_contents_link}}
                               href="#kommande">Kommande</a>
                        </div>
                        <div style={{...baseStyle.table_of_contents_item, ...baseStyle.table_of_contents_indent_1,}}>
                            <a style={{...baseStyle.table_of_contents_link}}
                               href="#historik">Historik</a>
                        </div>

                        <div style={{...baseStyle.table_of_contents_item,}}>
                            <a style={{...baseStyle.table_of_contents_link}}
                               href="#kartan">Kartan</a>
                        </div>
                        <div style={{...baseStyle.table_of_contents_item, ...baseStyle.table_of_contents_indent_1,}}>
                            <a style={{...baseStyle.table_of_contents_link}}
                               href="#amatarpunkt">Ange/ända
                                status på en matarpunkt</a>
                        </div>
                        <div style={{...baseStyle.table_of_contents_item, ...baseStyle.table_of_contents_indent_1,}}>
                            <a style={{...baseStyle.table_of_contents_link}}
                               href="#areservmatarpunkt">Ange/ändra
                                status på en reservmatarpunkt</a>
                        </div>
                        <div style={{...baseStyle.table_of_contents_item, ...baseStyle.table_of_contents_indent_1,}}>
                            <a style={{...baseStyle.table_of_contents_link}}
                               href="#astation">Ange/ändra
                                status på en station</a>
                        </div>
                        <div style={{...baseStyle.table_of_contents_item, ...baseStyle.table_of_contents_indent_1,}}>
                            <a style={{...baseStyle.table_of_contents_link}}
                               href="#färger">Färger</a>
                        </div>
                        <div style={{...baseStyle.table_of_contents_item,}}>
                            <a style={{...baseStyle.table_of_contents_link}}
                               href="#användare">Användare</a>
                        </div>
                        <div style={{...baseStyle.table_of_contents_item, ...baseStyle.table_of_contents_indent_1,}}>
                            <a style={{...baseStyle.table_of_contents_link}}
                               href="#åtkomstnivåer">Åtkomstnivåer</a>
                        </div>
                    </nav>

                    <h1 style={{...baseStyle.h1}} id="menyval">Menyval</h1>
                    <figure style={{...baseStyle.image}}>
                        <img src={MENU_IMAGE}/>
                    </figure>
                    <p style={baseStyle.p}>Ovanför kartan finner du alla länkar för val av
                        funktioner, dessa är:</p>
                    <table style={{...baseStyle.simple_table, ...baseStyle.table}}>
                        <tbody>
                        <tr>
                            <td style={{...baseStyle.td, ...baseStyle.simple_table_td, width: '234px'}}>Sökruta</td>
                            <td style={{...baseStyle.td, ...baseStyle.simple_table_td, width: '467px'}}>Här söker du
                                efter matarpunkter och stationer
                            </td>
                        </tr>
                        <tr>
                            <td style={{...baseStyle.td, ...baseStyle.simple_table_td, width: '234px'}}>Väljare för mörk
                                eller ljus karta
                            </td>
                            <td style={{...baseStyle.td, ...baseStyle.simple_table_td, width: '467px'}}>Väljer mörkt
                                eller ljust bakgrund på kartan
                            </td>
                        </tr>
                        <tr>
                            <td style={{...baseStyle.td, ...baseStyle.simple_table_td, width: '234px'}}>Kugghjul</td>
                            <td style={{...baseStyle.td, ...baseStyle.simple_table_td, width: '467px'}}>
                                Under kugghjulet kan du se vad du är inloggad som och kan även logga ut från
                                matarpunktkartan
                            </td>
                        </tr>
                        <tr>
                            <td style={{...baseStyle.td, ...baseStyle.simple_table_td, width: '234px'}}>Länk -
                                Matarpunkter
                            </td>
                            <td style={{...baseStyle.td, ...baseStyle.simple_table_td, width: '467px'}}>
                                Två val. Matarpunkter och resevmatarpunkter. Dessa länkar leder till en utviksmeny på
                                höger sida.
                                Valet matarpunkter ger en överskådlig status på samtliga punkter samt reservmatarpunkter
                                ger en lista på vilka reserv matarpunkter som finns tillgängliga.
                            </td>
                        </tr>
                        <tr>
                            <td style={{...baseStyle.td, ...baseStyle.simple_table_td, width: '234px'}}>Länk -
                                Begränsningar
                            </td>
                            <td style={{...baseStyle.td, ...baseStyle.simple_table_td, width: '467px'}}>
                                Leder till en utviksmeny på höger sida där begränsningar kan administreras.
                            </td>
                        </tr>
                        <tr>
                            <td style={{...baseStyle.td, ...baseStyle.simple_table_td, width: '234px'}}>Lager</td>
                            <td style={{...baseStyle.td, ...baseStyle.simple_table_td, width: '467px'}}>
                                Här finns det möjlighet att välja vilken information man vill ska visas i kartan
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>

                <h2 style={{...baseStyle.h2}} id="sökruta">Sökruta</h2>
                <figure style={{...baseStyle.image}}>
                    <img src={SEARCH_IMAGE}/>
                </figure>
                <p style={{...baseStyle.p, ...baseStyle}}>Sökfunktionen filtrerar automatiskt ut resultaten i den
                    takt du skriver. När du funnit det du sökt efter klickar du resultatet i listan.</p>

                <h2 style={{...baseStyle.h2}} id="väljare">Väljare för mörk eller ljus sida</h2>
                <figure style={{...baseStyle.image}}>
                    <img src={THEME_IMAGE}/>
                </figure>
                <p style={{...baseStyle.p, ...baseStyle}}>Väljer om kartans bakgrund ska vara ljus eller mörk.</p>

                <h2 style={{...baseStyle.h2}} id="kugghjulet">Kugghjulet</h2>
                <figure style={{...baseStyle.image}}>
                    <img src={ORG_IMAGE}/>
                </figure>
                <p style={{...baseStyle.p, ...baseStyle}}>Här finns information om vilken organisation du tillhör
                    samt epost adress. Du kan även logga ut.</p>


                <h2 style={{...baseStyle.h2}} id="matarpunkter">Matarpunkter (Länk)</h2>
                <div style={{...baseStyle.column_list}}>
                    <div style={{...baseStyle.column, width: '33.333333333333336%'}}>
                        <figure style={{...baseStyle.column_first_child, ...baseStyle.image, textAlign: 'left'}}>
                            <a
                                href={FEED_POINT_LIST_IMAGE}>
                                <img style={{width: '280px'}}
                                     src={FEED_POINT_LIST_IMAGE}/>
                            </a>
                            <figcaption style={baseStyle.figcaption}>Översiktsbild</figcaption>
                        </figure>
                    </div>
                    <div style={{width: '33.33333333333333%'}} className="column">
                        <p style={{...baseStyle.p}}>En översikt och aktuell status på alla
                            matarpunkter.</p>
                        <p style={{...baseStyle.p}}>Vid klick på en matarpunkt ges möjligheten att
                            sätta status direkt. Man kan även sätta en notat om status.</p>
                        <p style={{...baseStyle.p}}>Tryck på spara så uppdateras kartan med ny
                            status.</p>
                        <p style={{...baseStyle.p}}>Klicka bort rutan vid krysset uppe till höger</p>
                    </div>
                    <div style={{...baseStyle.column_last_child, ...baseStyle.column, width: '33.333333333333336%'}}
                         className="column">
                        <figure className="image"><a
                            href={STATE_BOX_IMAGE}><img
                            style={{width: '284px'}}
                            src={STATE_BOX_IMAGE}/></a>
                            <figcaption style={{...baseStyle.figcaption}}>Möjlighet att ändra status och ge kommentar
                            </figcaption>
                        </figure>
                    </div>
                </div>


                <h2 style={baseStyle.h2} id="begränsningar">Begränsningar (Länk)</h2>
                <div style={baseStyle.column_list}>
                    <div style={{...baseStyle.column, width: '50%'}}>
                        <figure style={{...baseStyle.image, textAlign: 'left'}}><a
                            href={CREATE_RESTRICTION_IMAGE}><img
                            style={{width: '290px'}}
                            src={CREATE_RESTRICTION_IMAGE}/></a></figure>
                    </div>
                    <div style={{...baseStyle.column, width: '50%'}}>
                        <p style={{...baseStyle.p, ...baseStyle.column_first_child}}><strong>Lägg till
                            begränsning</strong> - I denna
                            ruta kan du specificera en ny begränsning.</p>
                        <ul style={{...baseStyle.ul}}>
                            <li style={{...baseStyle.ul___li, listStyleType: 'disc'}}><strong>Ange
                                begränsning</strong> finner du Avstängt spår,
                                Backförbud, Hastighetsbegränsning och Annan begränsning. Välj vad som är aktuellt för
                                ditt
                                ärende.
                            </li>
                        </ul>
                        <ul style={{...baseStyle.ul}}>
                            <li style={{...baseStyle.ul___li, listStyleType: 'disc'}}>P<strong>lats/riktning </strong>definerar
                                aktuellt spåravsnitt.
                            </li>
                        </ul>
                        <ul style={{...baseStyle.ul}}>
                            <li style={{...baseStyle.ul___li, listStyleType: 'disc'}}><strong>Berörda växlar</strong>.
                                Här kan man notera i fritext vilka
                                växlar som berörs av ärendet.
                            </li>
                        </ul>
                        <ul style={{...baseStyle.ul}}>
                            <li style={{...baseStyle.ul___li, listStyleType: 'disc'}}><strong>Orsak till
                                begränsning</strong>. Här anges orsaken till
                                ärendet.
                            </li>
                        </ul>
                        <ul style={{...baseStyle.ul}}>
                            <li style={{...baseStyle.ul___li, listStyleType: 'disc'}}><strong>Beräknad
                                tidsperiod</strong>. Ange tidsperioden ärendet
                                avser genom att antigen skriva in datumet manuellt eller att klicka på kalender ikonen
                                och
                                välja.
                            </li>
                        </ul>
                        <ul style={{...baseStyle.ul}}>
                            <li style={{...baseStyle.ul___li, listStyleType: 'disc'}}><strong>Tills
                                Vidare</strong> (checkbox) Välj denna om slutdatum ej
                                är definierad.
                            </li>
                        </ul>
                        <ul style={{...baseStyle.ul}}>
                            <li style={{...baseStyle.ul___li, listStyleType: 'disc'}}><strong>Begränsning inlagd på
                                begäran av</strong>. Beskrivning i
                                fritext om ärendet.
                            </li>
                        </ul>
                        <ul style={{...baseStyle.ul}}>
                            <li style={{...baseStyle.ul___li, listStyleType: 'disc'}}><strong>Notering</strong>. Annan
                                information relaterat till ärendet
                                i fritext.
                            </li>
                        </ul>
                        <ul style={{...baseStyle.ul}}>
                            <li style={{...baseStyle.ul___li, listStyleType: 'disc'}}>Val av <strong>Aktuell
                                begränsning</strong> eller är en <strong>Kommande
                                begränsning</strong>. Välj om begränsningen ska implementeras direkt eller är en
                                kommande.
                            </li>
                        </ul>
                        <p style={{...baseStyle.p, ...baseStyle.column_last_child}}>
                        </p>
                    </div>
                </div>


                <h2 style={{...baseStyle.h2}} id="aktuella">Aktuella begränsningar (länk)</h2>
                <div style={{...baseStyle.column_list}}>
                    <div style={{...baseStyle.column, width: '50%'}}>
                        <figure style={{
                            ...baseStyle.image, ...baseStyle.figure, ...baseStyle.column_first_child,
                            textAlign: 'left'
                        }}>
                            <a
                                href={ACTUAL_RESTRICTION_IMAGE}><img
                                style={{width: '281px'}}
                                src={ACTUAL_RESTRICTION_IMAGE}/></a></figure>
                    </div>
                    <div style={{...baseStyle.column, width: '50%'}}><p
                        style={{...baseStyle.column_first_child}}>Ger en översikt av aktuella begränsningar</p>
                        <p style={{...baseStyle.p, ...baseStyle.column_last_child}}>
                        </p></div>
                </div>


                <h2 style={{...baseStyle.h2}} id="kommande">Kommande begränsningar (länk)</h2>
                <div style={{...baseStyle.column_list}}>
                    <div style={{...baseStyle.column, width: '50%'}}>
                        <figure style={{
                            ...baseStyle.image, ...baseStyle.figure, ...baseStyle.column_first_child,
                            textAlign: 'left'
                        }}>
                            <a
                                href={INCOMING_RESTRICTION_IMAGE}><img
                                style={{width: '294px'}}
                                src={INCOMING_RESTRICTION_IMAGE}/></a></figure>
                    </div>
                    <div style={{...baseStyle.column, width: '50%'}}><p
                        style={{...baseStyle.column_first_child}}>Ger en översikt av kommande begränsningar</p>
                        <p style={{...baseStyle.p, ...baseStyle.column_last_child}}>
                        </p></div>
                </div>


                <h2 style={{...baseStyle.h2}} id="historik">Historik</h2>
                <figure style={{...baseStyle.image, ...baseStyle.figure}}><a
                    href={LOG_QUERY_IMAGE}>
                    <img style={{width: '708px'}}
                         src={LOG_QUERY_IMAGE}/></a>
                </figure>
                <p style={baseStyle.p}>Ger en översikt av historiska, avslutade
                    begränsningar.<br/>Välj tidsintervall ock klicka på förstoringsglaset (sök) för att visa
                    sökresultat. Den
                    runda pil ikonen är för att uppdatera sökresultaten.<br/></p>


                <h1 style={{...baseStyle.h1}} id="kartan">Kartan</h1>
                <h2 style={{...baseStyle.h2}} id="amatarpunkt">Ange/ända status på en matarpunkt</h2>
                <div style={{...baseStyle.column_list}}>
                    <div style={{...baseStyle.column, width: '50%'}}>
                        <figure style={{...baseStyle.figure, ...baseStyle.image, textAlign: 'left'}}><a
                            href={NORMAL_FEEDPOINT_IMAGE}><img
                            style={{width: '303px'}}
                            src={NORMAL_FEEDPOINT_IMAGE}/></a></figure>
                    </div>
                    <div style={{...baseStyle.column, width: '50%'}}>
                        <p style={{...baseStyle.p, ...baseStyle.column_first_child}}>Leta upp matarpunkten som är
                            aktuell för
                            ändringen antigen via ett direkt klick i kartan eller via sökfunktionen.</p>
                        <p style={{...baseStyle.p}}>Sätt status <strong>Spänningssatt </strong>eller
                            <strong> Ej spänningssatt</strong> och skriv en notering (fritext) om ändringen. Tryck spara
                            för att
                            genomföra ändringen eller avbryt för att avbryta.</p>
                        <p style={{...baseStyle.p, ...baseStyle.column_last_child}}>Se ändringen i kartan</p></div>
                </div>


                <h2 style={{...baseStyle.h2}} id="areservmatarpunkt">Ange/ändra status på en reservmatarpunkt</h2>
                <div style={{...baseStyle.column_list}}>
                    <div style={{...baseStyle.column, width: '50%'}}>
                        <figure style={{...baseStyle.figure, ...baseStyle.image, textAlign: 'left'}}><a
                            href={RESERVE_FEEDPOINT_IMAGE}><img
                            style={{width: '292px'}}
                            src={RESERVE_FEEDPOINT_IMAGE}/></a></figure>
                    </div>
                    <div style={{...baseStyle.column, width: '50%'}}>
                        <p style={{...baseStyle.p, ...baseStyle.column_first_child}}>Förfarandet för att ändra status på
                            en
                            reservmatarpunkt är lika som en normal matarpunkt med ett undantag.</p>
                        <p style={{...baseStyle.p,}}>En reservmatarpunkt måste aktiveras. Detta på
                            grund av det faktum att man aldrig kan ha fler än två matarpunkter aktiverade samtidigt.</p>
                        <p style={{...baseStyle.p, ...baseStyle.column_last_child}}>Således, deaktivera först en
                            matarpunkt som är
                            relaterad till den aktuella matarsträckan. Efter detta går det bra att ändra status på
                            reservamatarpunkten. Klicka på <strong>Aktivera </strong>för att genomföra ändringen</p>
                    </div>
                </div>


                <h2 style={{...baseStyle.h2}} id="astation">Ange/ändra status på en station</h2>
                <div style={{...baseStyle.column_list}}>
                    <div style={{...baseStyle.column, width: '50%'}}>
                        <figure style={{...baseStyle.figure, ...baseStyle.image, textAlign: 'left'}}><a
                            href={STATION_IMAGE}><img
                            style={{width: '306px'}}
                            src={STATION_IMAGE}/></a></figure>
                    </div>
                    <div style={{...baseStyle.column, width: '50%'}}>
                        <p style={{...baseStyle.p, ...baseStyle.column_first_child}}>Förfarandet att ändra status på en
                            station är
                            densamma som på en matarpunkt. </p>
                        <p style={{...baseStyle.p,}}>Genom att ej spänningssätta en hel station
                            deaktiveras samtliga matarpunkter som är knutna till denna stations samtidigt.</p>
                        <p style={{...baseStyle.p, ...baseStyle.column_last_child}}></p>
                    </div>
                </div>

                <h2 style={{...baseStyle.h2}} id="färger">Färger</h2>
                <table style={{...baseStyle.table, ...baseStyle.simple_table}}>
                    <tbody>
                    <tr>
                        <td style={{...baseStyle.simple_table_td, ...baseStyle.td}}>Matarpunkt</td>
                        <td style={{...baseStyle.simple_table_td, ...baseStyle.td, width: '109px'}}>Gul</td>
                        <td style={{...baseStyle.simple_table_td, ...baseStyle.td, width: '450px'}}>Ej spänningssatt
                            (ytterligare en matarpunkt är aktiv)
                        </td>
                    </tr>
                    <tr>
                        <td style={{...baseStyle.simple_table_td, ...baseStyle.td}}>Matarpunkt</td>
                        <td style={{...baseStyle.simple_table_td, ...baseStyle.td, width: '109px'}}>Röd</td>
                        <td style={{...baseStyle.simple_table_td, ...baseStyle.td, width: '450px'}}>Samtliga
                            matarpunkter som är kopplademot det aktuella
                            spåravsnittet är inte spänningsatta.
                        </td>
                    </tr>
                    <tr>
                        <td style={{...baseStyle.simple_table_td, ...baseStyle.td}}>Station</td>
                        <td style={{...baseStyle.simple_table_td, ...baseStyle.td, width: '109px'}}>Röd</td>
                        <td style={{...baseStyle.simple_table_td, ...baseStyle.td, width: '450px'}}>Alla matarpunkter är
                            inte spänningssatta.
                        </td>
                    </tr>
                    </tbody>
                </table>

                <h1 style={{...baseStyle.h1}} id="användare">Användare</h1>
                <h2 style={{...baseStyle.h2}} id="åtkomstnivåer">Åtkomstnivåer</h2>
                <p style={{...baseStyle.p}}>Det finns tre möjliga privilegier knutna till ett konto
                    för matarpunktkartan.</p>
                <table style={{...baseStyle.simple_table}}>
                    <tbody>
                    <tr>
                        <td style={{...baseStyle.td}}>Admin</td>
                        <td style={{...baseStyle.td, width: '561px'}}>Ger full åtkomst. Information representerar
                            verklig status.
                            Alla ändringar sparas till databas.
                        </td>
                    </tr>
                    <tr>
                        <td style={{...baseStyle.td}}>User</td>
                        <td style={{...baseStyle.td, width: '561px'}}>Ger åtkomst till alla funktioner, men ändringar
                            skrivs inte
                            till databasen. Vid utloggning eller stängning av fönster/webbläsare så går ändrad status
                            förlorad.
                        </td>
                    </tr>
                    <tr>
                        <td style={{...baseStyle.td}} className="">Guest</td>
                        <td style={{...baseStyle.td, width: '561px'}}>Gäst access. Kan se men inte ändra status.</td>
                    </tr>
                    </tbody>
                </table>

            </div>
        </div>
    );
}

const baseStyle = {
    body: {
        lineHeight: "1.5",
        whiteSpace: "pre-wrap"
    },
    a: {
        color: "inherit",
        textDecoration:
            "underline"
    },
    block_color_gray: {
        color: "rgba(120, 119, 116, 1)",
        fill:
            "rgba(120, 119, 116, 1)"
    },
    a_visited: {
        color: "inherit",
        textDecoration:
            "underline"
    }
    ,
    pdf_relative_link_path: {
        fontSize: "80%",
        color:
            "#444"
    }
    ,
    h1: {
        letterSpacing: '-0.01em',
        lineHeight: '1.2',
        fontWeight: '600',
        marginbBottom: '0',
        fontSize: "1.875rem",
        marginTop:
            "1.875rem"
    }
    ,
    h2: {
        letterSpacing: '-0.01em',
        lineHeight: '1.2',
        fontWeight: '600',
        marginbBottom: '0',
        fontSize: "1.5rem",
        marginTop:
            "1.5rem"
    }
    ,
    h3: {
        letterSpacing: '-0.01em',
        lineHeight: '1.2',
        fontWeight: '600',
        marginbBottom: '0',
        fontSize: "1.25rem",
        marginTop:
            "1.25rem"
    }
    ,
    page_title: {
        fontSize: "2.5rem",
        fontWeight:
            "700",
        marginTop:
            "0",
        marginBottom:
            "0.75em"
    },
    figure: {
        margin: "1.25em 0",
        pageBreakInside:
            "avoid"
    }
    ,
    figcaption: {
        opacity: "0.5",
        fontSize:
            "85%",
        marginTop:
            "0.5em"
    }
    ,
    mark: {
        backgroundColor: "transparent"
    }
    ,
    indented: {
        paddingLeft: "1.5em"
    }
    ,
    hr: {
        background: "transparent",
        display:
            "block",
        width:
            "100%",
        height:
            "1px",
        visibility:
            "visible",
        border:
            "none",
        borderBottom:
            "1px solid rgba(55, 53, 47, 0.09)"
    }
    ,
    img: {
        maxWidth: "100%"
    }
    ,
    collection_content: {
        fontSize: "0.875rem"
    }
    ,
    column_list: {
        display: "flex",
        justifyContent:
            "space-between"
    }
    ,
    column: {
        padding: "0 1em"
    }
    ,
    column_first_child: {
        paddingLeft: "0"
    }
    ,
    column_last_child: {
        paddingRight: "0"
    }
    ,
    table_of_contents_item: {
        display: "block",
        fontSize:
            "0.875rem",
        lineHeight:
            "1.3",
        padding:
            "0.125rem"
    }
    ,
    table_of_contents_indent_1: {
        marginLeft: "1.5rem"
    }
    ,
    table_of_contents_indent_2: {
        marginLeft: "3rem"
    }
    ,
    table_of_contents_indent_3: {
        marginLeft: "4.5rem"
    }
    ,
    table_of_contents_link: {
        textDecoration: "none",
        width: 'auto',
        opacity:
            "0.7",
        borderBottom:
            "1px solid rgba(55, 53, 47, 0.18)"
    }
    ,
    table: {
        border: '1px solid rgba(55, 53, 47, 0.09)',
        borderCollapse: 'collapse',
        borderLeft: "none",
        borderRight:
            "none",

    }
    ,
    th: {
        border: '1px solid rgba(55, 53, 47, 0.09)',
        borderCollapse: 'collapse',
        color: "rgba(55, 53, 47, 0.6)"

    }
    ,
    td: {
        border: '1px solid rgba(55, 53, 47, 0.09)',
        borderCollapse: 'collapse',
        fontWeight: "normal",
        padding:
            "0.25em 0.5em",
        lineHeight:
            "1.5",
        minHeight:
            "1.5em",
        textAlign:
            "left"
    }
    ,
    ol: {
        margin: 0,
        marginBlockStart: '0.6em',
        marginBlockEnd: '0.6em',
        paddingInlineStart: "1.6em"
    }
    ,
    ul: {
        margin: 0,
        marginBlockStart: '0.6em',
        marginBlockEnd: '0.6em',
        paddingInlineStart: "1.7em"
    }
    ,
    li___ol_first_child: {
        marginBlockStart: "0.6em"
    }
    ,
    li___ul_first_child: {
        marginBlockStart: "0.6em"
    }
    ,
    ul___li: {
        paddingLeft: "0.1em"
    }
    ,
    ul_to_do_list: {
        paddingInlineStart: "0"
    }
    ,
    ul_to_do_list___li: {
        listStyle: "none"
    }
    ,
    to_do_children_checked: {
        textDecoration: "line-through",
        opacity:
            "0.375"
    }
    ,
    ul_toggle___li: {
        listStyle: "none"
    }
    ,
    ol___li: {
        paddingLeft: "0.2em"
    }
    ,
    mono_ol: {
        paddingInlineStart: "2em"
    }
    ,
    mono_ol___li: {
        textIndent: "-0.4em"
    }
    ,
    toggle: {
        paddingInlineStart: "0em",
        listStyleType:
            "none"
    }
    ,
    toggle___li___details: {
        paddingLeft: "1.7em"
    }
    ,
    toggle___li___details___summary: {
        marginLeft: "-1.1em"
    }
    ,
    selected_value: {
        display: "inline-block",
        padding:
            "0 0.5em",
        background:
            "rgba(206, 205, 202, 0.5)",
        borderRadius:
            "3px",
        marginRight:
            "0.5em",
        marginTop:
            "0.3em",
        marginBottom:
            "0.3em",
        whiteSpace:
            "nowrap"
    }
    ,
    collection_title: {
        display: "inline-block",
        marginRight:
            "1em"
    }
    ,
    page_description: {
        marginBottom: "2em"
    }
    ,
    simple_table: {
        marginTop: "1em",
        fontSize:
            "0.875rem",
        emptyCells:
            "show"
    }
    ,
    simple_table_td: {
        height: "29px",
        minWidth:
            "120px"
    }
    ,
    simple_table_th: {
        height: "29px",
        minWidth:
            "120px"
    }
    ,
    simple_table_header_color: {
        background: "rgb(247, 246, 243)",
        color:
            "black"
    }
    ,
    simple_table_header: {
        fontWeight: "500"
    }
    ,
    time: {
        opacity: "0.5"
    }
    ,
    icon: {
        display: "inline-block",
        maxWidth:
            "1.2em",
        maxHeight:
            "1.2em",
        textDecoration:
            "none",
        verticalAlign:
            "text-bottom",
        marginRight:
            "0.5em"
    }
    ,
    img_icon: {
        borderRadius: "3px"
    }
    ,
    user_icon: {
        width: "1.5em",
        height:
            "1.5em",
        borderRadius:
            "100%",
        marginRight:
            "0.5rem"
    }
    ,
    user_icon_inner: {
        fontSize: "0.8em"
    }
    ,
    text_icon: {
        border: "1px solid #000",
        textAlign:
            "center"
    }
    ,
    page_cover_image: {
        display: "block",
        objectFit:
            "cover",
        width:
            "100%",
        maxHeight:
            "30vh"
    }
    ,
    page_header_icon: {
        fontSize: "3rem",
        marginBottom:
            "1rem"
    }
    ,
    page_header_icon_with_cover: {
        marginTop: "-0.72em",
        marginLeft:
            "0.07em"
    }
    ,
    page_header_icon_img: {
        borderRadius: "3px"
    }
    ,
    link_to_page: {
        margin: "1em 0",
        padding:
            "0",
        border:
            "none",
        fontWeight:
            "500"
    }
    ,
    p____user: {
        opacity: "0.5"
    }
    ,
    td____user: {
        whiteSpace: "nowrap"
    }
    ,
    td___time: {
        whiteSpace: "nowrap"
    }
    ,
    input_type__checkbox: {
        transform: "scale(1.5)",
        marginRight:
            "0.6em",
        verticalAlign:
            "middle"
    }
    ,
    p: {
        marginTop: "0.5em",
        marginBottom:
            "0.5em"
    }
    ,
    image: {
        border: "none",
        margin:
            "1.5em 0",
        padding:
            "0",
        borderRadius:
            "0",
        textAlign:
            "center"
    }
}
