import {useNotLoadingEffect} from 'utils/hooks/useMemoHooks.ts';
import {useCemitApiSwrResolveData} from 'async/cemitAppAsync/cemitAppHooks/cemitApiHooks/apiResolverHooks.ts';
import {OrganizationLoaded} from '../../../../types/organizations/organization.ts';
import {StateSetter} from '../../../../types/hookHelpers/stateSetter';
import {head, propOr, equals} from 'ramda';
import {CemitFilter} from '../../../../types/cemitFilters/cemitFilter.d.ts';
import {extractDateIntervals} from '../../../../appUtils/cemitFilterUtils/cemitFilterDateIntervalUtils.ts';
import {Perhaps} from '../../../../types/typeHelpers/perhaps';
import {setListIfGivenPropsChanged} from '../../../../utils/hooks/setterUtils.ts';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import {TrainGroupsGroupingCollection} from 'types/trainGroups/trainGroupsGroupingCollection';
import {
  mergeCachedTrainRunsIntoTrainGroups,
  trainGroupUserSettablePropPaths,
} from 'appUtils/trainAppUtils/trainAppTypeMerging/trainGroupMerging.ts';
import {trainGroupGroupingCollectionToLookup} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainGroupByGroupingUtils.ts';
import {
  TrainApiTrainFormationsRoute,
  TrainApiTrainRunsRoute,
} from '../../../../types/apis/trainApi';
import {SWRResponse} from 'swr';
import {TrainRunResponse} from '../../../../types/trainRuns/trainRun';
import {
  TrainFormationRunCount,
  TrainFormationRunCountResponse,
} from 'types/trains/trainFormation';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';

/**
 * Resolves the Formations from the API
 * @param loading If true do nothing
 * @param organization The organization
 * @param cemitFilter
 * @param extraFields
 * @param trainGroupOnlyTrainFormations
 * @param setTrainGroupOnlyFormations
 * @param trainGroupsOnlyTrainFormationGroupingCollection
 */
export const useConfiguredApiForTrainFormations = (
  loading: boolean,
  organization: OrganizationLoaded | undefined,
  cemitFilter: CemitFilter,
  extraFields: ('alert_status' | 'train_type' | 'distance')[],
  trainGroupOnlyTrainFormations: Perhaps<TrainGroupOnlyTrainFormation[]>,
  setTrainGroupOnlyFormations: StateSetter<Perhaps<TrainGroupOnlyTrainFormation[]>>,
  trainGroupsOnlyTrainFormationGroupingCollection: TrainGroupsGroupingCollection<TrainGroupOnlyTrainFormation>,
) => {
  const dateInterval = loading ? undefined : head(extractDateIntervals(cemitFilter, {}));
  const swrResponse: SWRResponse<TrainRunResponse> =
    useCemitApiSwrResolveData<TrainApiTrainRunsRoute>(
      loading,
      organization,
      'trainFormations',
      {organization, extraFields, dateInterval},
    );
  const incomingTrainGroupOnlyTrainFormations = swrResponse.data;
  useNotLoadingEffect(
    loading || swrResponse.isLoading || swrResponse.isValidating,
    () => {
      const cachedTrainGroupsGroupingById = trainGroupGroupingCollectionToLookup(
        trainGroupsOnlyTrainFormationGroupingCollection,
      );
      const mergedIncomingTrainGroup = mergeCachedTrainRunsIntoTrainGroups(
        incomingTrainGroupOnlyTrainFormations,
        propOr([], organization.id, cachedTrainGroupsGroupingById),
      );
      setListIfGivenPropsChanged(
        trainGroupOnlyTrainFormations,
        mergedIncomingTrainGroup,
        // These are currently the only properties that the user can update locally
        trainGroupUserSettablePropPaths,
        setTrainGroupOnlyFormations,
      );
    },
    [
      incomingTrainGroupOnlyTrainFormations,
      trainGroupsOnlyTrainFormationGroupingCollection,
    ],
  );
};

/**
 * When the DateInterval changes, queries for all of the organizations TrainFormation
 * Run counts
 * @param loading
 * @param organization
 * @param dateInterval
 * @param setTrainFormationRunCounts
 */
export const useConfiguredApiForTrainFormationRunCounts = (
  loading: boolean,
  organization: OrganizationLoaded | undefined,
  dateInterval: DateInterval,
  trainFormationRunCounts: Perhaps<TrainFormationRunCount[]>,
  setTrainFormationRunCounts: StateSetter<Perhaps<TrainFormationRunCount[]>>,
) => {
  const swrResponse: SWRResponse<TrainFormationRunCountResponse> =
    useCemitApiSwrResolveData<TrainApiTrainFormationsRoute>(
      loading,
      organization,
      'trainFormations',
      {organization, dateInterval},
      'runCount',
    );
  const trainFormationRunCountsIncoming: TrainFormationRunCount[] = swrResponse.data;
  useNotLoadingEffect(
    loading || swrResponse.isLoading || swrResponse.isValidating,
    () => {
      if (!equals(trainFormationRunCounts, trainFormationRunCountsIncoming))
      setTrainFormationRunCounts(trainFormationRunCountsIncoming);
    },
    [trainFormationRunCounts, trainFormationRunCountsIncoming],
  );
};
