import {Layer, Source} from "react-map-gl";
import * as React from "react";
import {useEffect, useState} from "react";
import {restrictionTypes} from "../../../api/data/consts.ts";
import {Card, CardContent, Grid, IconButton, Stack, Typography} from "@mui/material";
import AvstangtSpar from 'assets/dataUrlIcons/trafficSimAssets/icons/AvstangtSpar.svg';
import Backforbud from 'assets/dataUrlIcons/trafficSimAssets/icons/Backforbud.svg';
import AnnanBegransning from 'assets/dataUrlIcons/trafficSimAssets/icons/AnnanBegransning.svg';
import Hastighetsbegraansning from 'assets/dataUrlIcons/trafficSimAssets/icons/Hastighetsbegraansning.svg';
import CustomPopup from "../../themedComponents/CustomPopup.tsx";
import {extractDateObject} from "../utils/datetime_util.ts";
import CustomTooltip from "../../themedComponents/CustomTooltip.tsx";
import EditIcon from '@mui/icons-material/Edit';

export interface IRestriction {
  "id": string;
  "switchId": string;
  "creationReason": string;
  "status": string;
  "speedLimit": number | null;
  "restrictionType": string;
  "authorizedBy": string;
  "createdBy": string;
  "notes": string;
  "isRunningInBothDirection": boolean;
  "coordinates": Array<number>;
  "startPlace": IRestrictionLocationDetails,
  "endPlace": IRestrictionLocationDetails,
  "startTimestamp": string;
  "endTimestamp": string | null;
}

interface IRestrictionLocationDetails {
  "id": string;
  "name": string;
  "network": string
  "coordinates": Array<number>
}
interface RestrictionProps {
  restriction: IRestriction,
  mapRef: any
  onEditRestriction: (restriction: IRestriction) => void;
}
export function Restriction({restriction, mapRef, onEditRestriction}: RestrictionProps) {

  const [showPopUp, setPopupState] = useState(false);
  const getRestrictionType = (type) => {
    switch (type) {
      case 'closedTrack':
        return 'CAvstangtSpar';
      case 'backProhibition':
        return 'CBackforbud';
      case 'speedLimit':
        return 'CHastighetsbegraansning';
      case 'other':
        return 'CAnnanBegransning';
      default:
        'CAvstangtSpar';
    }
  };
  let prepareRestrictionGeoJson = (_restriction) => {
    const feature =  {
      type: 'Feature',
      properties: {
        type: getRestrictionType(_restriction.restrictionType),
      },
      geometry: {
        type: 'Point',
        coordinates: _restriction.coordinates,
      },
    };

    return {
      type: 'FeatureCollection',
      name: 'restrictions',
      features: [feature],
    };
  };

  useEffect(() => {
    if (!mapRef) {
      return undefined
    }
    const onClickLayer = (e) => {
      e.originalEvent.stopPropagation();
      if (e.features.length > 0) {
        const clickedId = e.features[0].properties.id;
        setPopupState(true)
      }
    };

    mapRef.current.on('click', layerId, onClickLayer)


    return () => {
      if (mapRef.current) {
        mapRef.current.off('click', layerId, onClickLayer)
      }
    }

  }, []);

  const sourceId = `restrictions-source-layer-${restriction.id}`
  const layerId = `restrictions-layer-${restriction.id}`
  const coordinates = restriction.coordinates;
  return (
    <>
      <Source
        id={sourceId}
        type="geojson"
        data={prepareRestrictionGeoJson(restriction)}
      >
        <Layer
          id={layerId}
          type="symbol"
          source={sourceId}
          layout={{
            'icon-image': ['get', 'type'],
            // 'icon-image': 'feed-point-icon-gold',
            'icon-allow-overlap': true,
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              0.3,
              14,
              0.3,
              14.1,
              0.2,
              15,
              0.3,
              20,
              0.5,
            ]
          }}
        />
      </Source>

      {showPopUp && (
        <CustomPopup longitude={coordinates[0]} latitude={coordinates[1]}
                     anchor="bottom"
                     collapse={false}
                     onClose={() => setPopupState(false)}>
          <RestrictionContainer restriction={restriction} onEditRestriction={onEditRestriction} />
        </CustomPopup>
      )}
    </>
  )
}

interface IRestrictionContainerProps {
  restriction: IRestriction,
  onEditRestriction: (restriction: IRestriction) => void;
}

function RestrictionContainer({restriction,  onEditRestriction}: IRestrictionContainerProps) {

  const icons = {
    closedTrack: AvstangtSpar,
    backProhibition: Backforbud,
    speedLimit: Hastighetsbegraansning,
    other: AnnanBegransning,
  };

  const getIcon = (begransning: string) => {
    const IconSrc = icons[begransning];
    return IconSrc ? (
      <img
        src={IconSrc}
        alt={begransning}
        style={{marginRight: '8px'}}
      />
    ) : null;
  };

  const startTimestampDate = extractDateObject(restriction.startTimestamp);
  const endTimestampDate = extractDateObject(restriction.endTimestamp ?? "");

  return (
    <div style={{width: 'auto', marginTop: '10px'}}>
      <Grid container spacing={2}>
        <Grid item xs={12} key={restriction.id}>
          <Card variant="outlined">
            <CardContent
              style={{display: 'flex', flexDirection: 'column'}}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  variant="h7"
                  style={{display: 'flex', alignItems: 'center'}}
                >
                  {getIcon(restriction.restrictionType)}{' '}
                  {restrictionTypes[restriction.restrictionType]}
                </Typography>
                <span>
                  <Stack direction="row" spacing={2}>
                    <CustomTooltip title="Edit Restriction">
                      <IconButton onClick={() => {
                        onEditRestriction(restriction)
                      }} color="primary" aria-label="zoom to location">
                        <EditIcon fontSize={"small"} />
                      </IconButton>
                    </CustomTooltip>
                  </Stack>
                </span>
              </div>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Typography variant="caption" color="text.secondary">
                  <strong>Plats:</strong>
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {restriction.startPlace.name} -{' '}
                  {restriction.endPlace.name}{' '}
                </Typography>
              </div>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Typography variant="caption" color="text.secondary">
                  <strong>Berörda:</strong>
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {restriction.switchId}
                </Typography>
              </div>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Typography variant="caption" color="text.secondary">
                  <strong>Hastighet:</strong>
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {restriction.speedLimit}
                </Typography>
              </div>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Typography variant="caption" color="text.secondary">
                  <strong>Orsak:</strong>
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {restriction.creationReason}
                </Typography>
              </div>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Typography variant="caption" color="text.secondary">
                  <strong>Inlagd av:</strong>
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {restriction.createdBy}
                </Typography>
              </div>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Typography variant="caption" color="text.secondary">
                  <strong>Begärd av:</strong>
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {restriction.authorizedBy}
                </Typography>
              </div>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Typography variant="caption" color="text.secondary">
                  <strong>Starttid:</strong>
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {` ${startTimestampDate.toDateString()} ${startTimestampDate.toTimeString().substring(0, 8)}`}
                </Typography>
              </div>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Typography variant="caption" color="text.secondary">
                  <strong>Sluttid:</strong>
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {` ${restriction.endTimestamp ? endTimestampDate.toDateString() : ""} ${restriction.endTimestamp ? endTimestampDate.toTimeString().substring(0, 8) : "" }`}
                </Typography>
              </div>

              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Typography variant="caption" color="text.secondary">
                  <strong>Notering:</strong>
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {restriction.notes}
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
