import React, {useEffect, useState} from 'react';
import {DateTimePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs/index.js';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider.js';
import {Grid, IconButton, TextField} from '@mui/material';
import {Refresh, Search} from '@mui/icons-material';
import dayjs from 'dayjs';

const CustomDateTimePicker = ({
  onSearch = (payload) => {},
  onRefresh = (payload) => {},
}) => {
  const [startDateDayPickerValue, setStartDatePickerValue] = useState(
    dayjs(new Date(Date.now())),
  );
  const [endDateDayPickerValue, setEndDatePickerValue] = useState(
    dayjs(new Date(Date.now())),
  );
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = screenSize < 768; // Mobile view for screens smaller than 768px
  const isTablet = screenSize >= 768 && screenSize < 992; // Tablet view for screens between 768px and 992px

  const datePickerStyle = {
    input: {
      fontSize: isMobile ? '12px' : isTablet ? '14px' : '16px',
    },
  };

  const parseDateTime = (dateTimeValue) => {
    const date = `${dateTimeValue.$y}-${dateTimeValue.$M.toString().padStart(2, '0')}-${dateTimeValue.$D.toString().padStart(2, '0')}`;
    const hour = `${dateTimeValue.$H}`.toString().padStart(2, '0');
    const min = `${dateTimeValue.$m}`.toString().padStart(2, '0');
    const time = `${hour}:${min}`;
    return {date, time};
  };

  const onTriggerSearch = () => {
    if(!startDateDayPickerValue || !endDateDayPickerValue) return;
    const sdParsedDateTime = parseDateTime(startDateDayPickerValue);
    const edParsedTime = parseDateTime(endDateDayPickerValue);

    const payload = {
      startTimestamp: startDateDayPickerValue.toISOString(),
      endTimestamp: endDateDayPickerValue.toISOString(),
    };
    onSearch(payload);
  };

  const handleStartChange = (newValue) => {
    setStartDatePickerValue(newValue);
  };

  const handleEndChange = (newValue) => {
    setEndDatePickerValue(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        spacing={1}
        style={{width: '100%', margin: 0}}
      >
        <Grid item>
          <DateTimePicker
            label="Start Date and Time"
            value={dayjs(startDateDayPickerValue)}
            ampm={false}
            onChange={handleStartChange}
            textField={(params) => (
              <TextField {...params} fullWidth style={datePickerStyle.input} />
            )}
          />
        </Grid>
        <Grid item>
          <DateTimePicker
            label="End Date and Time"
            value={dayjs(endDateDayPickerValue)}
            ampm={false}
            onChange={handleEndChange}
            textField={(params) => (
              <TextField {...params} fullWidth style={datePickerStyle.input} />
            )}
          />
        </Grid>
        <Grid item>
          <IconButton onClick={onTriggerSearch} aria-label="search">
            <Search fontSize="large" />
          </IconButton>
        </Grid>

        <Grid item>
          <IconButton onClick={onRefresh} aria-label="search">
            <Refresh fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default CustomDateTimePicker;
