import React, {useEffect, useState} from 'react';
import {useTheme} from '@mui/styles';
import {
    Box,
    Card,
    FormControl,
    FormControlLabel,
    FormLabel,
    List,
    ListItem,
    ListItemText,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import {TabContext, TabPanel} from '@mui/lab';
import SecondaryButton from './buttons/GenericButtons/SecondaryButton/SecondaryButton.tsx';
import PrimaryButton from './buttons/GenericButtons/PrimaryButton/PrimaryButton.tsx';
import {Edit, InfoRounded} from '@mui/icons-material';

function formatText(text, separator) {
  return text
    ? text.split(separator).map((item, index, array) => (
        <React.Fragment key={index}>
          {item}
          {index < array.length - 1 && <br />}
        </React.Fragment>
      ))
    : null;
}

export default function CardMatarpunkter({
  type,
  stations,
  feedPoints,
  sections,
  feedPointOrStationID,
  feedPointData,
  activeStation,
  activeStationFeedPoints,
  onFeedPointSaveState = (feedPoint, action) => {},
  onStationSaveState = (station, action) => {},
  onCloseCard = () => {},
}) {
  let checkStatus = (type) => {
    if (type === 'station') {
      if (activeStationFeedPoints.length === 0) return 'notLive';
      let counter = 0;
      activeStationFeedPoints.forEach((feedPoint) => {
        const sectionId = feedPoint.sections;
        const section = sections.filter((sec) => sec.internal_id === sectionId)[0];
        if (section) {
          if (section.isOpen === true && section.isInSafeTrackMode === false) {
            counter += 1;
          }
        }
      });
      const isSectionOpen = counter === activeStationFeedPoints.length;
      return activeStation && activeStation.isStationOpen && isSectionOpen
        ? 'live'
        : 'notLive';
    }

    if (type === 'feedpoint' && feedPointData) {
      const sectionId = feedPointData.sections;
      const section = sections.filter((sec) => sec.internal_id === sectionId)[0];
      if (section) {
        const sectionIsOpen = section.isOpen || !section.isInSafeTrackMode;
        if (sectionIsOpen) {
          if (feedPointData && feedPointData.isOpen) {
            return 'live';
          }
        }
        return 'notLive';
      }
    }
    return 'notLive';
  };

  const comments =
    type === 'station'
      ? activeStation === null || activeStation === undefined
        ? ''
        : activeStation.comments
      : feedPointData === null || feedPointData === undefined
        ? ''
        : feedPointData.comments;
  const mComments = comments === undefined ? '' : comments;
  const theme = useTheme();
  const [tabValue, setTabValue] = useState('1');
  const [voltageStatus, setVoltageStatus] = useState(checkStatus(type));
  const [notering, setNotering] = useState(mComments);

  useEffect(() => {
    if (type) {
      setVoltageStatus(checkStatus(type));
      setNotering(mComments);
    }
  }, [type, feedPointOrStationID]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleRadioChange = (event) => {
    setVoltageStatus(event.target.value);
  };
  const handleSave = (actionType) => {
    if (actionType === 'station') {
      if (activeStation !== null && Array.isArray(activeStation.feedpoints)) {
        onStationSaveState(activeStation, voltageStatus === 'live' ? 1 : 0);
      }
      return;
    }

    if (feedPointData) {
      onFeedPointSaveState(feedPointData, voltageStatus === 'live' ? 1 : 0);
    }
  };

  const onNoteringChange = (e) => {
    setNotering(e.target.value);
  };

  const renderListItem = (title, content) => (
    <ListItem sx={{display: 'flex', justifyContent: 'space-between', py: 0}}>
      <ListItemText primaryTypographyProps={{fontWeight: 'bold'}} primary={title} />
      <Typography variant="body2" sx={{maxWidth: '70%', textAlign: 'right'}}>
        {content}
      </Typography>
    </ListItem>
  );

  return (
    <Card
      sx={{
        width: '290px',
        position: 'absolute',
        height: '360px',
        backgroundColor: theme.palette.background.surfaceSecondary,
        marginBottom: '20px',
        zIndex: '9999',
      }}
    >
      <Box
        sx={{
          width: '290px',
          backgroundColor: theme.palette.background.surfaceOverlay,
          color: theme.palette.text.tertiary,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            textAlign: 'left',
            paddingLeft: '5px',
            color: theme.palette.text.gold,
          }}
        >{`${
          type === 'station' ? 'Likriktarstation: ' : 'Matarpunkt: '
        }${feedPointOrStationID}`}</Typography>
      </Box>

      <TabContext value={tabValue}>
        <Box
          sx={{
            width: '290px',
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="FeedPoint tabs"
            variant="fullWidth"
          >
            <Tab
              icon={<Edit sx={{fontSize: 'md'}} />}
              aria-label="Settings"
              value="1"
              sx={{padding: '6px', minWidth: 'auto'}}
            />
            <Tab
              icon={<InfoRounded sx={{fontSize: 'md'}} />}
              aria-label="Info"
              value="2"
              sx={{padding: '6px', minWidth: 'auto'}}
            />
          </Tabs>
        </Box>
        <TabPanel value="1" sx={{p: 2}}>
          <FormControl component="fieldset" sx={{fontSize: '0.8rem'}}>
            <FormLabel component="legend" sx={{fontSize: '0.9rem'}}>
              Status:
            </FormLabel>
            <RadioGroup
              aria-label="voltage status"
              name="voltageStatus"
              value={voltageStatus}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="live"
                control={<Radio />}
                label="Spänningssatt"
                sx={{
                  '& .MuiTypography-root': {fontSize: '0.8rem', color: theme.palette.text.primary,},
                }}
              />
              <FormControlLabel
                value="notLive"
                control={<Radio />}
                label="Ej spänningssatt"
                sx={{
                  '& .MuiTypography-root': {fontSize: '0.8rem', color: theme.palette.text.primary,},
                }}
              />
            </RadioGroup>
          </FormControl>
          <TextField
            margin="normal"
            required
            fullWidth
            id="note"
            label="Notering"
            name="note"
            value={notering}
            autoComplete="note"
            autoFocus
            onChange={onNoteringChange}
            sx={{fontSize: '0.9rem'}}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 2,
            }}
          >
            <PrimaryButton
              onClick={() => handleSave(type)}
              variant="contained"
              size="small"
              sx={{
                margin: '5px',
              }}
            >
              SPARA
            </PrimaryButton>
            <PrimaryButton
              variant="outlined"
              size="small"
              onClick={() => onCloseCard()}
              sx={{
                margin: '5px',
              }}
            >
              Avbryt
            </PrimaryButton>
          </Box>
        </TabPanel>
        <TabPanel value="2" sx={{p: 0}}>
          <Typography variant="subtitle1" gutterBottom sx={{paddingLeft: '5px'}}>
            Övriga egenskaper
          </Typography>
          {feedPointData && (
            <List dense={true} sx={{width: '100%'}}>
              {renderListItem('Drift:', feedPointData.props.drift)}
              {renderListItem('Placering:', formatText(feedPointData.props.placering, '.'))}
              {renderListItem('LS-station:', feedPointData.props.lsStation)}
              {renderListItem('Sektion:', feedPointData.section)}
              {renderListItem(
                'Säkerhets-sektioner:',
                formatText(feedPointData.props.sakerhetsSektioner, ','),
              )}
            </List>
          )}
          {feedPointData && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 2,
              }}
            >
              <SecondaryButton
                size="small"
                onClick={() => console.log('Reset clicked')}
                sx={{
                  margin: '5px',
                }}
              >
                Återställ Sektioner
              </SecondaryButton>
            </Box>
          )}
        </TabPanel>
      </TabContext>
    </Card>
  );
}
