import React, {useEffect, useState} from 'react';
import {useTheme} from '@mui/styles';
import {
  Box,
  CircularProgress,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Typography,
} from '@mui/material';
import {data} from 'pages/trafficsim/config/fakeJsonData.ts';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import CustomDateTimePicker from 'pages/trafficsim/trafficSimComponents/themedComponents/CustomDateTimePicker.tsx';
import {fetchRemoteRestrictionHistory, searchRemoteRestrictionHistory,} from 'pages/trafficsim/api/net/repo.ts';
import {restrictionTypes} from 'pages/trafficsim/api/data/consts.ts';
import AvstangtSpar from 'assets/dataUrlIcons/trafficSimAssets/icons/AvstangtSpar.svg';
import Backforbud from 'assets/dataUrlIcons/trafficSimAssets/icons/Backforbud.svg';
import Hastighetsbegraansning from 'assets/dataUrlIcons/trafficSimAssets/icons/Hastighetsbegraansning.svg';
import AnnanBegransning from 'assets/dataUrlIcons/trafficSimAssets/icons/AnnanBegransning.svg';
import {extractDateObject} from "../track/utils/datetime_util.ts";
import {IRestriction} from "../track/v2/Restriction.tsx";
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import {CustomTablePagination} from "../pagination/CustomTablePagination.tsx";
import {SharedTableHeader} from "../restrictionTableHeader/SharedTableHeader.tsx";
import {getComparator, Order, tableHeaderObj} from "../restrictionTableHeader/tableUtils.ts";

export interface IEventLog {
  id: string;
  restriction: IRestriction;
  eventType: string;
  createdAt: string;
}

interface HistoryRowProps {
  eventType: string;
  restriction: IRestriction;
}

const History = () => {
  const theme = useTheme();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [searchQuery, setSearchQueryObj] = useState<>({
    startTimestamp: '',
    endTimestamp: '',
  });
  const [filter, setFilter] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [historyData, setHistoryData] = useState<Array<IEventLog>>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const [orderBy, setOrderBy] = useState(tableHeaderObj.restriction.key);
  const [order, setOrder] = useState<Order>("desc");

  const icons = {
    closedTrack: AvstangtSpar,
    backProhibition: Backforbud,
    speedLimit: Hastighetsbegraansning,
    other: AnnanBegransning,
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPageNumber(newPage);
    fetchMoreData(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newRows = parseInt(event.target.value, 10)
    if (newRows) {
      setRowsPerPage(newRows);
      setPageNumber(0);
      setHistoryData([]);
      fetchMoreData(1, newRows);
    }
  };

  useEffect(() => {
    fetchMoreData();
  }, [searchQuery]);

  const fetchMoreData = (_pageNumber = 1, _rowsPerPage = 20) => {
    if (searchQuery.startTimestamp && searchQuery.startTimestamp !== '') {
      const queryParams = {
        startTimestamp: searchQuery.startTimestamp,
        endTimestamp: searchQuery.endTimestamp,
        page: _pageNumber,
        pageSize:  _rowsPerPage
      }
      searchRemoteRestrictionHistory(queryParams).then((response) => {
        setIsLoading(false);
        if (response !== null) {
          setHistoryData(response.data);
          setTotal(response.total);
          return;
        }
        setHistoryData([]);
      });
      return;
    }

    fetchRemoteRestrictionHistory(_pageNumber, _rowsPerPage).then((response) => {
      setIsLoading(false);
      if (response !== null) {
        setHistoryData((prevHistoryData) => [...response.data]);
        setTotal(response.total)
        return;
      }
      setHistoryData([]);
    });
  }


  const getIcon = (begransning) => {
    const IconSrc = icons[begransning];
    return IconSrc ? (
      <img src={IconSrc} alt={begransning} style={{marginRight: '8px'}}/>
    ) : null;
  };

  const onSearch = (payload) => {
    setIsLoading(true);
    if(searchQuery.startTimestamp === "" || searchQuery.startTimestamp !== payload.startTimestamp){
      setPageNumber(0);
      setSearchQueryObj({
        startTimestamp: payload.startTimestamp,
        endTimestamp: payload.endTimestamp
      });
    }
  };

  const clearSearchMemory = () => {
    if(searchQuery.startTimestamp !== "") {
      setSearchQueryObj({
        startTimestamp: "",
        endTimestamp: ""
      });
    }
    setPageNumber(0);
  }

  const onRefresh = () => {
    clearSearchMemory()
    setIsLoading(true);
  };

  const Row = ({eventType, restriction}: HistoryRowProps) => {
    const [open, setOpen] = useState(false);

    const startTimestampDate = extractDateObject(restriction.startTimestamp);
    const endTimestampDate = extractDateObject(restriction.endTimestamp ?? "");

    return (
      <React.Fragment>
        <TableRow
          sx={{
            'td, th': {color: theme.palette.text.primary},
            '& > *': {
              borderBottom: (theme) =>
                open ? 'unset' : `2px solid ${theme.palette.border.greyLight}`,
            },
          }}
        >
          <TableCell
            align="left"
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: 0,
              height: '100%',
            }}
          >
            {getIcon(restriction.restrictionType)}
            {restrictionTypes[restriction.restrictionType]}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {`${restriction.startPlace.name} ${restriction.endPlace.name}`}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {restriction.switchId}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {restriction.speedLimit === '' ? '-' : restriction.speedLimit}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {restriction.creationReason}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {eventType}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {`${startTimestampDate.toDateString()} ${startTimestampDate.toTimeString().substring(0, 8)}`}
          </TableCell>
          <TableCell align="left" style={{borderBottom: 0}}>
            {`${restriction.endTimestamp ? endTimestampDate.toDateString() : ""} ${restriction.endTimestamp ? endTimestampDate.toTimeString().substring(0, 8) : ""}`}
          </TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <ExpandLess/> : <ExpandMore/>}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{paddingBottom: 0, paddingTop: 0}}
            sx={{
              textAlign: 'left',
              borderBottom: open
                ? `2px solid ${theme.palette.border.greyLight}`
                : 'unset',
            }}
            colSpan={8}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{margin: 1, color: theme.palette.text.primary}}>
                <Typography
                  variant="body2"
                  gutterBottom
                  component="span"
                  sx={{marginRight: '20px'}}
                >
                  <Box component="span" fontWeight="medium">
                    Notering:
                  </Box>
                  {restriction.notes}
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  component="span"
                  sx={{marginRight: '20px'}}
                >
                  <Box component="span" fontWeight="medium">
                    Begränsning på begäran av:
                  </Box>
                  {restriction.authorizedBy}
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  component="span"
                  sx={{marginRight: '20px'}}
                >
                  <Box component="span" fontWeight="medium">
                    Begränsning inlagd av:
                  </Box>
                  {restriction.createdBy}
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredData = data.filter((item) => {
    const itemStartDate = new Date(item.starttid);
    const itemEndDate = new Date(item.sluttid);
    const filterStartDate = startDate ? new Date(startDate) : new Date('1970-01-01');
    const filterEndDate = endDate ? new Date(endDate) : new Date('2999-12-31');

    return (
      (filter === '' || item.begransning === filter) &&
      itemStartDate >= filterStartDate &&
      itemEndDate <= filterEndDate
    );
  });

  const filterDropdown = (
    <FormControl
      sx={{m: 0, minWidth: 260, marginBottom: 1, fontSize: '12px'}}
      size="small"
    >
      <InputLabel id="filter-label" sx={{fontSize: '14px'}}>
        Filter
      </InputLabel>
      <Select
        style={{width: '200px'}}
        labelId="filter-label"
        id="filter-select"
        value={filter}
        label="Filter"
        onChange={handleFilterChange}
        sx={{fontSize: '12px'}}
      >
        <MenuItem value="" sx={{fontSize: '12px'}}>
          Alla
        </MenuItem>
        <MenuItem value="Avstängt spår" sx={{fontSize: '12px'}}>
          Avstängt spår
        </MenuItem>
        <MenuItem value="Backförbud" sx={{fontSize: '12px'}}>
          Backförbud
        </MenuItem>
        <MenuItem value="Hastighetsbegränsning" sx={{fontSize: '12px'}}>
          Hastighetsbegränsning
        </MenuItem>
        <MenuItem value="Annan begränsning" sx={{fontSize: '12px'}}>
          Annan begränsning
        </MenuItem>
      </Select>
    </FormControl>
  );

  const datePickers = (
    <div>
      <CustomDateTimePicker label="" onSearch={onSearch} onRefresh={onRefresh}/>
    </div>
  );

  const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
      () =>
          [...historyData]
              .sort(getComparator<IEventLog>(order, orderBy)),
      [order, historyData, orderBy],
  );

  const createSortHandler =
      (property: string) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
      };

  const expandedLayout = (
    <TableContainer component={Paper}>
      <Table sx={{minWidth: 650}} size="small" aria-label="collapsible table">
        <SharedTableHeader createSortHandler={createSortHandler} showEventTypeHeader={true} orderBy={orderBy} order={order} />
        {visibleRows && (
          <TableBody>
            {visibleRows.map(
              (row) =>
                row && (
                  <Row key={row.id} eventType={row.eventType} restriction={row.restriction}/>
                ),
            )}
          </TableBody>
        )}
        <TableFooter>
          <TableRow>
            <CustomTablePagination
              align={"right"}
              width={500}
              rowsPerPageOptions={[50, 100, 150]}
              component="div"
              colSpan={9}
              count={total}
              rowsPerPage={rowsPerPage}
              page={pageNumber}
              slotProps={{
                select: {
                  'aria-label': 'Rows',
                },
                actions: {
                  showFirstButton: true,
                  showLastButton: true,
                  slots: {
                    firstPageIcon: FirstPageRoundedIcon,
                    lastPageIcon: LastPageRoundedIcon,
                    nextPageIcon: ChevronRightRoundedIcon,
                    backPageIcon: ChevronLeftRoundedIcon,
                  },
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
  const filtersContainer = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        justifyContent: 'space-between',
      }}
    >
      {/*{filterDropdown}*/}
      {<div></div>}
      {datePickers}
    </div>
  );

  return (
    <div>
      {filtersContainer} {isLoading ? <Box alignContent={"center"}><CircularProgress size={30}/></Box> : expandedLayout}
    </div>

  );
};

export default History;
