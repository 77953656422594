import {ReactElement, useMemo, useState} from 'react';
import {
  useNotLoadingEffectRailwayLineMapboxSourcesAndLayers,
  useNotLoadingEffectScheduledStopPointMapboxSourcesAndLayers
} from 'async/cemitAppAsync/cemitAppHooks/mapHooks/railwayLineMapHooks.ts';
import {ButtonState} from 'types/componentStates/buttonState';
import {doesOrganizationHaveServiceLines} from 'utils/organization/organizationUtils.ts';
import {
  useConfiguredApiForDetailedRailwayLines
} from '../../../trainAppHooks/trainApiHooks/trainApiRailwayLineHooks.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {anyImplementCemitType} from 'classes/cemitAppCemitedClasses/cemitClassResolvers.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {RailwayLine} from 'types/railways/railwayLine';
import {
  MAP_RAILWAY_SOURCE,
  SCHEDULED_STOP_POINT_SOURCE_PREFIX
} from 'config/appConfigs/cemitAppConfigs/railwayLineConfig.ts';
import {useNotLoadingMemoAreMapSourcesLoaded} from 'async/cemitAppAsync/cemitAppHooks/mapHooks/trainMapHooks.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {MapLayerProps, MapProps} from 'types/propTypes/mapPropTypes/mapProps';
import {lengthAsBoolean} from 'utils/functional/functionalUtils.ts';
import {multiLineStringOrLineStringToLineSegments} from 'utils/geojson/geojsonUtils.ts';
import {Feature, LineString, MultiLineString, Position} from 'geojson';
import {chain, length, map} from 'ramda';
import {TrackData} from 'types/railways/track';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {multiLineString} from '@turf/helpers';
import {TrainMapLayerProps} from 'types/propTypes/mapPropTypes/trainMapLayerProps';
import {MapboxImage} from 'types/mapbox/MapboxImage.ts';

/***
 * Loads/Updates TrainMap layer data into mapProps.trainMapLayerProps.trainMapLayerProps
 * Depends directly on TrainGroupSensor props in trainProps.trainGroupSingleTrainRunProps.sensorProps and TrainMap in
 * mapProps
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param mapProps
 * @param children
 * @return {*}
 * @constructor
 */
const TrainMapLayerDependency = (
  {
    appProps,
    organizationProps,
    trainProps,
    mapProps,
    renderChildren,
    loading
  }: Required<TrainAppMapDependencyProps>): ReactElement<
  Required<TrainAppMapDependencyProps>
> => {
  // The RailwayLine being hovered over, TODO not currently in use
  const [hoveredRailwayLineId, setHoveredRailwayLineId] =
    useState<Perhaps<string>>(undefined);

  // Used to indicate if the trip trainSwitchLoaded
  const [trainSwitchLoaded, setTrainSwitchLoaded] = useState<boolean>(false);

  // TODO terrainLayer not in use
  const [trainMapButtonStates, setTrainMapButtonStates] = useState<ButtonState>({
    terrainLayer: false,
    dataColumns3DLayerAreVisible: true,
    dataThresholds3DLayersAreVisible: true
  });


  // Load the detailed RailwayLines to get the geojson
  // This replaces the RailwayLineMinimized instances with RailwayLine instances
  // containing geojson without slowing down the loading process
  useConfiguredApiForDetailedRailwayLines(
    loading || !lengthAsBoolean(trainProps.railwayLineProps.railwayLines),
    organizationProps.organization,
    trainProps.railwayLineProps.railwayLines,
    trainProps.railwayLineProps.setRailwayLines
  );
  const railwayLinesDetailLoaded = useNotLoadingMemo(
    loading || !trainProps.railwayLineProps?.railwayLines,
    (railwayLines) => {
      // RailwayLine with detail currently downloads in a single request, so we just need to check that
      // any are RailwayLine and not just RailwayLineMinimized
      return anyImplementCemitType<RailwayLine>(CemitTypename.railwayLine, railwayLines);
    },
    [trainProps.railwayLineProps.railwayLines] as const
  );

  // Derived data about the tracks for Mapbox layers to use
  const trackData: Perhaps<TrackData> = useNotLoadingMemo(
    // Make sure the detailed RailwayLines are downloaded before computing
    loading || !railwayLinesDetailLoaded,
    (railwayLines) => {
      const lineStringFeatures: Feature<LineString> = chain(
        (railwayLine: RailwayLine): Feature[] => {
          return railwayLine.geojson.features;
        },
        railwayLines
      );

      const trackAsMultiLineString: Feature<MultiLineString> = multiLineString(
        map((lineStringFeature: Feature<LineString>): Position => {
          return lineStringFeature.geometry.coordinates;
        }, lineStringFeatures)
      );
      // If trackRouteLines are available, use them to find the nearest point
      const trackRouteLines: Feature<LineString>[] = chain(
        (railwayLine: RailwayLine): Feature<LineString>[] => {
          return map(
            (trackRoute: TrackRoute): Feature<LineFeature> => {
              return trackRoute.geojson;
            },
            (railwayLine.trackRoutes || []) as TrackRoute[]
          );
        },
        trainProps.railwayLineProps.railwayLines as RailwayLine[]
      );
      return clsOrType<TrackData>(CemitTypename.trackData, {
        lineSegments: multiLineStringOrLineStringToLineSegments(trackAsMultiLineString),
        trackAsMultiLineString,
        trackRouteLines: length(trackRouteLines) ? trackRouteLines : undefined
      });
    },
    [trainProps.railwayLineProps.railwayLines] as const
  );

  // Update/Create RailwayLine Mapbox Sources
  useNotLoadingEffectRailwayLineMapboxSourcesAndLayers(
    // If the organization does not have ServiceLines, we should always be in a loading state
    doesOrganizationHaveServiceLines(organizationProps) == false ||
    !railwayLinesDetailLoaded,
    {
      appProps,
      organizationProps,
      trainProps,
      mapProps
    }
  );

  // Update/Create ScheduledStopPoint Mapbox Sources
  useNotLoadingEffectScheduledStopPointMapboxSourcesAndLayers(
    // If the organization does not have ServiceLines, we should always be in a loading state
    // Check railwayLinesDetailLoaded to make sure this runs after RailwayLine
    // Mapbox Sources so the z-layer is higher
    doesOrganizationHaveServiceLines(organizationProps) == false ||
    !railwayLinesDetailLoaded,
    {
      appProps,
      organizationProps,
      trainProps,
      mapProps
    }
  );

  const areMapSourcesLoaded = useNotLoadingMemoAreMapSourcesLoaded(
    loading,
    mapProps.changeStatuses,
    [MAP_RAILWAY_SOURCE, SCHEDULED_STOP_POINT_SOURCE_PREFIX]
  );

  const updatedMapProps: MapProps = useMemo(() => {
    return {
      ...mapProps,
      mapLayerProps: clsOrType<MapLayerProps>(CemitTypename.mapLayerProps, {
        trainMapLayerProps: clsOrType<TrainMapLayerProps>(
          CemitTypename.trainMapLayerProps,
          {
            // Depends directly on trainProps.trainGroupSensorProps and mapProps
            loading: loading || !areMapSourcesLoaded,
            hoveredRailwayLineId,
            setHoveredRailwayLineId,
            trainMapButtonStates,
            setTrainMapButtonStates,
            trainSwitchLoaded,
            setTrainSwitchLoaded,
            trackData,
          }
        )
      })
    };
  }, [mapProps, loading, hoveredRailwayLineId, trainMapButtonStates, trainSwitchLoaded, trackData]);

  return renderChildren({
    appProps,
    organizationProps,
    mapProps: updatedMapProps,
    trainProps
  });
};

TrainMapLayerDependency.displayName = 'TrainMapLayerDependency';
export default TrainMapLayerDependency;
// export default memo(
//   TrainMapLayerDependency,
/* (prevProps, currentProps) => {
   const appPropsEqual = prevProps.appProps == currentProps.appProps;
   const orgPropsEqual = prevProps.organizationProps == currentProps.organizationProps;
   const trainPropsEqual = prevProps.trainProps == currentProps.trainProps;
   const mapPropsEqual = prevProps.mapProps == currentProps.mapProps;
   return appPropsEqual && orgPropsEqual && trainPropsEqual && mapPropsEqual;
 }, */
//);
