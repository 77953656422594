import React, {useContext} from 'react';
import CemitThemeContext from 'theme/CemitThemeContext.ts';
import {Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

const TrainMapThemeToggle = () => {
  const {themeName, toggleTheme} = useContext(CemitThemeContext);
  const {t} = useTranslation();

  return <Tooltip {...{arrow: true, title: t('selectMapStyle')}}>
    <Stack {...{direction: 'row', spacing: 1, sx: {alignItems: 'center'}}} >
      <Typography {...{variant: 'subtitle2', color: 'primary'}}>{t('mapStyle')}</Typography>
      <ToggleButtonGroup
        size='small'
        color="primary"
        value={themeName}
        exclusive
        onChange={toggleTheme}
        aria-label={t('selectMapStyle')}
      >
        <ToggleButton value="light">{t('lightMap')}</ToggleButton>
        <ToggleButton value="dark">{t('darkMap')}</ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  </Tooltip>;
};
export default TrainMapThemeToggle;
