import {Perhaps} from 'types/typeHelpers/perhaps';

/**
 * Describes the AlertOverview DateIntervals back in days from the present
 */
export enum AlertOverviewByTimePeriod {
  // The interval of the current day
  Days1 = 'Days1',
  // The interval of the last week
  Days7 = 'Days7',
  // The interval of the last fortnight
  Days14 = 'Days14',
  // The interval of the last 30 days
  Days30 = 'Days30',
  // The interval of the last 90 days
  Days90 = 'Days90',
  // The interval of the last 180 days
  Days180 = 'Days180',
}
export enum AlertOverviewByTimePeriodPrevious {
  // The interval of the previous day
  Days2To1 = 'Days2To1',
  // The interval of the previous week
  Days14To7 = 'Days14To7',
  // The interval of the previous fortnight
  Days28To14 = 'Days28To14',
  // The interval of the previous 30 days
  Days60To30 = 'Days60To30',
  // The interval of the previous 90 days
  Days180To90 = 'Days180To90',
}
export type AlertOverviewByTimePeriodOrPrevious = AlertOverviewByTimePeriod | AlertOverviewByTimePeriodPrevious

const previousAlertOverviewByTimePeriod: Record<
  AlertOverviewByTimePeriod,
  Perhaps<AlertOverviewByTimePeriodPrevious>
> = {
  [AlertOverviewByTimePeriod.Days1]: AlertOverviewByTimePeriodPrevious.Days2To1,
  [AlertOverviewByTimePeriod.Days7]: AlertOverviewByTimePeriodPrevious.Days14To7,
  [AlertOverviewByTimePeriod.Days14]: AlertOverviewByTimePeriodPrevious.Days28To14,
  [AlertOverviewByTimePeriod.Days30]: AlertOverviewByTimePeriodPrevious.Days60To30,
  [AlertOverviewByTimePeriod.Days90]: AlertOverviewByTimePeriodPrevious.Days180To90,
  [AlertOverviewByTimePeriod.Days180]: undefined
};

/**
 * Resolve the previous AlertOverviewByTimePeriod corresponding with the current AlertOverviewByTimePeriod.
 * Days180 resolves to undefined, since we don't have data older than 180 days currently
 * @param alertOverviewByTimePeriod
 */
export const resolvePreviousAlertOverviewByTimePeriod = (alertOverviewByTimePeriod: AlertOverviewByTimePeriod): Perhaps<AlertOverviewByTimePeriodPrevious> => {
  return previousAlertOverviewByTimePeriod[alertOverviewByTimePeriod];
};
