import {IconButton, Menu, MenuItem, Stack, Typography} from '@mui/material';
import {
  ChosenTrainFormations
} from 'components/apps/trainAppComponents/cemitFilterComponents/cemitFilterTrainFormationComponents/ChosenTrainFormations.tsx';
import {length, lensPath} from 'ramda';
import React from 'react';
import TrainFormationChooser, {
  TrainFormationChooserProps
} from '../../chooserComponents/trainFormationChooserComponents/TrainFormationChooser.tsx';
import {useMemoClsOrType} from 'appUtils/typeUtils/useMemoClsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {
  TrainAppTrainComponentDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainTrainAppTrainComponentDependencyProps.d.ts';
import ColorPicker from 'components/pickers/colorPicker.tsx';
import {setClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {Property} from 'csstype';
import TrainGroupRemoveButton
  from 'components/apps/trainAppComponents/chooserComponents/trainGroupChooserComponents/TrainGroupRemoveButton.tsx';
import {
  CemitFilterTrainFormationsViewProps
} from 'components/apps/trainAppComponents/cemitFilterComponents/cemitFilterTrainFormationComponents/CemitFilterTrainFormationsViewProps.ts';
import {MoreVert} from '@mui/icons-material';
import TrainGroupVisibilityButton
  from 'components/apps/trainAppComponents/chooserComponents/trainGroupChooserComponents/TrainGroupVisibilityButton.tsx';
import {toggleTrainGroupVisibility} from 'classes/typeCrud/trainGroupCrud.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {OverridableComponent} from '@mui/types';
import {StackTypeMap} from '@mui/system';

/**
 * Presents CemitFilter TrainFormations in a menu with chips for the selected and
 * an expand button to switch to the list view
 *
 * @param appProps
 * @param trainProps
 * @param componentProps
 * @constructor
 */
const CemitFilterTrainFormationsViewMenu = ({
                                              appProps,
                                              trainProps,
                                              componentProps
                                            }: TrainAppTrainComponentDependencyProps<CemitFilterTrainFormationsViewProps>) => {
  const {
    readonly,
    formationLabelsFromFilter,
    formationsLabel,
    trainGroupFormations,
    chosenTrainGroupOnlyTrainFormations,
    handleAddTrainGroupOnlyTrainFormationToFilter,
    handleRemoveTrainGroupOnlyTrainFormationFromFilters,
    scopedTrainGroup,
    addTrainGroupMode,
    maximumAllowed
  } = componentProps;
  const crudList =
    trainProps.trainGroupOnlyTrainFormationProps.crudTrainGroupOnlyTrainFormations;

  const trainFormationChooserProps: TrainFormationChooserProps =
    useMemoClsOrType<TrainFormationChooserProps>(
      CemitTypename.trainFormationChooserProps,
      {
        trainGroupFormations,
        chosenTrainGroupOnlyTrainFormations,
        handleAddTrainGroupOnlyTrainFormationToFilter,
        // Show only this as selected among chosenTrainGroupOnlyTrainFormations
        scopedTrainGroup,
        // Show an add icon and have the selected item from the menu be added to chosenTrainGroupOnlyTrainFormations
        addTrainGroupMode,
        // Maximum TrainGroups allowed
        maximumAllowed
      },
      [trainGroupFormations, chosenTrainGroupOnlyTrainFormations]
    );
  // Toggle that appears if the TrainGroup is invisible
  const visibilityStatusToggle = !scopedTrainGroup?.activity?.isVisible ? (
    <TrainGroupVisibilityButton
      key="visibilityButton"
      {...{
        toggleTrainGroupVisibility: (trainGroup: TrainGroup) =>
          toggleTrainGroupVisibility(crudList, trainGroup),
        trainGroup: scopedTrainGroup
      }}
    />
  ) : undefined;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      {...({
        spacing: 0.5,
        direction: 'row',
        sx: {
          width: addTrainGroupMode ? '40px' : '100%',
          alignItems: 'center',
          // For add mode, show the icon on the right
          justifyContent: addTrainGroupMode ? 'center' : 'space-between'
        }
      } as OverridableComponent<StackTypeMap>)}
    >
      <Stack
        {...{
          spacing: 0.5,
          direction: 'row',
          sx: {
            alignItems: 'center',
            ...(componentProps.addTrainGroupMode
              ? {
                m: 0,
                p: 0,
                borderRadius: 0,
              }
              : {})
          }
        }}
      >
        <TrainFormationChooser
          {...{
            appProps,
            trainProps,
            componentProps: trainFormationChooserProps
          }}
        />
        {
          // Show Chips for Formations in the filter or show an all chosenFormations message.
          !readonly && length(formationLabelsFromFilter) ? (
            <ChosenTrainFormations
              {...{
                chosenTrainGroupOnlyTrainFormations,
                handleRemoveTrainGroupOnlyTrainFormationFromFilters
              }}
            />
          ) : (
            <Typography
              {...{
                sx: {
                  whiteSpace: 'nowrap',
                  color: 'secondary.main'
                }
              }}
            >
              {formationsLabel}
            </Typography>
          )
        }
      </Stack>
      {scopedTrainGroup && !addTrainGroupMode && (
        <Stack {...{direction: 'row', spacing: 1, sx: {alignItems: 'center'}}}>
          {visibilityStatusToggle}
          <IconButton
            aria-label="more"
            id="basic-menu"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            color="secondary"
            onClick={handleClick}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem>
              <TrainGroupRemoveButton
                {...{
                  isSelectedTrainRuns: true,
                  trainGroup: scopedTrainGroup,
                  disabled: length(chosenTrainGroupOnlyTrainFormations) < 2,
                  handleRemoveTrainGroupFromFilters:
                  handleRemoveTrainGroupOnlyTrainFormationFromFilters,
                  label: appProps.t('remove')
                }}
              />
            </MenuItem>
            <MenuItem>
              <TrainGroupVisibilityButton
                key="visibilityButton"
                {...{
                  toggleTrainGroupVisibility: (trainGroup: TrainGroup) =>
                    toggleTrainGroupVisibility(crudList, trainGroup),
                  trainGroup: scopedTrainGroup,
                  label: appProps.t(
                    scopedTrainGroup.activity.isVisible
                      ? 'hideTrainGroup'
                      : 'showTrainGroup'
                  )
                }}
              />
            </MenuItem>
            <MenuItem>
              <ColorPicker
                key="colorPicker"
                {...{
                  color: scopedTrainGroup?.activity?.isActiveColor,
                  handleChangeComplete: (color: Property.Color) => {
                    const modifiedTrainGroup = setClassOrType(
                      lensPath(['activity', 'isActiveColor']),
                      color,
                      scopedTrainGroup
                    );
                    crudList.updateOrCreate(modifiedTrainGroup);
                  },
                  label: appProps.t('changeColor')
                }}
              ></ColorPicker>
            </MenuItem>
          </Menu>
        </Stack>
      )}
    </Stack>
  );
};

CemitFilterTrainFormationsViewMenu.displayProps = 'CemitFilterTrainFormationsMenuChooser';
export default CemitFilterTrainFormationsViewMenu;
