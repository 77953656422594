import {Box, Stack} from '@mui/material';
import React, {useEffect} from 'react';
import {CemitComponentProps} from '../../types/propTypes/cemitComponenProps';
import {Perhaps} from '../../types/typeHelpers/perhaps';
import TrainFilterHeaderComponent from 'pages/trainApp/trainPageSubContainers/TrainFilterHeaderComponent.tsx';

import {
  TrainAppMapComponentDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapComponentDependencyProps.ts';
import {needToShowTrainGroupOverview} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/activityUtils.ts';

export interface TrainPageProps extends CemitComponentProps {
  trainPageBoardAndMaybeSlider: JSX.Element;
  trainPageBoardCollapsed: JSX.Element;
  trainPageSliderButton: Perhaps<JSX.Element>;
  trainPageMap: Perhaps<JSX.Element>;
}

/**
 * TrainPage is the top component of the Train application. It shows TrainBoard and possible a TrainMap
 * Within the TrainBoard is a listing of TrainGroups and possibly details about the selected TrainGroups
 * @return {JSX.Element}
 * @constructor
 */
const TrainPage = (
  {
    appProps,
    organizationProps,
    trainProps,
    mapProps,
    componentProps
  }: TrainAppMapComponentDependencyProps<TrainPageProps>): JSX.Element => {
  const {
    trainPageBoardAndMaybeSlider,
    trainPageBoardCollapsed,
    trainPageSliderButton,
    trainPageMap
  } = componentProps;

  const isTrainGroupOverviewActive = needToShowTrainGroupOverview({appProps});
   useEffect(() => {
     // Resize the map when it becomes visible again. This makes sure it takes up 100%
     // of it available container
     if (!isTrainGroupOverviewActive) {
       mapProps.trainMap?.resize();
     }
  }, [mapProps.trainMap, isTrainGroupOverviewActive]);

  // [ <Slide><TrainBoard/></Slide> (collapsable to <TrainGroupYard>) | <TrainMap/> ]
  // TrainBoard display non-map data. TrainMap is a Mapbox map
  // TrainBoard collapses left using Slide and a small <TrainGroupYard> takes its place along
  // with the expanded TrainMap
  return (
    <Stack
      direction="column"
      sx={{
        minWidth: 0,
        minHeight: 0,
        flex: 1,
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        height: '100%'
      }}
    >
      <TrainFilterHeaderComponent {...{appProps, organizationProps, trainProps, mapProps}} />
      <Stack
        {...{
          direction: 'row',
          spacing: 1,
          sx: {
            minWidth: 0,
            minHeight: 0,
            flex: 1,
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
            height: '100%'
          }
        }}
      >
        {/* Renders TrainBoard, which includes the TrainList and maybe TrainFormation and Details page */}
        {trainPageBoardAndMaybeSlider}
        {/* Only renders when trainListContainer is collapsed with Slide */}
        {trainPageBoardCollapsed}
        <Stack
          key="trainPageMapAndTrainPageSliderButton"
          {...{
            spacing: 1,
            direction: 'row',
            sx: {
              display: isTrainGroupOverviewActive ? 'none' : 'normal',
              minWidth: 0,
              minHeight: 0,
              flex: isTrainGroupOverviewActive ? 0 : 1,
              overflow: 'hidden',
              // For the absolute slider button
              position: 'relative',
              flexGrow: 1
            }
          }}
        >
          {/* Render the slider buttons to collapse expand the TrainPage component or map */}
          {trainPageSliderButton}
          {/* The Optional Mapbox map component */}
          {trainPageMap}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TrainPage;
