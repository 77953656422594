import {alpha, Box} from '@mui/material';
import LoaderWithText from 'components/loading/LoaderWithText.tsx';
import TrainGroupErrorButton
  from 'components/apps/trainAppComponents/chooserComponents/trainGroupChooserComponents/TrainGroupErrorButton.tsx';
import React from 'react';
import {AppSettings} from '../../../config/appConfigs/appSettings.ts';
import {cemitColors} from 'theme/cemitColors.ts';
import {CemitComponentProps} from '../../../types/propTypes/cemitComponenProps';
import {toArrayIfNot} from 'utils/functional/functionalUtils.ts';
import {
  MapComponentDependencyDerivedProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapComponentDependencyProps.ts';
import {needToShowTrainGroupOverview} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/activityUtils.ts';

const {mapboxDivId} = AppSettings;

export interface TrainMapComponentProps extends CemitComponentProps {
  loadingMapLabel: string;
  error: boolean;
  loadingOrUpdating: boolean;
  hideSearch: boolean;
  isTrainGroupOverviewActive?: boolean;
}

/**
 * The Mapbox map for the TrainApp.
 * TODO this can serve all other Cemit applications in the future
 * @param trainProps
 * @param mapProps
 * @param componentProps
 */
const TrainMapComponent = (
  {
    appProps,
    trainProps,
    mapProps,
    componentProps
  }: MapComponentDependencyDerivedProps<TrainMapComponentProps>): JSX.Element => {
  const {whatIsLoading} = mapProps;
  const {sx, loadingMapLabel, error, loadingOrUpdating} = componentProps;

  // If coming from the train app, isTrainGroupOverviewActive means don't show the map
  const isTrainGroupOverviewActive = componentProps.isTrainGroupOverviewActive || false
  return (
    <Box
      sx={[
        {
          display: isTrainGroupOverviewActive ? 'none' : 'normal',
          overflow: 'hidden',
          width: 'inherit',
          maxWidth: 'inherit',
          right: 0
        },
        ...toArrayIfNot(sx)
      ]}
    >
      {/* Provides a linear gradient on the left side of the map to blend with the background colorProvides a linear gradient on the left side of the map to blend with the background color.
    TODO remove the gradient. It isn't needed
    */}
      <Box
        sx={{
          position: 'relative',
          top: 0,
          bottom: 0,
          width: '100%',
          height: '100%',

          '&:after': {
            position: 'absolute',
            content: '""',
            height: '100%',
            width: '20px',
            left: 0,
            top: 0,
            zIndex: 100,
            background: `linear-gradient(
          90deg,
          ${alpha(cemitColors.darkBlue, 1)} 0%,
          ${alpha(cemitColors.darkBlue, 0.5)} 80%,
          ${alpha(cemitColors.darkBlue, 0)} 100%,
          )`
          }
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative'
          }}
        >
          <Box
            sx={{
              zIndex: 100,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            {!error && loadingOrUpdating && (
              <LoaderWithText
                {...{spinner: true, text: loadingMapLabel, whatIsLoading: whatIsLoading}}
              />
            )}
            {
              // TODO, error button should be generic
              error && (
                <TrainGroupErrorButton
                  {...{
                    trainGroups: trainProps.trainGroupActivityProps.activeTrainGroups,
                    crudTrainGroups:
                    trainProps.trainGroupSingleTrainRunProps.crudTrainGroups
                  }}
                />
              )
            }
          </Box>
          <Box
            key="trainMap"
            id={mapboxDivId}
            ref={mapProps.trainMapContainer}
            sx={{
              top: 0,
              bottom: 0,
              width: '100%',
              height: '100%',
              position: 'relative'
            }}
          ></Box>
          {/* The SearchBox */}
          <Box
            sx={{
              visibility: componentProps.hideSearch ? 'hidden' : 'visible',
              zIndex: 100,
              margin: 1,
              position: 'absolute',
              right: '0px',
              top: '0px',
              width: '50%',
              opacity: 0.8
            }}
            id="search-box-container"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TrainMapComponent;
