import {MapboxLayer, MapSourceVisual, MapSourceVisualForTrainGroup} from '../../../../types/mapbox/mapSourceVisual';
import {
  railwayLineMapboxSourcesAndLayersSets
} from '../../../../appUtils/cemitAppUtils/cemitSourceLayerUtils/railwayLineSourceLayerUtils.ts';
import {useNotLoadingEffect, useNotLoadingSetterEffect} from '../../../../utils/hooks/useMemoHooks.ts';
import {setMapboxSourceAndLayersSets} from '../../../../utils/map/mapboxSourceUtils.ts';
import {Perhaps} from '../../../../types/typeHelpers/perhaps';
import {RailwayLine} from '../../../../types/railways/railwayLine';
import {
  scheduledStopPointMapboxSourcesAndLayersSets
} from 'appUtils/cemitAppUtils/cemitSourceLayerUtils/scheduledStopPointSourceLayerUtils.ts';
import {TrainAppMapDependencyProps} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppMapDependencyProps.ts';
import {useContext} from 'react';
import CemitThemeContext from 'theme/CemitThemeContext.ts';
import {MapSourceChangeStatus} from 'types/mapbox/mapSourceChangeStatus';
import {filter, find, forEach, keys, startsWith, zip} from 'ramda';
import {SCHEDULED_STOP_POINT_SOURCE_PREFIX} from 'config/appConfigs/cemitAppConfigs/railwayLineConfig.ts';
import {themeLookup} from 'theme/lightDarkTheme.ts';

/**
 * Call railwayLineMapboxSourcesAndLayersSets and update/creates the resulting sources on the trainMap
 * @param loading Do nothing if true
 * @param trainProps
 * @param trainProps.railwayLineProps
 * @param trainProps.railwayLineProps.railwayLines The railwayLines whose tracks we create sources for
 * @param mapProps
 * @param mapProps.trainMap The Mapbox map
 * @param t The translation function
 */
export const useNotLoadingEffectScheduledStopPointMapboxSourcesAndLayers = (
  loading: boolean,
  {organizationProps, trainProps, mapProps}: TrainAppMapDependencyProps
) => {
  // TODO we used to used ordered tracks, now we need the railwayLines of the TrainRouteOrGroup,
  // This is passing all railways
  const railwayLines: Perhaps<RailwayLine[]> = trainProps.railwayLineProps?.railwayLines;
  const {theme} = useContext(CemitThemeContext);
  const themeMode: string = theme.palette.mode;

  const dependencies = [railwayLines] as const;
  useNotLoadingSetterEffect(
    {loading: loading!},
    scheduledStopPointMapboxSourcesAndLayersSets,
    {organizationProps, themeMode},
    (mapSourceVisuals: MapSourceVisualForTrainGroup[]) => {
      // railwayLineMapboxSourcesAndLayersSets produces a source and one or more layers
      // for each RailwayLine Track. Set Mapbox to them here
      setMapboxSourceAndLayersSets(
        mapProps.trainMap,
        mapProps.setChangeStatuses,
        mapSourceVisuals,
        mapProps.setMapboxImages,
        false
      );
    },
    dependencies
  );

};

/**
 * Call railwayLineMapboxSourcesAndLayersSets and update/creates the resulting sources on the trainMap
 * @param loading Do nothing if true
 * @param trainProps
 * @param trainProps.railwayLineProps
 * @param trainProps.railwayLineProps.railwayLines The railwayLines whose tracks we create sources for
 * @param mapProps
 * @param mapProps.trainMap The Mapbox map
 * @param t The translation function
 */
export const useNotLoadingEffectRailwayLineMapboxSourcesAndLayers = (
  loading: boolean,
  {trainProps, mapProps}: TrainAppMapDependencyProps
) => {
  // TODO we used to used ordered tracks, now we need the railwayLines of the TrainRouteOrGroup,
  // This is passing all railways
  const railwayLines: Perhaps<RailwayLine[]> = trainProps.railwayLineProps?.railwayLines;
  // trainProps.trainRouteGroupProps.trainRouteOrGroup.orderedTracks
  const dependencies = [railwayLines] as const;
  useNotLoadingSetterEffect(
    {loading: loading!},
    railwayLineMapboxSourcesAndLayersSets,
    {railwayLines},
    (mapSourceVisuals: MapSourceVisualForTrainGroup[]) => {
      // railwayLineMapboxSourcesAndLayersSets produces a source and one or more layers
      // for each RailwayLine Track. Set Mapbox to them here
      setMapboxSourceAndLayersSets(
        mapProps.trainMap,
        mapProps.setChangeStatuses,
        mapSourceVisuals,
        mapProps.setMapboxImages,
        true,
        false
      );
    },
    dependencies
  );

};
