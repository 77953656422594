import {addIndex, compose, fromPairs, keys, length, map, prop, reduce} from 'ramda';
import {AttributeAlertLevel} from 'types/alerts/attributeAlertLevelEnums';

import {EnumStringType} from 'types/alerts/enumStringType.ts';
import {TrainGroup} from 'types/trainGroups/trainGroup.ts';
import {AlertScopeProps} from 'types/alerts/alertScopeProps.ts';
import {TrainFormationCollectionDevice} from 'types/sensors/trainFormationCollectionDevice';
import {VehicleCollectionDevice} from 'types/sensors/vehicleCollectionDevice';
import {findOrThrow} from 'utils/functional/functionalUtils.ts';
import {AlertDatum, GraphqlResponseDateIntervalOverviewAlertData} from 'types/alerts/alertMapData.ts';
import {
  AlertOverviewByTimePeriod,
  AlertOverviewByTimePeriodOrPrevious,
  AlertOverviewByTimePeriodPrevious
} from 'types/alerts/alertOverviewByTimePeriod.ts';
import {AlertLevels} from 'types/alerts/alertLevels.ts';

/**
 * The priority of the enumType, which matches the order they are declared in
 */
export const alertAlertLevelPriority = (
  enumType: EnumStringType
): Record<AttributeAlertLevel, number> => {
  const attributeAlertLevels = keys(enumType);

  return fromPairs(
    addIndex(map)(
      (attributeAlertLevel: EnumStringType, index: number) => [
        attributeAlertLevel,
        index
      ],
      attributeAlertLevels
    )
  );
};

/**
 * Finds the item in trainGroup.alertScopeSummaryProps.alertTrainGroupProps!.overviewAlertData
 * matching the given dataIntervalLabel.
 * @param dataIntervalLabel
 * @param trainGroup
 */
export const resolveOverviewAlertDataForTimePeriod = (
  dataIntervalLabel: AlertOverviewByTimePeriodOrPrevious,
  trainGroup: TrainGroup
): GraphqlResponseDateIntervalOverviewAlertData => {
  return findOrThrow(
    (overviewAlertData: GraphqlResponseDateIntervalOverviewAlertData) => {
      return overviewAlertData.dateIntervalLabel === dataIntervalLabel;
    },
    trainGroup.alertScopeSummaryProps!.alertTrainGroupProps!.overviewAlertData
  )
};

/**
 * Finds the item in trainGroup.alertScopeSummaryProps.alertTrainGroupProps!.overviewAlertData
 * matching the given dataIntervalLabel, and then returns it's percent attribute values
 * @param alertOverviewByTimePeriod
 * @param trainGroup
 */
export const resolveAlertScoreForTimePeriod = (
  alertOverviewByTimePeriod: AlertOverviewByTimePeriodOrPrevious,
  trainGroup: TrainGroup
): number => {
  return resolveOverviewAlertDataForTimePeriod(alertOverviewByTimePeriod, trainGroup).percent;
};

/**
 * Returns the number of critical alerts for the given AlertOverviewByTimePeriod of the given TrainGroup
 * @param alertOverviewByTimePeriod
 * @param trainGroup
 * @param criticalAlertLevelKeys e.g. "L2", "L3"
 */
export const resolveCriticalAlertCountForTimePeriod = (
  alertOverviewByTimePeriod:AlertOverviewByTimePeriodOrPrevious,
  trainGroup: TrainGroup,
  criticalAlertLevelKeys: (keyof AlertLevels)[]
): number => {
  const graphqlResponseDateIntervalOverviewAlertData: GraphqlResponseDateIntervalOverviewAlertData = findOrThrow(
    (overviewAlertData: GraphqlResponseDateIntervalOverviewAlertData) => {
      return overviewAlertData.dateIntervalLabel === alertOverviewByTimePeriod;
    },
    trainGroup.alertScopeSummaryProps!.alertTrainGroupProps!.overviewAlertData
  );

  const sumOfCriticalAlerts = reduce(
    (sum: number, criticalAlertLevelKey: keyof AlertLevels) => {
      return sum + reduce(
        // Count up the counts of each AlertDatum
        (innerSum: number, alertDatum: AlertDatum) => {return innerSum + alertDatum.count},
        0,
        // Access all the AlertDatum instances for of the given dataIntervalLabel that are critical
        graphqlResponseDateIntervalOverviewAlertData.overviewAlertData?.[criticalAlertLevelKey] || []
      )
    },
    0,
    criticalAlertLevelKeys
  );
  return sumOfCriticalAlerts
};


/**
 * Find the first VehicleCollectionDevice of the TrainFormation in AlertScopeProps
 * that has point ids configured
 * @param alertScopeProps
 */
export const findFirstVehicleCollectionDeviceWithPointIds = (alertScopeProps: AlertScopeProps[]) => {
  const trainFormationCollectionDevices: TrainFormationCollectionDevice[] =
    alertScopeProps.scopedTrainGroup.trainGroupCollectionDevices;
  if (!length(trainFormationCollectionDevices || [])) {
    throw new Error('trainFormationCollectionDevices is undefined or empty');
  }
  // Get the VehicleCollectionDevices so that we can extract the point id
  // for each alert type
  const vehicleCollectionDevices: VehicleCollectionDevice[] = map(
    prop('vehicleCollectionDevice'),
    trainFormationCollectionDevices
  );
  // TODO We are not handling TrainFormations with multiple sensors.
  // We are just using the first
  const vehicleCollectionDevice: VehicleCollectionDevice = findOrThrow(
    compose(Boolean, prop('alertPointId')),
    vehicleCollectionDevices
  );
  return vehicleCollectionDevice;
};
