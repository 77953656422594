import {ToggleButton, ToggleButtonGroup} from '@mui/material';
import {toggleButtonGroupClasses, ToggleButtonGroupClasses} from '@mui/material/ToggleButtonGroup';
import {AlertOverviewByTimePeriod} from 'types/alerts/alertOverviewByTimePeriod.ts';
import {equals, keys, map} from 'ramda';
import {TFunction} from 'i18next';
import {ReactElement, SyntheticEvent} from 'react';
import {CemitComponentProps} from 'types/propTypes/cemitComponenProps';

interface TrainGroupOverviewDateIntervalPickerProps extends CemitComponentProps {
  t: TFunction,
  handleClick: (event: SyntheticEvent, alertOverviewByTimePeriod: AlertOverviewByTimePeriod) => void,
  alertOverviewByTimePeriod: AlertOverviewByTimePeriod
}

/**
 * Lets the user select the desired AlertOverviewByTimePeriod for the TrainGroup overview
 * @constructor
 */
const TrainGroupOverviewDateIntervalPicker = (
  {t, handleClick, alertOverviewByTimePeriod}: TrainGroupOverviewDateIntervalPickerProps
) => {
  // Each AlertOverviewByTimePeriod enum represents and selectable interval
  const buttons: ReactElement[] = map(
    (buttonAlertOverviewByTimePeriod: AlertOverviewByTimePeriod) => {
      return <ToggleButton
        key={buttonAlertOverviewByTimePeriod} {...{
        onClick: (event: SyntheticEvent) => handleClick(event, buttonAlertOverviewByTimePeriod),
        sx: [
          {
            paddingY: '2px',
            paddingX: '4px',
            [`& .${toggleButtonGroupClasses.grouped}`]: {
              border: 0,
              [`&.${toggleButtonGroupClasses.disabled}`]: {
                border: 0
              }
            }
          },
          // For some reason mui hides the left border of the selected (disabled) button, so unset the rule it makes
          ...map((className: keyof ToggleButtonGroupClasses) => {
              return {
                [`&.${className}`]: {
                  [`&.${toggleButtonGroupClasses.disabled}`]: {
                    borderLeftColor: 'unset'
                  }
                }
              };
            },
            [toggleButtonGroupClasses.middleButton,
              toggleButtonGroupClasses.lastButton,
              toggleButtonGroupClasses.firstButton
            ]
          )
        ],
        color: 'primary',
        value: buttonAlertOverviewByTimePeriod,
        disabled: equals(alertOverviewByTimePeriod, buttonAlertOverviewByTimePeriod)
      }}>
        {t(`${buttonAlertOverviewByTimePeriod}Title`)}
      </ToggleButton>;
    },
    keys(AlertOverviewByTimePeriod)
  );
  return <ToggleButtonGroup {...{
    size: 'small',
    value: alertOverviewByTimePeriod,
    variant: 'outlined',
    exclusive: true,
    sx: {
      radius: '10px'
    },
    'aria-label': 'train overview date interval picker'
  }}>
    {buttons}
  </ToggleButtonGroup>;
};
export default TrainGroupOverviewDateIntervalPicker;
