import {TFunction} from 'i18next';

/**
 * Translates the give label using the TFunction unless parseFloat indicates that it is a number.
 * This allows mapping an array of labels and numbers into a sentence and only translating the words
 * @param t
 * @param label
 */
export const translateUnlessNumber =(t: TFunction, label: string): string => {
  return isNaN(parseFloat(label)) ? t(label) : label;
}
